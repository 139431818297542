import { t } from "i18next";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { marked } from "marked";
import { useSelector } from "react-redux";
import { getSiteContent } from "../../../Reducer/api";

export default function ElectricCarsContent() {
  const language = useSelector((state) => state.auth.language);

  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const [data, setData] = useState(null);

  useEffect(() => {
    getSiteContent("electricCarsPage").then((res) => {
      res.json().then((r) => {
        console.log(r);
        setData(r?.data?.[0]?.attributes);
      });
    });
  }, []);

  marked.setOptions({
    gfm: true,
    highlight: false,
    tables: false,
    breaks: true,
    pedantic: false,
    // sanitize: true,
    smartLists: true,
    smartypants: false,
    langPrefix: false,
  });

  return (
    <section className="blog_page content-page">
      <div className="container set">
        <p
          dangerouslySetInnerHTML={{
            __html: marked.parse(data?.[lang] || t("Please wait...")),
          }}
        ></p>
      </div>
    </section>
  );
}

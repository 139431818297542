import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
export const faq = [
  {
    id: 1,
    que: {
      en: "How can Ako Sayara help me buy a used car in Iraq?",
      ar: "كيف يمكنني شراء سيارة مستعملة في العراق من خلال اكو سيارة؟",
      ku: "چۆن دەتوانم ئۆتۆمبێلی بەکارهاتوو لە عێراق لە ڕێگەی ئەکو سەیارە بکڕم؟",
    },
    ans: {
      en: "Ako Sayara connects buyers with verified sellers, offering a wide selection of used cars in cities like Baghdad, Erbil, and Basra. Our platform ensures a smooth and secure transaction process, helping you find the right car at the right price.",
      ar: "يربط اكو سيارة المشترين بالبائعين الموثوقين، ويوفر مجموعة واسعة من السيارات المستعملة في مدن مثل بغداد، أربيل، والبصرة. تضمن منصتنا عملية سلسة وآمنة لمساعدتك في العثور على السيارة المناسبة بالسعر المناسب.",
      ku: "ئەکو سەیارە کڕیاران بە فرۆشیارانی متمانەپێکراوەوە دەبەستێتەوە، هەروەها کۆمەڵێک ئۆتۆمبێلی بەکارهێنراوی بەرفراوان لە شارەکانی وەک بەغدا، هەولێر، و بەسرە دابین دەکات. پلاتفۆرمەکەمان پرۆسەیەکی نەرم و پارێزراو مسۆگەر دەکات بۆ ئەوەی یارمەتیت بدات لە دۆزینەوەی ئۆتۆمبێلی گونجاو بە نرخێکی گونجاو.",
    },
  },
  {
    id: 2,
    que: {
      en: "How do I sell a used car in Iraq through Ako Sayara?",
      ar: "كيف أبيع سيارة مستعملة في العراق عبر اكو سيارة؟",
      ku: "چۆن ئۆتۆمبێلی بەکارهاتوو لە عێراق لە ڕێگەی ئەکو سەیارە بفرۆشم؟",
    },
    ans: {
      en: "Selling your used car with Ako Sayara is simple. List your car on our platform with detailed information and photos. We ensure your listing reaches potential buyers across Iraq, including major cities like Sulaymaniyah and Mosul.",
      ar: "بيع سيارتك المستعملة عبر اكو سيارة بسيط جدًا. قم بإدراج سيارتك على منصتنا مع معلومات وصور تفصيلية. نحن نضمن أن إعلانك يصل إلى المشترين المحتملين في جميع أنحاء العراق، بما في ذلك المدن الكبرى مثل السليمانية والموصل.",
      ku: "فرۆشتنی ئۆتۆمبێلە بەکارهاتووەکەت لە ڕێگەی ئەکو سەیارە زۆر ئاسانە. ئۆتۆمبێلەکەت لە پلاتفۆرمی ئێمەدا بنووسە لەگەڵ زانیاری و وێنەی ورد. ئێمە دڵنیا دەبینەوە لەوەی ڕیکلامەکەت دەگاتە دەستی کڕیارە بەتواناکان لە سەرتاسەری عێراق، بە شارە گەورەکانی وەک سلێمانی و موسڵیشەوە.",
    },
  },
  {
    id: 3,
    que: {
      en: "Can I rent a car in Iraq for short or long-term use?",
      ar: "هل يمكنني استئجار سيارة في العراق للاستخدام القصير أو الطويل؟",
      ku: "دەتوانم ئۆتۆمبێل لە عێراق بەکرێ بگرم بۆ بەکارهێنانی کورت یان درێژ؟",
    },
    ans: {
      en: "Yes, Ako Sayara offers flexible car rental options for short-term or long-term use. Whether you’re visiting Erbil for a weekend or need a car in Basra for a month, we have a variety of vehicles to suit your needs.",
      ar: "نعم، يوفر اكو سيارة خيارات مرنة لاستئجار السيارات لفترات قصيرة أو طويلة. سواء كنت تزور أربيل لعطلة نهاية الأسبوع أو تحتاج إلى سيارة في البصرة لمدة شهر، لدينا مجموعة متنوعة من السيارات لتلبية احتياجاتك.",
      ku: "بەڵێ، ئیکۆ کار بژاردەی نەرم و نیان بۆ بەکرێدانی ئۆتۆمبێل بۆ ماوەیەکی کورت یان درێژ دابین دەکات. جا بۆ کۆتایی هەفتە سەردانی هەولێر بکەیت یان بۆ ماوەی مانگێک پێویستت بە ئۆتۆمبێل بێت لە بەسرە، ئۆتۆمبێلی جۆراوجۆرمان هەیە بۆ دابینکردنی پێداویستییەکانتان.",
    },
  },
  {
    id: 4,
    que: {
      en: "Is it possible to buy a new car in Iraq through Ako Sayara?",
      ar: "هل يمكنني شراء سيارة جديدة في العراق عبر اكو سيارة؟",
      ku: "دەتوانم لە ڕێگەی ئەکو سایەرە ئۆتۆمبێلێکی نوێ لە عێراق بکڕم؟",
    },
    ans: {
      en: "Absolutely! Ako Sayara works with trusted dealerships to offer new cars for sale in Iraq. Browse our platform to explore the latest models available in cities like Baghdad, Basra, and Kurdistan Region.",
      ar: "بالتأكيد! يعمل اكو سيارة مع وكلاء معتمدين لتوفير سيارات جديدة للبيع في العراق. استعرض منصتنا لاستكشاف أحدث الموديلات المتوفرة في مدن مثل بغداد، البصرة، وإقليم كردستان.",
      ku: "دڵنیابە! کۆمپانیای ئەکو سەیارە لەگەڵ بریکارە ڕێگەپێدراوەکان کاردەکات بۆ دابینکردنی ئۆتۆمبێلی نوێ بۆ فرۆشتن لە عێراق. لە پلاتفۆرمەکەماندا بگەڕێ بۆ دۆزینەوەی نوێترین مۆدێلەکان کە لە شارەکانی وەک بەغدا و بەسرە و هەرێمی کوردستان بەردەستن.",
    },
  },
  {
    id: 5,
    que: {
      en: "How do I sell my car in Iraq quickly?",
      ar: "كيف أبيع سيارتي في العراق بسرعة؟",
      ku: "چۆن ئۆتۆمبێلەکەم لە عێراق بە خێرایی بفرۆشم؟",
    },
    ans: {
      en: "To sell your car in Iraq quickly, list it on Ako Sayara with accurate details and clear photos. Our platform attracts buyers from across Iraq, increasing your chances of a fast and hassle-free sale.",
      ar: "لبيع سيارتك في العراق بسرعة، قم بإدراجها على اكو سيارة مع تفاصيل دقيقة وصور واضحة. منصتنا تجذب المشترين من جميع أنحاء العراق، مما يزيد من فرص البيع السريع والخالي من المتاعب.",
      ku: "بۆ ئەوەی ئۆتۆمبێلەکەت لە عێراق بە خێرایی بفرۆشیت، لە ئەکو سەیارە بینووسە بە وردەکاریی ورد و وێنەی ڕوون. پلاتفۆرمەکەمان کڕیاران لە سەرتاسەری عێراقەوە بۆ لای خۆی ڕادەکێشێت، ئەمەش چانسی فرۆشتنی خێرا و بێ کێشە زیاد دەکات.",
    },
  },
  {
    id: 6,
    que: {
      en: "Can I rent my car in Iraq through Ako Sayara?",
      ar: "هل يمكنني تأجير سيارتي في العراق عبر اكو سيارة؟",
      ku: "دەتوانم لە ڕێگەی ئەکو سەیارە ئۆتۆمبێلەکەم لە عێراق بەکرێ بگرم؟",
    },
    ans: {
      en: "Yes, Ako Sayara allows car owners to rent their vehicles to others. List your car on our platform to earn income by renting it out to trusted renters in cities like Baghdad, Basra, Erbil, Sulaymaniyah, and beyond.",
      ar: "نعم، يتيح اكو سيارة لأصحاب السيارات تأجير مركباتهم للآخرين. قم بإدراج سيارتك على منصتنا لتحقيق دخل إضافي من خلال تأجيرها لمستأجرين موثوقين في مدن مثل بغداد، البصرة، أربيل، السليمانية وغيرها.",
      ku: "بەڵێ ئەکو سەیارە ڕێگە بە خاوەن ئۆتۆمبێلەکان دەدات ئۆتۆمبێلەکانیان بەکرێ بگرن بە کەسانی تر. ئۆتۆمبێلەکەت لە پلاتفۆرمی ئێمەدا بنووسە بۆ ئەوەی داهاتی زیادە بەدەست بهێنیت بە کرێدانی بە کرێچی باوەڕپێکراو لە شارەکانی وەک بەغدا و بەسرە و هەولێر و سلێمانی و ئەوانی تر.",
    },
  },
  {
    id: 7,
    que: {
      en: "What types of cars can I rent through Ako Sayara?",
      ar: "ما أنواع السيارات التي يمكنني استئجارها عبر اكو سيارة؟",
      ku: "چ جۆرە ئۆتۆمبێلێک دەتوانم لە ڕێگەی ئیکۆسیارا بەکرێ بگرم؟",
    },
    ans: {
      en: "Ako Sayara offers a variety of cars for rent in Iraq, including Saloons, SUVs, luxury cars, and more. Whether you need a budget-friendly compact car or a high-end vehicle, we have options to meet your requirements.",
      ar: "يوفر اكو سيارة مجموعة متنوعة من السيارات للإيجار في العراق، بما في ذلك سيارات السيدان، سيارات الدفع الرباعي، السيارات الفاخرة، وغيرها. سواء كنت بحاجة إلى سيارة اقتصادية مدمجة أو مركبة فاخرة، لدينا خيارات تناسب متطلباتك.",
      ku: "ئیکۆ کار جۆرەها ئۆتۆمبێلی بۆ کرێ لە عێراق دابین دەکات، لەوانە ئۆتۆمبێلی سیدان و ئێس یو ڤی و ئۆتۆمبێلی لوکس و ئەوانی تر. جا پێویستت بە ئۆتۆمبێلێکی ئابووری کۆمپاکت بێت یان ئۆتۆمبێلێکی لوکس، ئێمە بژاردەمان هەیە کە لەگەڵ پێداویستییەکانتان بگونجێت.",
    },
  },
  {
    id: 8,
    que: {
      en: "How do I ensure I’m getting a good deal when I buy a used car in Iraq?",
      ar: "كيف أضمن أنني أحصل على صفقة جيدة عند شراء سيارة مستعملة في العراق؟",
      ku: "چۆن دڵنیا بم لەوەی کە گرێبەستێکی باشم دەست دەکەوێت لەکاتی کڕینی ئۆتۆمبێلی بەکارهاتوو لە عێراق؟",
    },
    ans: {
      en: "Ako Sayara provides detailed car listings with comprehensive information, vehicle history reports, and inspection options. This ensures you’re making an informed decision when buying a used car in Iraq.",
      ar: "يقدم اكو سيارة قوائم سيارات تفصيلية تحتوي على معلومات شاملة، تقارير تاريخ السيارة، وخيارات الفحص. يضمن ذلك اتخاذ قرار مستنير عند شراء سيارة مستعملة في العراق.",
      ku: "ئەکو سەیارە لیستی وردی ئۆتۆمبێلەکان دەدات کە زانیاری گشتگیر و ڕاپۆرتی مێژووی ئۆتۆمبێل و بژاردەی پشکنین لەخۆدەگرێت. ئەمەش دڵنیای دەدات لەوەی کە لە کاتی کڕینی ئۆتۆمبێلی بەکارهاتوو لە عێراق بڕیارێکی ئاگادارانە دەدەیت.",
    },
  },
  {
    id: 9,
    que: {
      en: "Are there specific requirements for renting a car in Iraq?",
      ar: "هل هناك متطلبات معينة لاستئجار سيارة في العراق؟",
      ku: "ئایا مەرجی تایبەت هەیە بۆ بەکرێدانی ئۆتۆمبێل لە عێراق؟",
    },
    ans: {
      en: "To rent a car in Iraq, you need a valid driver’s license, a government-issued ID or passport, and a payment method such as a credit or debit card. Ako Sayara works with reliable rental providers to make the process easy.",
      ar: "لاستئجار سيارة في العراق، تحتاج إلى رخصة قيادة سارية، هوية حكومية أو جواز سفر، وطريقة دفع مثل بطاقة ائتمان أو خصم. يعمل اكو سيارة مع مزودي تأجير موثوقين لتسهيل العملية.",
      ku: "بۆ بەکرێگرتنی ئۆتۆمبێل لە عێراق پێویستت بە مۆڵەتی شۆفێری کارا و ناسنامەی حکومی یان پاسپۆرت و شێوازی پارەدان وەک کارتی بانکیی یان بانکی پێویستە. ئەکو سەیارە لەگەڵ دابینکەرانی کرێی متمانەپێکراو کاردەکات بۆ ئەوەی پرۆسەکە ئاسانتر بێت.",
    },
  },
  {
    id: 10,
    que: {
      en: "Why should I choose Ako Sayara to buy, sell, or rent cars in Iraq?",
      ar: "لماذا يجب علي اختيار اكو سيارة لشراء، بيع، أو استئجار السيارات في العراق؟",
      ku: "بۆچی ئەکو سەیارە هەڵبژێرم بۆ کڕین، فرۆشتن، یان بەکرێدانی ئۆتۆمبێل لە عێراق؟",
    },
    ans: {
      en: "Ako Sayara is a trusted platform that simplifies car buying, selling, and renting in Iraq. Whether you want to buy a new car in Iraq, sell your car in Iraq, or rent a car in Iraq, we offer a seamless experience with competitive prices and reliable service.",
      ar: "يعتبر اكو سيارة منصة موثوقة تسهل عملية شراء، بيع، وتأجير السيارات في العراق. سواء كنت ترغب في شراء سيارة جديدة في العراق، بيع سيارتك، أو استئجار سيارة، نقدم لك تجربة سلسة بأسعار تنافسية وخدمة موثوقة.",
      ku: "ئەکو سەیارە بە پلاتفۆرمێکی متمانەپێکراوە کە کارەکانی کڕین ، فرۆشتن و یابەکرێی ئۆتۆمبێل کورت دەکات جا کە کڕینی ئۆتۆمبێلێکی نوێت سەیارە بەپێی نرخە ئاسانتره‌.",
    },
  },
];
function FaqPage() {
  const { t, i18n } = useTranslation();
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";
  const [accordion, setAccordion] = useState([]);
  const toggleAccordion = (e) => {
    if (accordion.includes(e)) {
      setAccordion([...accordion.filter((v) => v !== e)]);
    } else {
      setAccordion([...accordion, e]);
    }
  };
  return (
    <section class="recent_articles min-vh-100">
      <div className="container faq">
        <h6
          class="back mx-2"
          style={{ direction: i18n.language === "English" ? "ltr" : "rtl" }}
        >
          <NavLink to={-1}>
            <img src="../assets/images/backbt.png" /> {t("Go back")}
          </NavLink>
        </h6>
        <h1 class="hed mb-4">{t("Frequently asked questions")}</h1>
        <Accordion
          open={accordion}
          toggle={toggleAccordion}
          className=" rounded-2 py-1"
        >
          {faq &&
            faq?.map((item, index) => {
              return (
                <AccordionItem className="rounded-2 m-2 outline-none">
                  <AccordionHeader
                    targetId={index + 1}
                    className="d-flex justify-content-between"
                    style={{ width: "100%", borderRadius: "20px" }}
                  >
                    <span style={{ fontWeight: 600 }}>{item?.que?.[lang]}</span>
                  </AccordionHeader>
                  <AccordionBody accordionId={index + 1}>
                    <div style={{ fontSize: "16px" }}>{item?.ans?.[lang]}</div>
                  </AccordionBody>
                </AccordionItem>
              );
            })}
        </Accordion>
      </div>
    </section>
  );
}

export default FaqPage;

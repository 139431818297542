import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ModalButton from "../ModalButton";
import Button from "../Button";
import { useSelector } from "react-redux";
import { paths } from "../../../utils/paths";
const ListItem = ({value,style,segment,onClick,innerhtmlType,innerData,buttonStyle,listClick,buttonTitle,btnType,buttonId,id,inlineStyle, name}) => {
    const udata = useSelector((state) => state.auth);
    const navigate = useNavigate()
    var html="";
    
    if((segment === paths.sellYourCar.slice(1) || segment === paths.rentYourCar.slice(1)) && !udata.token) {
        html=(<a className={`nav-link`} onClick={e => navigate(paths.login, {state: {backPage: `/${segment}`}})}>{value}</a>);
        return (
            <li className={style} id={id} value={value} name={name} onClick={listClick} style={inlineStyle}>
                {html}
            </li>
        )
    }

    if(innerhtmlType=="link")
    {
        html=(<NavLink className="nav-link"  to={`/${segment}`} >{value}</NavLink>);
    }
    if(innerhtmlType=="modalButton")
    {
        html=(<ModalButton type={innerData.type} styleClass={innerData.class} toggle={innerData.toggle} selected={innerData
        .selected} target={innerData.target} controls={innerData.controls} role={innerData.role} title={innerData.title}/>);
    }
    if(innerhtmlType=="button")
    {
        html=(<Button type={btnType} btnId={buttonId} onClick={onClick} title={buttonTitle} style={buttonStyle}/>);
    }
    if(innerhtmlType=="html")
    {
        html=value;
    }
    
    return (
        <li className={style} id={id} value={value} name={name} onClick={listClick} style={inlineStyle}>
            {html}
        </li>
    )
}
export default ListItem;
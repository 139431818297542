import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CheckMyCar from "./CheckMyCar";
import { clearStatus, fromChacie } from "../../../Reducer/chaiceSlice";
import { cities, errorSms, title } from "../../../data/content";
import { toast } from "react-toastify";
import { dataInFormatCheck } from "./dataFormatUtil";
import { beginWithImage } from "../../../utils/constants";
import withoutVin from "../../../data/withoutVin.json";
import { paths } from "../../../utils/paths";
import JsonLDSchemaHandler from "../../Tools/JSONLDSchemaUtil";
import { ldSchemas } from "../../../utils/json-schemas";
export default function CheckCar() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const submitting = useSelector((state) => state.chacie.submitting);
  const chasis = useSelector((state) => state.chacie.chacie);
  const error = useSelector((state) => state.chacie.error);
  const success = useSelector((state) => state.chacie.success);
  const token = useSelector((state) => state.auth.token);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [vin, setVin] = useState(null);
  const [carData, setCarData] = useState({});
  // const [startup, setStart] = useState("FirstChildVIn");
  // const [details, setDetails] = useState({});

  // const onPaymentComplete = (data) => {
  //   console.log(vin);
  //   dispatch(fromChacie({ chasis: vin, type: "check" }));
  //   // setCarData(findMyCarDataStatic)
  //   // setStep(3)
  // };

  useEffect(() => {
    if (location.state) {
      console.log(location.state);
      dispatch(fromChacie({ chasis: location.state, type: "check" }));
    }
    // const skip = ["cities", ["All cities"], "All cities"];
    // const containsArray = skip.some(
    //   (item) =>
    //     Array.isArray(item) &&
    //     JSON.stringify(item) === JSON.stringify("All cities")
    // );
    const brands = [
      {
          "ID": 27,
          "BrandNameen": "Toyota",
          "BrandNamear": "تويوتا",
          "BrandNameku": "تۆیۆتا",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 120,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 21,
          "Attachment": {
              "ID": 21,
              "Url": "/img/BrandAttachments\\1692609856905.7356_Toyota.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "toyota5075.logowik.com__1_-removebg-preview.png",
              "Size": 91708.0
          },
          "Sort": 120,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 254,
                  "ModelNameen": "Camry",
                  "ModelNamear": "كامري",
                  "ModelNameku": "کامری",
                  "Sort": 100,
                  "FromYearId": 32,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 32,
                      "YearName": "1982",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 249,
                  "ModelNameen": "Land Cruiser",
                  "ModelNamear": "لاندكروزر",
                  "ModelNameku": "لاند کروزەر",
                  "Sort": 98,
                  "FromYearId": 1,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 1,
                      "YearName": "1951",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 245,
                  "ModelNameen": "Corolla",
                  "ModelNamear": "كورولا",
                  "ModelNameku": "کۆرۆلا",
                  "Sort": 96,
                  "FromYearId": 30,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 30,
                      "YearName": "1980",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 289,
                  "ModelNameen": "Avalon",
                  "ModelNamear": "أفالون",
                  "ModelNameku": "ئەڤەلۆن",
                  "Sort": 94,
                  "FromYearId": 44,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 44,
                      "YearName": "1994",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 257,
                  "ModelNameen": "RAV4",
                  "ModelNamear": "راف 4",
                  "ModelNameku": "ڕاڤ 4",
                  "Sort": 93,
                  "FromYearId": 44,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 44,
                      "YearName": "1994",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 933,
                  "ModelNameen": "Crown",
                  "ModelNamear": "كراون",
                  "ModelNameku": "کراون",
                  "Sort": 92,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 253,
                  "ModelNameen": "Hilux",
                  "ModelNamear": "هايلوكس",
                  "ModelNameku": "هایلۆکس",
                  "Sort": 91,
                  "FromYearId": 30,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 30,
                      "YearName": "1980",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 288,
                  "ModelNameen": "Land Cruiser Prado",
                  "ModelNamear": "لاندكروزر برادو",
                  "ModelNameku": "لاندكروزر پرادۆ",
                  "Sort": 90,
                  "FromYearId": 34,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 34,
                      "YearName": "1984",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 934,
                  "ModelNameen": "Highlander",
                  "ModelNamear": "هايلاندر",
                  "ModelNameku": "هایلاندەر",
                  "Sort": 89,
                  "FromYearId": 51,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 51,
                      "YearName": "2001",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1011,
                  "ModelNameen": "Corolla Cross",
                  "ModelNamear": "كورولا كروس",
                  "ModelNameku": "کۆرۆلا کرۆس",
                  "Sort": 88,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 322,
                  "ModelNameen": "Yaris",
                  "ModelNamear": "يارس",
                  "ModelNameku": "یارس",
                  "Sort": 87,
                  "FromYearId": 50,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 50,
                      "YearName": "2000",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 246,
                  "ModelNameen": "Rush",
                  "ModelNamear": "رش",
                  "ModelNameku": "ڕەش",
                  "Sort": 86,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 939,
                  "ModelNameen": "Tundra",
                  "ModelNamear": "توندرا",
                  "ModelNameku": "توندرا",
                  "Sort": 84,
                  "FromYearId": 49,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 49,
                      "YearName": "1999",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 287,
                  "ModelNameen": "Land Cruiser 70",
                  "ModelNamear": "لاند كروزر 70",
                  "ModelNameku": "لاند کرۆزەر 70",
                  "Sort": 82,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 248,
                  "ModelNameen": "Land Cruiser Pickup",
                  "ModelNamear": "لاند كروزر بيك اب",
                  "ModelNameku": "لاند کرۆزەر پیکاپ",
                  "Sort": 80,
                  "FromYearId": 38,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 38,
                      "YearName": "1988",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 251,
                  "ModelNameen": "Avanza",
                  "ModelNamear": "افانزا",
                  "ModelNameku": "ئەڤانزا",
                  "Sort": 78,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 241,
                  "ModelNameen": "Hiace",
                  "ModelNamear": "هايس",
                  "ModelNameku": "هیاس",
                  "Sort": 76,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 928,
                  "ModelNameen": "4Runner",
                  "ModelNamear": "4رنر",
                  "ModelNameku": "4رەنەر",
                  "Sort": 0,
                  "FromYearId": 34,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 34,
                      "YearName": "1984",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1255,
                  "ModelNameen": "Alphard",
                  "ModelNamear": "ألفارد",
                  "ModelNameku": "ئەلفەرد",
                  "Sort": 0,
                  "FromYearId": 52,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1294,
                  "ModelNameen": "Aristo",
                  "ModelNamear": "أريستو",
                  "ModelNameku": "ئەرستۆ",
                  "Sort": 0,
                  "FromYearId": 41,
                  "ToYearId": 55,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 41,
                      "YearName": "1991",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 255,
                  "ModelNameen": "Aurion",
                  "ModelNamear": "أوريون",
                  "ModelNameku": "ئۆریۆن",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 929,
                  "ModelNameen": "Auris",
                  "ModelNamear": "اورس",
                  "ModelNameku": "ئەوریس",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 930,
                  "ModelNameen": "Avensis",
                  "ModelNamear": "افنسز",
                  "ModelNameku": "ئەڤێنسز",
                  "Sort": 0,
                  "FromYearId": 47,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 47,
                      "YearName": "1997",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1381,
                  "ModelNameen": "bZ3",
                  "ModelNamear": "bZ3",
                  "ModelNameku": "bZ3",
                  "Sort": 0,
                  "FromYearId": 74,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 74,
                      "YearName": "2023",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1188,
                  "ModelNameen": "bZ4X",
                  "ModelNamear": "bZ4X",
                  "ModelNameku": "bZ4X",
                  "Sort": 0,
                  "FromYearId": 72,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 72,
                      "YearName": "2022",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1383,
                  "ModelNameen": "Carina",
                  "ModelNamear": "كارينا",
                  "ModelNameku": "کارینا",
                  "Sort": 0,
                  "FromYearId": 20,
                  "ToYearId": 51,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 20,
                      "YearName": "1970",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 51,
                      "YearName": "2001",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 931,
                  "ModelNameen": "CH-R",
                  "ModelNamear": "سي اتش ار",
                  "ModelNameku": "CH-R",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1350,
                  "ModelNameen": "Chaser",
                  "ModelNamear": "شايسر",
                  "ModelNameku": "چەیسەر",
                  "Sort": 0,
                  "FromYearId": 27,
                  "ToYearId": 51,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 27,
                      "YearName": "1977",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 51,
                      "YearName": "2001",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 989,
                  "ModelNameen": "Coaster",
                  "ModelNamear": "كوستر",
                  "ModelNameku": "کۆستەر",
                  "Sort": 0,
                  "FromYearId": 19,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 19,
                      "YearName": "1969",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 932,
                  "ModelNameen": "Corona",
                  "ModelNamear": "كورونا",
                  "ModelNameku": "کرۆنا",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1270,
                  "ModelNameen": "Cresta",
                  "ModelNamear": "كريستا",
                  "ModelNameku": "کرێستا",
                  "Sort": 0,
                  "FromYearId": 30,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 30,
                      "YearName": "1980",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1170,
                  "ModelNameen": "Crown Kluger",
                  "ModelNamear": "كراون كلوجر",
                  "ModelNameku": "کراون کلوگەر",
                  "Sort": 0,
                  "FromYearId": 72,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 72,
                      "YearName": "2022",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1260,
                  "ModelNameen": "Echo",
                  "ModelNamear": "إيكو",
                  "ModelNameku": "ئیکۆ",
                  "Sort": 0,
                  "FromYearId": 49,
                  "ToYearId": 55,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 49,
                      "YearName": "1999",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 256,
                  "ModelNameen": "FJ Cruiser",
                  "ModelNamear": "إف جي كروزر",
                  "ModelNameku": "FJ کرۆزەر",
                  "Sort": 0,
                  "FromYearId": 28,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 28,
                      "YearName": "1978",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 286,
                  "ModelNameen": "Fortuner",
                  "ModelNamear": "فورجنر",
                  "ModelNameku": "فۆرچێنەر",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1267,
                  "ModelNameen": "Frontlander",
                  "ModelNamear": "فرونتلاندر",
                  "ModelNameku": "فرۆنتلاندەر",
                  "Sort": 0,
                  "FromYearId": 50,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 50,
                      "YearName": "2000",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1236,
                  "ModelNameen": "GR86",
                  "ModelNamear": "GR86",
                  "ModelNameku": "GR86",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1338,
                  "ModelNameen": "Grand Highlander",
                  "ModelNamear": "جراند هايلاندر",
                  "ModelNameku": "گراند هایلاندەر",
                  "Sort": 0,
                  "FromYearId": 74,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 74,
                      "YearName": "2023",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 240,
                  "ModelNameen": "Granvia",
                  "ModelNamear": "جرانفيا",
                  "ModelNameku": "گرانڤیا",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 321,
                  "ModelNameen": "GT 86",
                  "ModelNamear": "GT 86",
                  "ModelNameku": "GT 86",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 252,
                  "ModelNameen": "Innova",
                  "ModelNamear": "انوفا",
                  "ModelNameku": "ئینۆڤا",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1206,
                  "ModelNameen": "Ipsum",
                  "ModelNamear": "إبسم",
                  "ModelNameku": "ئیپسەم",
                  "Sort": 0,
                  "FromYearId": 45,
                  "ToYearId": 60,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 45,
                      "YearName": "1995",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1134,
                  "ModelNameen": "Land Cruiser 76",
                  "ModelNamear": "لاند كروزر 76",
                  "ModelNameku": "لاند کرۆزەر 76",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1230,
                  "ModelNameen": "Mark II",
                  "ModelNamear": "II مارك",
                  "ModelNameku": "II مارک",
                  "Sort": 0,
                  "FromYearId": 18,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 18,
                      "YearName": "1968",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 935,
                  "ModelNameen": "Matrix",
                  "ModelNamear": "ماتريكس",
                  "ModelNameku": "ماتریکس",
                  "Sort": 0,
                  "FromYearId": 51,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 51,
                      "YearName": "2001",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 936,
                  "ModelNameen": "Paseo",
                  "ModelNamear": "باسيو",
                  "ModelNameku": "پاسیۆ",
                  "Sort": 0,
                  "FromYearId": 40,
                  "ToYearId": 50,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 40,
                      "YearName": "1990",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 50,
                      "YearName": "2000",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 247,
                  "ModelNameen": "Previa",
                  "ModelNamear": "بريفيا",
                  "ModelNameku": "پریڤیا",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 242,
                  "ModelNameen": "Prius",
                  "ModelNamear": "بريوس",
                  "ModelNameku": "پریوس",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1124,
                  "ModelNameen": "Raize",
                  "ModelNamear": "رايز",
                  "ModelNameku": "ڕەیز",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1211,
                  "ModelNameen": "Scion tC",
                  "ModelNamear": "سايون تي سي",
                  "ModelNameku": "ساێۆن تی سی",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1252,
                  "ModelNameen": "Scion xB",
                  "ModelNamear": "سايون xB",
                  "ModelNameku": "سایۆن xB",
                  "Sort": 0,
                  "FromYearId": 50,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 50,
                      "YearName": "2000",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 250,
                  "ModelNameen": "Sequoia",
                  "ModelNamear": "سيكويا",
                  "ModelNameku": "سیکۆیا",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 937,
                  "ModelNameen": "Sienna",
                  "ModelNamear": "سيينا",
                  "ModelNameku": "سیانا",
                  "Sort": 0,
                  "FromYearId": 47,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 47,
                      "YearName": "1997",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 320,
                  "ModelNameen": "Supra",
                  "ModelNamear": "سوبرا",
                  "ModelNameku": "سوپرا",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 938,
                  "ModelNameen": "Tacoma",
                  "ModelNamear": "تاكوما",
                  "ModelNameku": "تاکۆما",
                  "Sort": 0,
                  "FromYearId": 45,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 45,
                      "YearName": "1995",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1221,
                  "ModelNameen": "Tercel",
                  "ModelNamear": "ترسل",
                  "ModelNameku": "تێرسێل",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1184,
                  "ModelNameen": "Urban Cruiser",
                  "ModelNamear": "أوربان كروزر",
                  "ModelNameku": "ئۆربان کرۆزەر",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 940,
                  "ModelNameen": "Venza",
                  "ModelNamear": "فنزا",
                  "ModelNameku": "ڤێنزا",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 991,
                  "ModelNameen": "Wigo",
                  "ModelNamear": "ويجو",
                  "ModelNameku": "ویگۆ",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 323,
                  "ModelNameen": "xA",
                  "ModelNamear": "xA",
                  "ModelNameku": "xA",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1360,
                  "ModelNameen": "Yaris Cross",
                  "ModelNamear": "ياريس كروس",
                  "ModelNameku": "یاریس کرۆس",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 243,
                  "ModelNameen": "Zelas",
                  "ModelNamear": "زلاس",
                  "ModelNameku": "زێلاس",
                  "Sort": 0,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 60,
          "BrandNameen": "BMW",
          "BrandNamear": "بي ام دبليو",
          "BrandNameku": "بی ئێم دەبڵیو",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 117,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 36,
          "Attachment": {
              "ID": 36,
              "Url": "/img/BrandAttachments\\1598260896743.1196_BMW",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260896743.1196_BMW",
              "Size": 47693.0
          },
          "Sort": 117,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 640,
                  "ModelNameen": "5-Series",
                  "ModelNamear": "سيريس 5",
                  "ModelNameku": "سیریەس 5",
                  "Sort": 30,
                  "FromYearId": 39,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 39,
                      "YearName": "1989",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 656,
                  "ModelNameen": "3-Series",
                  "ModelNamear": "الفئة 3",
                  "ModelNameku": "سیریەس 3",
                  "Sort": 25,
                  "FromYearId": 49,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 49,
                      "YearName": "1999",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 665,
                  "ModelNameen": "7-Series",
                  "ModelNamear": "سیریس 7",
                  "ModelNameku": "سیریەس 7",
                  "Sort": 22,
                  "FromYearId": 38,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 38,
                      "YearName": "1988",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 652,
                  "ModelNameen": "4-Series",
                  "ModelNamear": "سيريس 4",
                  "ModelNameku": "4 سیریەس",
                  "Sort": 20,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 637,
                  "ModelNameen": "X6",
                  "ModelNamear": "X6",
                  "ModelNameku": "X6",
                  "Sort": 18,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 644,
                  "ModelNameen": "X5",
                  "ModelNamear": "X5",
                  "ModelNameku": "X5",
                  "Sort": 16,
                  "FromYearId": 50,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 50,
                      "YearName": "2000",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 689,
                  "ModelNameen": "8-Series",
                  "ModelNamear": "8 سيريس",
                  "ModelNameku": "8 سیریەس",
                  "Sort": 14,
                  "FromYearId": 40,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 40,
                      "YearName": "1990",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 577,
                  "ModelNameen": "2-Series",
                  "ModelNamear": "الفئة 2",
                  "ModelNameku": "سیریەس 2",
                  "Sort": 12,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 691,
                  "ModelNameen": "X7",
                  "ModelNamear": "X7",
                  "ModelNameku": "X7",
                  "Sort": 12,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 659,
                  "ModelNameen": "X3",
                  "ModelNamear": "X3",
                  "ModelNameku": "X3",
                  "Sort": 10,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 688,
                  "ModelNameen": "1-Series",
                  "ModelNamear": "1 سيريس",
                  "ModelNameku": "1 سیریەس",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 662,
                  "ModelNameen": "6-Series",
                  "ModelNamear": "سيريس 6",
                  "ModelNameku": "سیریەس 6",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1261,
                  "ModelNameen": "i3",
                  "ModelNamear": "i3",
                  "ModelNameku": "i3",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1352,
                  "ModelNameen": "i4",
                  "ModelNamear": "i4",
                  "ModelNameku": "i4",
                  "Sort": 0,
                  "FromYearId": 72,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 72,
                      "YearName": "2022",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1347,
                  "ModelNameen": "i5",
                  "ModelNamear": "i5",
                  "ModelNameku": "i5",
                  "Sort": 0,
                  "FromYearId": 74,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 74,
                      "YearName": "2023",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 578,
                  "ModelNameen": "i8",
                  "ModelNamear": "i8",
                  "ModelNameku": "i8",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1334,
                  "ModelNameen": "iX",
                  "ModelNamear": "iX",
                  "ModelNameku": "iX",
                  "Sort": 0,
                  "FromYearId": 71,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 71,
                      "YearName": "2021",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1349,
                  "ModelNameen": "iX1",
                  "ModelNamear": "iX1",
                  "ModelNameku": "iX1",
                  "Sort": 0,
                  "FromYearId": 72,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 72,
                      "YearName": "2022",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 547,
                  "ModelNameen": "X1",
                  "ModelNamear": "X1",
                  "ModelNameku": "X1",
                  "Sort": 0,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 492,
                  "ModelNameen": "X2",
                  "ModelNamear": "X2",
                  "ModelNameku": "X2",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 660,
                  "ModelNameen": "X4",
                  "ModelNamear": "X4",
                  "ModelNameku": "X4",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 638,
                  "ModelNameen": "X6 M",
                  "ModelNamear": "X6 M",
                  "ModelNameku": "X6 M",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1161,
                  "ModelNameen": "XM",
                  "ModelNamear": "XM",
                  "ModelNameku": "XM",
                  "Sort": 0,
                  "FromYearId": 74,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 74,
                      "YearName": "2023",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 661,
                  "ModelNameen": "Z4",
                  "ModelNamear": "Z4",
                  "ModelNameku": "Z4",
                  "Sort": 0,
                  "FromYearId": 53,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 47,
          "BrandNameen": "Kia",
          "BrandNamear": "كيا",
          "BrandNameku": "کیا",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 116,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 37,
          "Attachment": {
              "ID": 37,
              "Url": "/img/BrandAttachments\\1691318753979.1956_Kia.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "1671359522911.9517_Kia ZOOM33.png",
              "Size": 30423.0
          },
          "Sort": 116,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 463,
                  "ModelNameen": "Sorento",
                  "ModelNamear": "سورينتو",
                  "ModelNameku": "سۆرینتۆ",
                  "Sort": 110,
                  "FromYearId": 52,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1016,
                  "ModelNameen": "K5",
                  "ModelNamear": "K5",
                  "ModelNameku": "K5",
                  "Sort": 100,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 768,
                  "ModelNameen": "Sportage",
                  "ModelNamear": "سبورتاج",
                  "ModelNameku": "سپۆرتاج",
                  "Sort": 98,
                  "FromYearId": 43,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 43,
                      "YearName": "1993",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 767,
                  "ModelNameen": "Cerato",
                  "ModelNamear": "سيراتو",
                  "ModelNameku": "سیراتۆ",
                  "Sort": 96,
                  "FromYearId": 53,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 949,
                  "ModelNameen": "Forte",
                  "ModelNamear": "فورتي",
                  "ModelNameku": "فۆرتێ",
                  "Sort": 95,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 776,
                  "ModelNameen": "Stinger",
                  "ModelNamear": "ستينغر",
                  "ModelNameku": "ستینگەر",
                  "Sort": 94,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 148,
                  "ModelNameen": "Seltos",
                  "ModelNamear": "سلتوس",
                  "ModelNameku": "سێلتۆس",
                  "Sort": 93,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 775,
                  "ModelNameen": "Optima",
                  "ModelNamear": "اوبتيما",
                  "ModelNameku": "ئۆپتیما",
                  "Sort": 92,
                  "FromYearId": 50,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 50,
                      "YearName": "2000",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 147,
                  "ModelNameen": "Rio",
                  "ModelNamear": "ريو",
                  "ModelNameku": "ریۆ",
                  "Sort": 91,
                  "FromYearId": 49,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 49,
                      "YearName": "1999",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 462,
                  "ModelNameen": "Telluride",
                  "ModelNamear": "تيلوريد",
                  "ModelNameku": "تێلوراید",
                  "Sort": 90,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 769,
                  "ModelNameen": "Carnival",
                  "ModelNamear": "كرنفال",
                  "ModelNameku": "کەرنیڤاڵ",
                  "Sort": 89,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 461,
                  "ModelNameen": "Picanto",
                  "ModelNamear": "بيكانتو",
                  "ModelNameku": "پیکانتۆ",
                  "Sort": 88,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 773,
                  "ModelNameen": "Quoris",
                  "ModelNamear": "كوريس",
                  "ModelNameku": "کۆریس",
                  "Sort": 86,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 765,
                  "ModelNameen": "Soul",
                  "ModelNamear": "سول",
                  "ModelNameku": "سۆڵ",
                  "Sort": 84,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 770,
                  "ModelNameen": "Cadenza",
                  "ModelNamear": "كادينزا",
                  "ModelNameku": "کادێنزا",
                  "Sort": 83,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1204,
                  "ModelNameen": "Besta",
                  "ModelNamear": "بيستا",
                  "ModelNameku": "بێستا",
                  "Sort": 0,
                  "FromYearId": 44,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 44,
                      "YearName": "1994",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1038,
                  "ModelNameen": "Bongo",
                  "ModelNamear": "بونكو",
                  "ModelNameku": "بۆنگۆ",
                  "Sort": 0,
                  "FromYearId": 40,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 40,
                      "YearName": "1990",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 774,
                  "ModelNameen": "Carens",
                  "ModelNamear": "كارينز",
                  "ModelNameku": "کارینس",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 977,
                  "ModelNameen": "Ceed",
                  "ModelNamear": "سيد",
                  "ModelNameku": "سیید",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1208,
                  "ModelNameen": "EV6",
                  "ModelNamear": "EV6",
                  "ModelNameku": "EV6",
                  "Sort": 0,
                  "FromYearId": 71,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 71,
                      "YearName": "2021",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1320,
                  "ModelNameen": "EV9",
                  "ModelNamear": "EV9",
                  "ModelNameku": "EV9",
                  "Sort": 0,
                  "FromYearId": 74,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 74,
                      "YearName": "2023",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1172,
                  "ModelNameen": "Forte5",
                  "ModelNamear": "5فورتي",
                  "ModelNameku": "5فۆرتێ",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1227,
                  "ModelNameen": "K3",
                  "ModelNamear": "K3",
                  "ModelNameku": "K3",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1112,
                  "ModelNameen": "K7",
                  "ModelNamear": "K7",
                  "ModelNameku": "K7",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1139,
                  "ModelNameen": "K8",
                  "ModelNamear": "K8",
                  "ModelNameku": "K8",
                  "Sort": 0,
                  "FromYearId": 71,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 71,
                      "YearName": "2021",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 771,
                  "ModelNameen": "K900",
                  "ModelNamear": "K900",
                  "ModelNameku": "K900",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 950,
                  "ModelNameen": "Lotze",
                  "ModelNamear": "لوتزي",
                  "ModelNameku": "لۆتزێ",
                  "Sort": 0,
                  "FromYearId": 50,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 50,
                      "YearName": "2000",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 777,
                  "ModelNameen": "Mohave",
                  "ModelNamear": "موهافي",
                  "ModelNameku": "مۆهاڤی",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1144,
                  "ModelNameen": "Niro",
                  "ModelNamear": "نيرو",
                  "ModelNameku": "نیرۆ",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 772,
                  "ModelNameen": "Opirus",
                  "ModelNamear": "أوبيروس",
                  "ModelNameku": "ئۆپیرۆس",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 146,
                  "ModelNameen": "Pegas",
                  "ModelNamear": "بيجاس",
                  "ModelNameku": "پێگاس",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1341,
                  "ModelNameen": "Potentia",
                  "ModelNamear": "بوتينشيا",
                  "ModelNameku": "پۆتێنشیا",
                  "Sort": 0,
                  "FromYearId": 42,
                  "ToYearId": 52,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 42,
                      "YearName": "1992",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1307,
                  "ModelNameen": "Pregio",
                  "ModelNamear": "بريجيو",
                  "ModelNameku": "پرێگیۆ",
                  "Sort": 0,
                  "FromYearId": 45,
                  "ToYearId": 56,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 45,
                      "YearName": "1995",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1333,
                  "ModelNameen": "Pride",
                  "ModelNamear": "برايد",
                  "ModelNameku": "پراید",
                  "Sort": 0,
                  "FromYearId": 36,
                  "ToYearId": 50,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 36,
                      "YearName": "1986",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 50,
                      "YearName": "2000",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1241,
                  "ModelNameen": "Ray",
                  "ModelNamear": "راي",
                  "ModelNameku": "ڕەی",
                  "Sort": 0,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1003,
                  "ModelNameen": "Sedona",
                  "ModelNamear": "سيدونا",
                  "ModelNameku": "سێدۆنا",
                  "Sort": 0,
                  "FromYearId": 48,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 48,
                      "YearName": "1998",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1186,
                  "ModelNameen": "Sephia",
                  "ModelNamear": "سيفيا",
                  "ModelNameku": "سێفیا",
                  "Sort": 0,
                  "FromYearId": 44,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 44,
                      "YearName": "1994",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1057,
                  "ModelNameen": "Sonet",
                  "ModelNamear": "سونيت",
                  "ModelNameku": "سۆنێت",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1265,
                  "ModelNameen": "Spectra",
                  "ModelNamear": "سبكترا",
                  "ModelNameku": "سپێکترا",
                  "Sort": 0,
                  "FromYearId": 50,
                  "ToYearId": 59,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 50,
                      "YearName": "2000",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1348,
                  "ModelNameen": "Stonic",
                  "ModelNamear": "ستونيك",
                  "ModelNameku": "ستۆنیک",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1385,
                  "ModelNameen": "Tasman",
                  "ModelNamear": "تسمان",
                  "ModelNameku": "تاسمان",
                  "Sort": 0,
                  "FromYearId": 80,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 80,
                      "YearName": "2025",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 85,
          "BrandNameen": "GAC",
          "BrandNamear": "جي أي سي",
          "BrandNameku": "جی ئەی سی",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 115,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 84,
          "Attachment": {
              "ID": 84,
              "Url": "/img/BrandAttachments\\1689252010116.8923_GAC.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "GAC.png",
              "Size": 21218.0
          },
          "Sort": 115,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1164,
                  "ModelNameen": "EMKOO",
                  "ModelNamear": "إمكو",
                  "ModelNameku": "ئیمکۆ",
                  "Sort": 0,
                  "FromYearId": 72,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 72,
                      "YearName": "2022",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1109,
                  "ModelNameen": "Empow",
                  "ModelNamear": "إمباو",
                  "ModelNameku": "ئێمپۆو",
                  "Sort": 0,
                  "FromYearId": 71,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 71,
                      "YearName": "2021",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 916,
                  "ModelNameen": "GA3",
                  "ModelNamear": "GA3",
                  "ModelNameku": "GA3",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 992,
                  "ModelNameen": "GA4",
                  "ModelNamear": "GA4",
                  "ModelNameku": "GA4",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 920,
                  "ModelNameen": "GA5",
                  "ModelNamear": "GA5",
                  "ModelNameku": "GA5",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 919,
                  "ModelNameen": "GA8",
                  "ModelNamear": "GA8",
                  "ModelNameku": "GA8",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1176,
                  "ModelNameen": "GN8",
                  "ModelNamear": "GN8",
                  "ModelNameku": "GN8",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1243,
                  "ModelNameen": "Gonow GA200",
                  "ModelNamear": "جونو GA200",
                  "ModelNameku": "گۆنۆو GA200",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": 66,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1215,
                  "ModelNameen": "Gonow Way M1",
                  "ModelNamear": "جونو واي M1",
                  "ModelNameku": "گۆناو وای M1",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 922,
                  "ModelNameen": "GS3",
                  "ModelNamear": "GS3",
                  "ModelNameku": "GS3",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1177,
                  "ModelNameen": "GS3 EMZOOM",
                  "ModelNamear": "GS3 إمزوم",
                  "ModelNameku": "GS3 ئێمزووم",
                  "Sort": 0,
                  "FromYearId": 74,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 74,
                      "YearName": "2023",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 897,
                  "ModelNameen": "GS4",
                  "ModelNamear": "GS4",
                  "ModelNameku": "GS4",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 917,
                  "ModelNameen": "GS5",
                  "ModelNamear": "GS5",
                  "ModelNameku": "GS5",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 918,
                  "ModelNameen": "GS7",
                  "ModelNamear": "GS7",
                  "ModelNameku": "GS7",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 921,
                  "ModelNameen": "GS8",
                  "ModelNamear": "GS8",
                  "ModelNameku": "GS8",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1372,
                  "ModelNameen": "M8",
                  "ModelNamear": "M8",
                  "ModelNameku": "M8",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 30,
          "BrandNameen": "Suzuki",
          "BrandNamear": "سوزوكي",
          "BrandNameku": "سوزوکی",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 114,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 55,
          "Attachment": {
              "ID": 55,
              "Url": "/img/BrandAttachments\\1598260871390.4036_Suzuki",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260871390.4036_Suzuki",
              "Size": 14347.0
          },
          "Sort": 114,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 284,
                  "ModelNameen": "Alto",
                  "ModelNamear": "التو",
                  "ModelNameku": "التۆ",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 278,
                  "ModelNameen": "APV",
                  "ModelNamear": "APV",
                  "ModelNameku": "APV",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 285,
                  "ModelNameen": "Baleno",
                  "ModelNamear": "بالينو",
                  "ModelNameku": "بالینۆ",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 280,
                  "ModelNameen": "Carry",
                  "ModelNamear": "كاري",
                  "ModelNameku": "کاری",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 283,
                  "ModelNameen": "Celerio",
                  "ModelNamear": "سيليريو",
                  "ModelNameku": "سلریۆ",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 275,
                  "ModelNameen": "Ciaz",
                  "ModelNamear": "سياز",
                  "ModelNameku": "سیاز",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 259,
                  "ModelNameen": "Dzire",
                  "ModelNamear": "ديزاير",
                  "ModelNameku": "دیزایەر",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1297,
                  "ModelNameen": "Eeco",
                  "ModelNamear": "إيكو",
                  "ModelNameku": "ئیکۆ",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 279,
                  "ModelNameen": "Ertiga",
                  "ModelNamear": "إرتيجا",
                  "ModelNameku": "ئەرتیگا",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1276,
                  "ModelNameen": "Forenza",
                  "ModelNamear": "فورينزا",
                  "ModelNameku": "فۆرێنزا",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": 64,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1296,
                  "ModelNameen": "Fronx",
                  "ModelNamear": "فرونكس",
                  "ModelNameku": "فرۆنکس",
                  "Sort": 0,
                  "FromYearId": 74,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 74,
                      "YearName": "2023",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 277,
                  "ModelNameen": "Grand Vitara",
                  "ModelNamear": "جراند فيتارا",
                  "ModelNameku": "گراند ڤیتیرا",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 273,
                  "ModelNameen": "Ignis",
                  "ModelNamear": "إجنيس",
                  "ModelNameku": "ئیگنیس",
                  "Sort": 0,
                  "FromYearId": 52,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 258,
                  "ModelNameen": "Ignis Crossover",
                  "ModelNamear": "Ignis Crossover",
                  "ModelNameku": "Ignis Crossover",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 244,
                  "ModelNameen": "Jimny",
                  "ModelNamear": "جيمني",
                  "ModelNameku": "جیمی",
                  "Sort": 0,
                  "FromYearId": 49,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 49,
                      "YearName": "1999",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 281,
                  "ModelNameen": "Kizashi",
                  "ModelNamear": "كيزاشي",
                  "ModelNameku": "کیزاشی",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 276,
                  "ModelNameen": "Liana",
                  "ModelNamear": "ليانا",
                  "ModelNameku": "لیانا",
                  "Sort": 0,
                  "FromYearId": 52,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1274,
                  "ModelNameen": "Reno",
                  "ModelNamear": "رينو",
                  "ModelNameku": "ڕینۆ",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1225,
                  "ModelNameen": "S-Presso",
                  "ModelNamear": "إس بريسو",
                  "ModelNameku": "S-پرێسۆ",
                  "Sort": 0,
                  "FromYearId": 71,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 71,
                      "YearName": "2021",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 260,
                  "ModelNameen": "Swift",
                  "ModelNamear": "سويفت",
                  "ModelNameku": "سویفت",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 274,
                  "ModelNameen": "SX4",
                  "ModelNamear": "SX4",
                  "ModelNameku": "SX4",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 233,
                  "ModelNameen": "Vitara",
                  "ModelNamear": "فيتارا",
                  "ModelNameku": "ڤیتارا",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 282,
                  "ModelNameen": "XL7",
                  "ModelNamear": "XL7",
                  "ModelNameku": "XL7",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 53,
          "BrandNameen": "Changan",
          "BrandNamear": "شانجان",
          "BrandNameku": "چانگان",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 112,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 65,
          "Attachment": {
              "ID": 65,
              "Url": "/img/BrandAttachments\\1721048256774.609_Changan.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "Changan-Logo EDITED.png",
              "Size": 58413.0
          },
          "Sort": 112,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 470,
                  "ModelNameen": "Alsvin",
                  "ModelNamear": "السفن",
                  "ModelNameku": "ئەلسڤین",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1064,
                  "ModelNameen": "CM5",
                  "ModelNamear": "CM5",
                  "ModelNameku": "CM5",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1345,
                  "ModelNameen": "CS15",
                  "ModelNamear": "CS15",
                  "ModelNameku": "CS15",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 474,
                  "ModelNameen": "CS35",
                  "ModelNamear": "CS35",
                  "ModelNameku": "CS35",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 472,
                  "ModelNameen": "CS75",
                  "ModelNamear": "CS75",
                  "ModelNameku": "CS75",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1303,
                  "ModelNameen": "CS75 Plus",
                  "ModelNamear": "CS75 بلس",
                  "ModelNameku": "CS75 پڵەس",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1015,
                  "ModelNameen": "CS85",
                  "ModelNamear": "CS85",
                  "ModelNameku": "CS85",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 473,
                  "ModelNameen": "CS95",
                  "ModelNamear": "CS95",
                  "ModelNameku": "CS95",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1153,
                  "ModelNameen": "E-Star",
                  "ModelNamear": "إي- ستار",
                  "ModelNameku": "E-Star",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 471,
                  "ModelNameen": "Eado",
                  "ModelNamear": "إيدو",
                  "ModelNameku": "ئێدۆ",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1302,
                  "ModelNameen": "Eado Plus",
                  "ModelNamear": "إيدو بلس",
                  "ModelNameku": "ئیدۆ پڵەس",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1027,
                  "ModelNameen": "Hunter",
                  "ModelNamear": "هنتر",
                  "ModelNameku": "هەنتەر",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1102,
                  "ModelNameen": "STAR 9",
                  "ModelNamear": "ستار 9",
                  "ModelNameku": "ستار 9",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1138,
                  "ModelNameen": "Star Truck",
                  "ModelNamear": "ستار تراك",
                  "ModelNameku": "ستار تراک",
                  "Sort": 0,
                  "FromYearId": 49,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 49,
                      "YearName": "1999",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1065,
                  "ModelNameen": "UNI-K",
                  "ModelNamear": "يوني كي",
                  "ModelNameku": "UNI-K",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1309,
                  "ModelNameen": "UNI-S",
                  "ModelNamear": "UNI-S",
                  "ModelNameku": "UNI-S",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1066,
                  "ModelNameen": "UNI-T",
                  "ModelNamear": "يوني تي",
                  "ModelNameku": "UNI-T",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1245,
                  "ModelNameen": "UNI-V",
                  "ModelNamear": "يوني في",
                  "ModelNameku": "UNI-V",
                  "Sort": 0,
                  "FromYearId": 71,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 71,
                      "YearName": "2021",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 2,
          "BrandNameen": "Nissan",
          "BrandNamear": "نيسان",
          "BrandNameku": "نیسان",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 111,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 34,
          "Attachment": {
              "ID": 34,
              "Url": "/img/BrandAttachments\\1637589213218.6526_Nissan.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "nissan-logo-2020-black (1).png",
              "Size": 54964.0
          },
          "Sort": 111,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 81,
                  "ModelNameen": "Altima",
                  "ModelNamear": "ألتيما",
                  "ModelNameku": "ئەلتیما",
                  "Sort": 100,
                  "FromYearId": 42,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 42,
                      "YearName": "1992",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 71,
                  "ModelNameen": "Sentra",
                  "ModelNamear": "سنترا",
                  "ModelNameku": "سێنترا",
                  "Sort": 98,
                  "FromYearId": 32,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 32,
                      "YearName": "1982",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 75,
                  "ModelNameen": "Patrol",
                  "ModelNamear": "باترول",
                  "ModelNameku": "پاترۆل",
                  "Sort": 96,
                  "FromYearId": 1,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 1,
                      "YearName": "1951",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 927,
                  "ModelNameen": "Rogue",
                  "ModelNamear": "روج",
                  "ModelNameku": "رۆگ",
                  "Sort": 94,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 72,
                  "ModelNameen": "Sunny",
                  "ModelNamear": "صني",
                  "ModelNameku": "سەنی",
                  "Sort": 92,
                  "FromYearId": 16,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 16,
                      "YearName": "1966",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 83,
                  "ModelNameen": "Pathfinder",
                  "ModelNamear": "باثفايندر",
                  "ModelNameku": "پاسفایندەر",
                  "Sort": 90,
                  "FromYearId": 52,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 82,
                  "ModelNameen": "Maxima",
                  "ModelNamear": "ماكسيما",
                  "ModelNameku": "مەگزیما",
                  "Sort": 88,
                  "FromYearId": 31,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 31,
                      "YearName": "1981",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 73,
                  "ModelNameen": "X-Trail",
                  "ModelNamear": "إكس تريل",
                  "ModelNameku": "ئێکس ترەیل",
                  "Sort": 87,
                  "FromYearId": 50,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 50,
                      "YearName": "2000",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 925,
                  "ModelNameen": "Versa",
                  "ModelNamear": "فيرسا",
                  "ModelNameku": "ڤێرسا",
                  "Sort": 86,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 76,
                  "ModelNameen": "Patrol Safari",
                  "ModelNamear": "باترول سفاري",
                  "ModelNameku": "پاترۆل سەفاری",
                  "Sort": 82,
                  "FromYearId": 1,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 1,
                      "YearName": "1951",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 70,
                  "ModelNameen": "Tiida",
                  "ModelNamear": "تيدا",
                  "ModelNameku": "تیدا",
                  "Sort": 80,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 385,
                  "ModelNameen": "Qashqai",
                  "ModelNamear": "قاشقاي",
                  "ModelNameku": "قەشقای",
                  "Sort": 78,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 79,
                  "ModelNameen": "Navara",
                  "ModelNamear": "نافارا",
                  "ModelNameku": "نەڤارا",
                  "Sort": 70,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 383,
                  "ModelNameen": "Kicks",
                  "ModelNamear": "كيكس",
                  "ModelNameku": "کیکس",
                  "Sort": 68,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 380,
                  "ModelNameen": "350Z",
                  "ModelNamear": "350Z",
                  "ModelNameku": "350Z",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 378,
                  "ModelNameen": "370Z",
                  "ModelNamear": "370Z",
                  "ModelNameku": "370Z",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 77,
                  "ModelNameen": "Armada",
                  "ModelNamear": "ارمادا",
                  "ModelNameku": "ئەرمادە",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1043,
                  "ModelNameen": "Cedric",
                  "ModelNamear": "سيدرك",
                  "ModelNameku": "سیدریک",
                  "Sort": 0,
                  "FromYearId": 10,
                  "ToYearId": 54,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 10,
                      "YearName": "1960",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1063,
                  "ModelNameen": "Civilian",
                  "ModelNamear": "سيفيليان",
                  "ModelNameku": "سيڤيليان",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1092,
                  "ModelNameen": "Datsun",
                  "ModelNamear": "داتسون",
                  "ModelNameku": "داتسون",
                  "Sort": 0,
                  "FromYearId": 20,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 20,
                      "YearName": "1970",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1384,
                  "ModelNameen": "Elgrand",
                  "ModelNamear": "الجراند",
                  "ModelNameku": "ئێلگراند",
                  "Sort": 0,
                  "FromYearId": 47,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 47,
                      "YearName": "1997",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 941,
                  "ModelNameen": "Frontier",
                  "ModelNamear": "فرونتر",
                  "ModelNameku": "فرۆنتیر",
                  "Sort": 0,
                  "FromYearId": 35,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 35,
                      "YearName": "1985",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 379,
                  "ModelNameen": "GT-R",
                  "ModelNamear": "جي تي آر",
                  "ModelNameku": "GT-R",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 384,
                  "ModelNameen": "Juke",
                  "ModelNamear": "جوك",
                  "ModelNameku": "جوک",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 381,
                  "ModelNameen": "Leaf",
                  "ModelNamear": "ليف",
                  "ModelNameku": "لیف",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 382,
                  "ModelNameen": "Micra",
                  "ModelNamear": "ميكرا",
                  "ModelNameku": "میکرا",
                  "Sort": 0,
                  "FromYearId": 32,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 32,
                      "YearName": "1982",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 232,
                  "ModelNameen": "Murano",
                  "ModelNamear": "مورانو",
                  "ModelNameku": "مۆرانۆ",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1014,
                  "ModelNameen": "NP300",
                  "ModelNamear": "NP300",
                  "ModelNameku": "NP300",
                  "Sort": 0,
                  "FromYearId": 35,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 35,
                      "YearName": "1985",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 942,
                  "ModelNameen": "NV",
                  "ModelNamear": "NV",
                  "ModelNameku": "NV",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1100,
                  "ModelNameen": "NV200",
                  "ModelNamear": "NV200",
                  "ModelNameku": "NV200",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 74,
                  "ModelNameen": "Patrol Pickup",
                  "ModelNamear": "باترول بيك اب",
                  "ModelNameku": "پاترۆل پیکەپ",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 80,
                  "ModelNameen": "Pickup",
                  "ModelNamear": "بيك اب",
                  "ModelNameku": "پیکەپ",
                  "Sort": 0,
                  "FromYearId": 35,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 35,
                      "YearName": "1985",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 943,
                  "ModelNameen": "Primera",
                  "ModelNamear": "بريميرا",
                  "ModelNameku": "پریمێرا",
                  "Sort": 0,
                  "FromYearId": 40,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 40,
                      "YearName": "1990",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 944,
                  "ModelNameen": "Pulsar",
                  "ModelNamear": "بولسار",
                  "ModelNameku": "پولسار",
                  "Sort": 0,
                  "FromYearId": 28,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 28,
                      "YearName": "1978",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 945,
                  "ModelNameen": "Quest",
                  "ModelNamear": "كويست",
                  "ModelNameku": "کوێست",
                  "Sort": 0,
                  "FromYearId": 42,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 42,
                      "YearName": "1992",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 975,
                  "ModelNameen": "Rogue Sport",
                  "ModelNamear": "روج سبورت",
                  "ModelNameku": "ڕۆگ سپۆرت",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1250,
                  "ModelNameen": "Sylphy",
                  "ModelNamear": "سيلفي",
                  "ModelNameku": "سیلفی",
                  "Sort": 0,
                  "FromYearId": 50,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 50,
                      "YearName": "2000",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 946,
                  "ModelNameen": "Teana",
                  "ModelNamear": "تيانا",
                  "ModelNameku": "تینا",
                  "Sort": 0,
                  "FromYearId": 53,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 947,
                  "ModelNameen": "Terrano",
                  "ModelNamear": "تيرانو",
                  "ModelNameku": "تێرانۆ",
                  "Sort": 0,
                  "FromYearId": 36,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 36,
                      "YearName": "1986",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 948,
                  "ModelNameen": "Titan",
                  "ModelNamear": "تيتان",
                  "ModelNameku": "تایتان",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 69,
                  "ModelNameen": "Urvan",
                  "ModelNamear": "اورفان",
                  "ModelNameku": "ئورڤان",
                  "Sort": 0,
                  "FromYearId": 53,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 926,
                  "ModelNameen": "Versa Note",
                  "ModelNamear": "فيرسا نوت",
                  "ModelNameku": "ڤێرسا نۆت",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 231,
                  "ModelNameen": "Xterra",
                  "ModelNamear": "إكستيرا",
                  "ModelNameku": "ئێکستێرا",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1326,
                  "ModelNameen": "Z",
                  "ModelNamear": "Z",
                  "ModelNameku": "Z",
                  "Sort": 0,
                  "FromYearId": 72,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 72,
                      "YearName": "2022",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 87,
          "BrandNameen": "Ford",
          "BrandNamear": "فورد",
          "BrandNameku": "فۆرد",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 110,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 33,
          "Attachment": {
              "ID": 33,
              "Url": "/img/BrandAttachments\\1728388004823.3416_Ford.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "1724763634543.8572_Ford-removebg-preview (1).png",
              "Size": 75030.0
          },
          "Sort": 110,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 908,
                  "ModelNameen": "Mustang",
                  "ModelNamear": "موستانج",
                  "ModelNameku": "مۆستانگ",
                  "Sort": 102,
                  "FromYearId": 14,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 14,
                      "YearName": "1964",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 903,
                  "ModelNameen": "Explorer",
                  "ModelNamear": "إكسبلورر",
                  "ModelNameku": "ئێکسپلۆرەر",
                  "Sort": 100,
                  "FromYearId": 41,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 41,
                      "YearName": "1991",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 837,
                  "ModelNameen": "F-150",
                  "ModelNamear": "إف-150",
                  "ModelNameku": "F-150",
                  "Sort": 99,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 900,
                  "ModelNameen": "Fusion",
                  "ModelNamear": "فيوجن",
                  "ModelNameku": "فیوژن",
                  "Sort": 98,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1040,
                  "ModelNameen": "Bronco",
                  "ModelNamear": "برونكو",
                  "ModelNameku": "بڕۆنکۆ",
                  "Sort": 97,
                  "FromYearId": 71,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 71,
                      "YearName": "2021",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 902,
                  "ModelNameen": "Edge",
                  "ModelNamear": "ادج",
                  "ModelNameku": "ئێدج",
                  "Sort": 96,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 898,
                  "ModelNameen": "Expedition",
                  "ModelNamear": "إكسبيديشن",
                  "ModelNameku": "ئێکسپێدیشن",
                  "Sort": 95,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 906,
                  "ModelNameen": "Taurus",
                  "ModelNamear": "تورس",
                  "ModelNameku": "تۆرەس",
                  "Sort": 94,
                  "FromYearId": 36,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 36,
                      "YearName": "1986",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 867,
                  "ModelNameen": "F-150 Raptor",
                  "ModelNamear": "F-150 رابتور",
                  "ModelNameku": "F-150 راپتۆر",
                  "Sort": 93,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1127,
                  "ModelNameen": "Territory",
                  "ModelNamear": "تيريتوري",
                  "ModelNameku": "ترێتۆری",
                  "Sort": 92,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 832,
                  "ModelNameen": "Focus",
                  "ModelNamear": "فوكس",
                  "ModelNameku": "فۆکەس",
                  "Sort": 90,
                  "FromYearId": 48,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 48,
                      "YearName": "1998",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 905,
                  "ModelNameen": "Figo",
                  "ModelNamear": "فيغو",
                  "ModelNameku": "فیگۆ",
                  "Sort": 88,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 911,
                  "ModelNameen": "Fiesta",
                  "ModelNamear": "فييستا",
                  "ModelNameku": "فێستا",
                  "Sort": 86,
                  "FromYearId": 26,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 26,
                      "YearName": "1976",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 833,
                  "ModelNameen": "Escape",
                  "ModelNamear": "إسكيب",
                  "ModelNameku": "ئێسکەیپ",
                  "Sort": 84,
                  "FromYearId": 51,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 51,
                      "YearName": "2001",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1331,
                  "ModelNameen": "Bronco Raptor",
                  "ModelNamear": "برونكو رابتور",
                  "ModelNameku": "برۆنکۆ ڕاپتۆر",
                  "Sort": 0,
                  "FromYearId": 71,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 71,
                      "YearName": "2021",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1035,
                  "ModelNameen": "Bronco Sport",
                  "ModelNamear": "برونكو سبورت",
                  "ModelNameku": "برۆنکۆ سپۆرت",
                  "Sort": 0,
                  "FromYearId": 71,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 71,
                      "YearName": "2021",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 895,
                  "ModelNameen": "Crown Victoria",
                  "ModelNamear": "كراون فيكتوريا",
                  "ModelNameku": "کراون ڤیکتۆریا",
                  "Sort": 0,
                  "FromYearId": 46,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 46,
                      "YearName": "1996",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1207,
                  "ModelNameen": "E-350",
                  "ModelNamear": "إي-350",
                  "ModelNameku": "E-350",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 986,
                  "ModelNameen": "Econoline",
                  "ModelNamear": "إيكونولين",
                  "ModelNameku": "ئیکۆنۆلاین",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 912,
                  "ModelNameen": "Ecosport",
                  "ModelNamear": "إيكوسبورت",
                  "ModelNameku": "ئیکۆ سپۆرت",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 831,
                  "ModelNameen": "Escort",
                  "ModelNamear": "إسكورت",
                  "ModelNameku": "اسکورت",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 904,
                  "ModelNameen": "Everest",
                  "ModelNamear": "افرست",
                  "ModelNameku": "ئێڤێرێست",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1298,
                  "ModelNameen": "Explorer Sport Trac",
                  "ModelNamear": "إكسبلورر سبورت تراك",
                  "ModelNameku": "ئێکسپلۆرەر سپۆرت تراک",
                  "Sort": 0,
                  "FromYearId": 51,
                  "ToYearId": 60,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 51,
                      "YearName": "2001",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1148,
                  "ModelNameen": "F-250",
                  "ModelNamear": "إف-250",
                  "ModelNameku": "F-250",
                  "Sort": 0,
                  "FromYearId": 49,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 49,
                      "YearName": "1999",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 993,
                  "ModelNameen": "F-350",
                  "ModelNamear": "إف-350",
                  "ModelNameku": "F-350",
                  "Sort": 0,
                  "FromYearId": 49,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 49,
                      "YearName": "1999",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 868,
                  "ModelNameen": "Five Hundred",
                  "ModelNamear": "Five Hundred",
                  "ModelNameku": "Five Hundred",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 834,
                  "ModelNameen": "Flex",
                  "ModelNamear": "فليكس",
                  "ModelNameku": "فلێکس",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 835,
                  "ModelNameen": "Freestar",
                  "ModelNamear": "فريستار",
                  "ModelNameku": "فری ستار",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 910,
                  "ModelNameen": "GT",
                  "ModelNamear": "GT",
                  "ModelNameku": "GT",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1332,
                  "ModelNameen": "Ka",
                  "ModelNamear": "كا",
                  "ModelNameku": "کا",
                  "Sort": 0,
                  "FromYearId": 46,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 46,
                      "YearName": "1996",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1101,
                  "ModelNameen": "Maverick",
                  "ModelNamear": "مافريك",
                  "ModelNameku": "ماڤیریک",
                  "Sort": 0,
                  "FromYearId": 71,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 71,
                      "YearName": "2021",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1089,
                  "ModelNameen": "Mercury",
                  "ModelNamear": "ميركوري",
                  "ModelNameku": "مێرکوری",
                  "Sort": 0,
                  "FromYearId": 4,
                  "ToYearId": 61,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 4,
                      "YearName": "1954",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 901,
                  "ModelNameen": "Mondeo",
                  "ModelNamear": "مونديو",
                  "ModelNameku": "موندیۆ",
                  "Sort": 0,
                  "FromYearId": 43,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 43,
                      "YearName": "1993",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1286,
                  "ModelNameen": "Mustang Mach-E",
                  "ModelNamear": "موستانج Mach-E",
                  "ModelNameku": "مۆستانگ Mach-E",
                  "Sort": 0,
                  "FromYearId": 71,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 71,
                      "YearName": "2021",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 899,
                  "ModelNameen": "Ranger",
                  "ModelNamear": "رانجر",
                  "ModelNameku": "رانجر",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1257,
                  "ModelNameen": "Scorpio",
                  "ModelNamear": "سكوربيو",
                  "ModelNameku": "سکۆرپیۆ",
                  "Sort": 0,
                  "FromYearId": 35,
                  "ToYearId": 48,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 35,
                      "YearName": "1985",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 48,
                      "YearName": "1998",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 909,
                  "ModelNameen": "Shelby GT500",
                  "ModelNamear": "شيلبي جي تي 500",
                  "ModelNameku": "شێڵبی GT500",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1087,
                  "ModelNameen": "Taurus X",
                  "ModelNamear": "توروس X",
                  "ModelNameku": "توروس X",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": 59,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 997,
                  "ModelNameen": "Thunderbird",
                  "ModelNamear": "ثندربيرد",
                  "ModelNameku": "ثندربيرد",
                  "Sort": 0,
                  "FromYearId": 1,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 1,
                      "YearName": "1951",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 827,
                  "ModelNameen": "Transit",
                  "ModelNamear": "ترانست",
                  "ModelNameku": "ترانسیت",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 987,
                  "ModelNameen": "Windstar",
                  "ModelNamear": "ويند ستار",
                  "ModelNameku": "ویند ستار",
                  "Sort": 0,
                  "FromYearId": 45,
                  "ToYearId": 53,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 45,
                      "YearName": "1995",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 77,
          "BrandNameen": "Hyundai",
          "BrandNamear": "هيونداي",
          "BrandNameku": "هیوندای",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 108,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 35,
          "Attachment": {
              "ID": 35,
              "Url": "/img/BrandAttachments\\1598260917025.2678_Hyundai",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": null,
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260917025.2678_Hyundai",
              "Size": 79161.0
          },
          "Sort": 108,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 740,
                  "ModelNameen": "Elantra",
                  "ModelNamear": "النترا",
                  "ModelNameku": "ئەلەنترا",
                  "Sort": 100,
                  "FromYearId": 41,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 41,
                      "YearName": "1991",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 747,
                  "ModelNameen": "Sonata",
                  "ModelNamear": "سوناتا",
                  "ModelNameku": "سۆناتا",
                  "Sort": 98,
                  "FromYearId": 35,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 35,
                      "YearName": "1985",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 742,
                  "ModelNameen": "Tucson",
                  "ModelNamear": "توسان",
                  "ModelNameku": "تۆکسۆن",
                  "Sort": 96,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 741,
                  "ModelNameen": "Santa Fe",
                  "ModelNamear": "سانتافي",
                  "ModelNameku": "سەنتافی",
                  "Sort": 94,
                  "FromYearId": 50,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 50,
                      "YearName": "2000",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 703,
                  "ModelNameen": "Accent",
                  "ModelNamear": "أكسنت",
                  "ModelNameku": "ئەکسێنت",
                  "Sort": 92,
                  "FromYearId": 44,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 44,
                      "YearName": "1994",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 698,
                  "ModelNameen": "Azera",
                  "ModelNamear": "أزيرا",
                  "ModelNameku": "ازێرا",
                  "Sort": 88,
                  "FromYearId": 36,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 36,
                      "YearName": "1986",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 705,
                  "ModelNameen": "Kona",
                  "ModelNamear": "كونا",
                  "ModelNameku": "کۆنا",
                  "Sort": 86,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 723,
                  "ModelNameen": "Palisade",
                  "ModelNamear": "باليسيد",
                  "ModelNameku": "پالیسەید",
                  "Sort": 85,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 737,
                  "ModelNameen": "Veloster",
                  "ModelNamear": "فلوستر",
                  "ModelNameku": "ڤیلۆستەر",
                  "Sort": 84,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 746,
                  "ModelNameen": "Genesis",
                  "ModelNamear": "جنسس",
                  "ModelNameku": "جێنسس",
                  "Sort": 83,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 702,
                  "ModelNameen": "i20",
                  "ModelNamear": "i20",
                  "ModelNameku": "i20",
                  "Sort": 82,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 739,
                  "ModelNameen": "i30",
                  "ModelNamear": "i30",
                  "ModelNameku": "i30",
                  "Sort": 80,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 720,
                  "ModelNameen": "i40",
                  "ModelNamear": "i40",
                  "ModelNameku": "i40",
                  "Sort": 78,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 699,
                  "ModelNameen": "Genesis Coupe",
                  "ModelNamear": "جينيسيس كوبيه",
                  "ModelNameku": "جێنسس کوپ",
                  "Sort": 72,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 696,
                  "ModelNameen": "Atos Prime",
                  "ModelNamear": "أتوس برايم",
                  "ModelNameku": "ئەتۆس پرایم",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 951,
                  "ModelNameen": "Avante",
                  "ModelNamear": "أفانتي",
                  "ModelNameku": "ئەڤانتی",
                  "Sort": 0,
                  "FromYearId": 35,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 35,
                      "YearName": "1985",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1366,
                  "ModelNameen": "Casper",
                  "ModelNamear": "كاسبر",
                  "ModelNameku": "کاسپەر",
                  "Sort": 0,
                  "FromYearId": 71,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 71,
                      "YearName": "2021",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 745,
                  "ModelNameen": "Centennial",
                  "ModelNamear": "سينتينيال",
                  "ModelNameku": "سێنتیانێل",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 700,
                  "ModelNameen": "Coupe",
                  "ModelNamear": "كوبيه",
                  "ModelNameku": "کوپ",
                  "Sort": 0,
                  "FromYearId": 53,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 704,
                  "ModelNameen": "Creta",
                  "ModelNamear": "كريتا",
                  "ModelNameku": "کرێتا",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 952,
                  "ModelNameen": "Entourage",
                  "ModelNamear": "انتورج",
                  "ModelNameku": "ئینتۆرەیج",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1358,
                  "ModelNameen": "Equus",
                  "ModelNamear": "ايكوس",
                  "ModelNameku": "ئیکووس",
                  "Sort": 0,
                  "FromYearId": 49,
                  "ToYearId": 66,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 49,
                      "YearName": "1999",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 953,
                  "ModelNameen": "Excel",
                  "ModelNamear": "اكسل",
                  "ModelNameku": "ئەکسل",
                  "Sort": 0,
                  "FromYearId": 35,
                  "ToYearId": 50,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 35,
                      "YearName": "1985",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 50,
                      "YearName": "2000",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 954,
                  "ModelNameen": "Galloper",
                  "ModelNamear": "جالوبر",
                  "ModelNameku": "گالوبەر",
                  "Sort": 0,
                  "FromYearId": 40,
                  "ToYearId": 55,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 40,
                      "YearName": "1990",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 701,
                  "ModelNameen": "Getz",
                  "ModelNamear": "جيتز",
                  "ModelNameku": "گێتز",
                  "Sort": 0,
                  "FromYearId": 53,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 697,
                  "ModelNameen": "Grand i10",
                  "ModelNamear": "جراند آي 10",
                  "ModelNameku": "گراند i10",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 748,
                  "ModelNameen": "Grand Santa Fe",
                  "ModelNamear": "جراند سانتافي",
                  "ModelNameku": "گراند سەنتافی",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 743,
                  "ModelNameen": "H-1",
                  "ModelNamear": "H-1",
                  "ModelNameku": "H-1",
                  "Sort": 0,
                  "FromYearId": 40,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 40,
                      "YearName": "1990",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 695,
                  "ModelNameen": "i10",
                  "ModelNamear": "i10",
                  "ModelNameku": "i10",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1160,
                  "ModelNameen": "Ioniq",
                  "ModelNamear": "ايونك",
                  "ModelNameku": "ئایۆنیک",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1362,
                  "ModelNameen": "Kona Electric",
                  "ModelNamear": "كونا الكتريك",
                  "ModelNameku": "کۆنا ئەلیکتریک",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 736,
                  "ModelNameen": "Matrix",
                  "ModelNamear": "ماتريكس",
                  "ModelNameku": "ماتریکس",
                  "Sort": 0,
                  "FromYearId": 52,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1389,
                  "ModelNameen": "Nexo",
                  "ModelNamear": "نيكسو",
                  "ModelNameku": "نێکسۆ",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 955,
                  "ModelNameen": "Reina",
                  "ModelNamear": "رينا",
                  "ModelNameku": "رێینا",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1104,
                  "ModelNameen": "Santa Cruz",
                  "ModelNamear": "سانتا كروز",
                  "ModelNameku": "سانتا کروز",
                  "Sort": 0,
                  "FromYearId": 72,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 72,
                      "YearName": "2022",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1018,
                  "ModelNameen": "Starex",
                  "ModelNamear": "ستار اكس",
                  "ModelNameku": "ستاركس",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1393,
                  "ModelNameen": "Stargazer",
                  "ModelNamear": "ستار جازر",
                  "ModelNameku": "ستارگەیزەر",
                  "Sort": 0,
                  "FromYearId": 72,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 72,
                      "YearName": "2022",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1107,
                  "ModelNameen": "Staria",
                  "ModelNamear": "ستاريا",
                  "ModelNameku": "ستاریا",
                  "Sort": 0,
                  "FromYearId": 71,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 71,
                      "YearName": "2021",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 749,
                  "ModelNameen": "Terracan",
                  "ModelNamear": "تيراكان",
                  "ModelNameku": "تێراکان",
                  "Sort": 0,
                  "FromYearId": 53,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 744,
                  "ModelNameen": "Trajet",
                  "ModelNamear": "تراجت",
                  "ModelNameku": "تراجێت",
                  "Sort": 0,
                  "FromYearId": 52,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 985,
                  "ModelNameen": "Venue",
                  "ModelNamear": "فينو",
                  "ModelNameku": "ڤینیو",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 722,
                  "ModelNameen": "Veracruz",
                  "ModelNamear": "فيراكروز",
                  "ModelNameku": "ڤێرە کروز",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1071,
                  "ModelNameen": "Verna",
                  "ModelNamear": "فيرنا",
                  "ModelNameku": "ڤێرنا",
                  "Sort": 0,
                  "FromYearId": 44,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 44,
                      "YearName": "1994",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 956,
                  "ModelNameen": "XG",
                  "ModelNamear": "XG",
                  "ModelNameku": "XG",
                  "Sort": 0,
                  "FromYearId": 47,
                  "ToYearId": 56,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 47,
                      "YearName": "1997",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 19,
          "BrandNameen": "Land Rover",
          "BrandNamear": "لاند روفر",
          "BrandNameku": "لاند ڕۆڤەر",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 106,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 86,
          "Attachment": {
              "ID": 86,
              "Url": "/img/BrandAttachments\\1598260861653.8472_Land Rover",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260861653.8472_Land Rover",
              "Size": 77647.0
          },
          "Sort": 106,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 160,
                  "ModelNameen": "Range Rover Sport",
                  "ModelNamear": "رنج روفر سبورت",
                  "ModelNameku": "رەنج رۆڤەر سپۆرت",
                  "Sort": 100,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 159,
                  "ModelNameen": "Range Rover Vogue",
                  "ModelNamear": "رينج روفر فوج",
                  "ModelNameku": "رەنج رۆڤەر ڤۆگ",
                  "Sort": 90,
                  "FromYearId": 40,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 40,
                      "YearName": "1990",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 170,
                  "ModelNameen": "Range Rover Evoque",
                  "ModelNamear": "رينج روفر إيفوك",
                  "ModelNameku": "رەنج رۆڤەر ئیڤۆک",
                  "Sort": 80,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 162,
                  "ModelNameen": "Range Rover Velar",
                  "ModelNamear": "رنج روفر فلار",
                  "ModelNameku": "رەنج رۆڤەر ڤێلار",
                  "Sort": 70,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 167,
                  "ModelNameen": "Discovery",
                  "ModelNamear": "ديسكوفري",
                  "ModelNameku": "دیسکۆڤەری",
                  "Sort": 60,
                  "FromYearId": 39,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 39,
                      "YearName": "1989",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 163,
                  "ModelNameen": "Defender",
                  "ModelNamear": "دفندر",
                  "ModelNameku": "دیفێندەر",
                  "Sort": 0,
                  "FromYearId": 29,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 29,
                      "YearName": "1979",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 165,
                  "ModelNameen": "Freelander",
                  "ModelNamear": "فريلاندر",
                  "ModelNameku": "فریلاندەر",
                  "Sort": 0,
                  "FromYearId": 51,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 51,
                      "YearName": "2001",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 166,
                  "ModelNameen": "LR2",
                  "ModelNamear": "LR2",
                  "ModelNameku": "LR2",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 168,
                  "ModelNameen": "LR3",
                  "ModelNamear": "LR3",
                  "ModelNameku": "LR3",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 169,
                  "ModelNameen": "LR4",
                  "ModelNamear": "LR4",
                  "ModelNameku": "LR4",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1036,
                  "ModelNameen": "Pickup",
                  "ModelNamear": "بيك اب",
                  "ModelNameku": "پیکاپ",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 63,
          "BrandNameen": "Audi",
          "BrandNamear": "اودي",
          "BrandNameku": "ئاودی",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 102,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 70,
          "Attachment": {
              "ID": 70,
              "Url": "/img/BrandAttachments\\1598260899951.4475_Audi",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260899951.4475_Audi",
              "Size": 39600.0
          },
          "Sort": 102,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 593,
                  "ModelNameen": "A3",
                  "ModelNamear": "A3",
                  "ModelNameku": "A3",
                  "Sort": 100,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 587,
                  "ModelNameen": "A6",
                  "ModelNamear": "A6",
                  "ModelNameku": "A6",
                  "Sort": 90,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 594,
                  "ModelNameen": "A7",
                  "ModelNamear": "A7",
                  "ModelNameku": "A7",
                  "Sort": 80,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 596,
                  "ModelNameen": "Q7",
                  "ModelNamear": "Q7",
                  "ModelNameku": "Q7",
                  "Sort": 60,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 597,
                  "ModelNameen": "Q8",
                  "ModelNamear": "Q8",
                  "ModelNameku": "Q8",
                  "Sort": 50,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1317,
                  "ModelNameen": "80",
                  "ModelNamear": "80",
                  "ModelNameku": "80",
                  "Sort": 0,
                  "FromYearId": 16,
                  "ToYearId": 46,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 16,
                      "YearName": "1966",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 46,
                      "YearName": "1996",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 591,
                  "ModelNameen": "A1",
                  "ModelNamear": "A1",
                  "ModelNameku": "A1",
                  "Sort": 0,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 589,
                  "ModelNameen": "A4",
                  "ModelNamear": "A4",
                  "ModelNameku": "A4",
                  "Sort": 0,
                  "FromYearId": 51,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 51,
                      "YearName": "2001",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 599,
                  "ModelNameen": "A5",
                  "ModelNamear": "A5",
                  "ModelNameku": "A5",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 585,
                  "ModelNameen": "A8",
                  "ModelNamear": "A8",
                  "ModelNameku": "A8",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 603,
                  "ModelNameen": "E-Tron",
                  "ModelNamear": "إي-ترون",
                  "ModelNameku": "ئێ ترۆن",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 673,
                  "ModelNameen": "Q2",
                  "ModelNamear": "Q2",
                  "ModelNameku": "Q2",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 605,
                  "ModelNameen": "Q3",
                  "ModelNamear": "Q3",
                  "ModelNameku": "Q3",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 635,
                  "ModelNameen": "Q5",
                  "ModelNamear": "Q5",
                  "ModelNameku": "Q5",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 668,
                  "ModelNameen": "R8",
                  "ModelNamear": "R8",
                  "ModelNameku": "R8",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 600,
                  "ModelNameen": "RS5",
                  "ModelNamear": "RS5",
                  "ModelNameku": "RS5",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1187,
                  "ModelNameen": "S4",
                  "ModelNamear": "S4",
                  "ModelNameku": "S4",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 601,
                  "ModelNameen": "S5",
                  "ModelNamear": "S5",
                  "ModelNameku": "S5",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 636,
                  "ModelNameen": "S6",
                  "ModelNamear": "S6",
                  "ModelNameku": "S6",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 586,
                  "ModelNameen": "S8",
                  "ModelNamear": "S8",
                  "ModelNameku": "S8",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1202,
                  "ModelNameen": "SQ8",
                  "ModelNamear": "SQ8",
                  "ModelNameku": "SQ8",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 669,
                  "ModelNameen": "TT",
                  "ModelNamear": "تيتي",
                  "ModelNameku": "تی تی",
                  "Sort": 0,
                  "FromYearId": 49,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 49,
                      "YearName": "1999",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 72,
          "BrandNameen": "Jaguar",
          "BrandNamear": "جاكوار",
          "BrandNameku": "جاگوار",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 100,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 77,
          "Attachment": {
              "ID": 77,
              "Url": "/img/BrandAttachments\\1598260914219.6172_Jaguar",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260914219.6172_Jaguar",
              "Size": 42561.0
          },
          "Sort": 100,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 778,
                  "ModelNameen": "E-Pace",
                  "ModelNamear": "إي- بيس",
                  "ModelNameku": "E-Pace",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 804,
                  "ModelNameen": "F-Pace",
                  "ModelNamear": "إف-بيس",
                  "ModelNameku": "F-Pace",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 806,
                  "ModelNameen": "F-Type",
                  "ModelNamear": "إف-تايب",
                  "ModelNameku": "F-Type",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 805,
                  "ModelNameen": "I-Pace",
                  "ModelNamear": "آي بيس",
                  "ModelNameku": "I-Pace",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 799,
                  "ModelNameen": "S-Type",
                  "ModelNamear": "إس تايب",
                  "ModelNameku": "S-Type",
                  "Sort": 0,
                  "FromYearId": 53,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 803,
                  "ModelNameen": "X-Type",
                  "ModelNamear": "إكس تايب",
                  "ModelNameku": "X-Type",
                  "Sort": 0,
                  "FromYearId": 52,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 802,
                  "ModelNameen": "XE",
                  "ModelNamear": "XE",
                  "ModelNameku": "XE",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 796,
                  "ModelNameen": "XF",
                  "ModelNamear": "XF",
                  "ModelNameku": "XF",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 795,
                  "ModelNameen": "XJ",
                  "ModelNamear": "XJ",
                  "ModelNameku": "XJ",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 801,
                  "ModelNameen": "XK",
                  "ModelNamear": "XK",
                  "ModelNameku": "XK",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 51,
          "BrandNameen": "Chevrolet",
          "BrandNamear": "شيفروليه",
          "BrandNameku": "شێڤرۆلێت",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 98,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 28,
          "Attachment": {
              "ID": 28,
              "Url": "/img/BrandAttachments\\1598260885500.0466_Chevrolet",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260885500.0466_Chevrolet",
              "Size": 76922.0
          },
          "Sort": 98,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 555,
                  "ModelNameen": "Tahoe",
                  "ModelNamear": "تاهو",
                  "ModelNameku": "تاهۆ",
                  "Sort": 100,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 569,
                  "ModelNameen": "Camaro",
                  "ModelNamear": "كامارو",
                  "ModelNameku": "کەمارۆ",
                  "Sort": 98,
                  "FromYearId": 16,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 16,
                      "YearName": "1966",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 559,
                  "ModelNameen": "Malibu",
                  "ModelNamear": "ماليبو",
                  "ModelNameku": "مالیبۆ",
                  "Sort": 96,
                  "FromYearId": 14,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 14,
                      "YearName": "1964",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 552,
                  "ModelNameen": "Silverado",
                  "ModelNamear": "سلفرادو",
                  "ModelNameku": "سلڤەرادۆ",
                  "Sort": 94,
                  "FromYearId": 53,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 570,
                  "ModelNameen": "Corvette",
                  "ModelNamear": "كورفيت",
                  "ModelNameku": "کۆرڤێت",
                  "Sort": 92,
                  "FromYearId": 29,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 29,
                      "YearName": "1979",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 566,
                  "ModelNameen": "Impala",
                  "ModelNamear": "امبالا",
                  "ModelNameku": "ئیمپالا",
                  "Sort": 90,
                  "FromYearId": 8,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 8,
                      "YearName": "1958",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 562,
                  "ModelNameen": "Blazer",
                  "ModelNamear": "بليزر",
                  "ModelNameku": "بلایزەر",
                  "Sort": 88,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 550,
                  "ModelNameen": "Equinox",
                  "ModelNamear": "ايكونس",
                  "ModelNameku": "ئیکۆنۆکس",
                  "Sort": 84,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 564,
                  "ModelNameen": "Traverse",
                  "ModelNamear": "ترافرس",
                  "ModelNameku": "تراڤێرس",
                  "Sort": 82,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 519,
                  "ModelNameen": "Optra",
                  "ModelNamear": "اوبترا",
                  "ModelNameku": "ئۆپترا",
                  "Sort": 5,
                  "FromYearId": 53,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 518,
                  "ModelNameen": "Cruze",
                  "ModelNamear": "كروز",
                  "ModelNameku": "کروز",
                  "Sort": 3,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 556,
                  "ModelNameen": "Avalanche",
                  "ModelNamear": "افلانش",
                  "ModelNameku": "ئەڤەلانچی",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 574,
                  "ModelNameen": "Aveo",
                  "ModelNamear": "أفيو",
                  "ModelNameku": "ئەڤیۆ",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 958,
                  "ModelNameen": "Bel Air",
                  "ModelNamear": "بيل اير",
                  "ModelNameku": "بێل ئێر",
                  "Sort": 0,
                  "FromYearId": 1,
                  "ToYearId": 32,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 1,
                      "YearName": "1951",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 32,
                      "YearName": "1982",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 517,
                  "ModelNameen": "Bolt",
                  "ModelNamear": "بولت",
                  "ModelNameku": "بۆلت",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1197,
                  "ModelNameen": "C10",
                  "ModelNamear": "C10",
                  "ModelNameku": "C10",
                  "Sort": 0,
                  "FromYearId": 9,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 9,
                      "YearName": "1959",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 553,
                  "ModelNameen": "Caprice",
                  "ModelNamear": "كابريس",
                  "ModelNameku": "کاپریس",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 520,
                  "ModelNameen": "Captiva",
                  "ModelNamear": "كابتيفا",
                  "ModelNameku": "کاپتیڤا",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 959,
                  "ModelNameen": "Celebrity",
                  "ModelNamear": "سلبرتي",
                  "ModelNameku": "سلبرتی",
                  "Sort": 0,
                  "FromYearId": 31,
                  "ToYearId": 41,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 31,
                      "YearName": "1981",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 41,
                      "YearName": "1991",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 960,
                  "ModelNameen": "Cobalt",
                  "ModelNamear": "كوبالت",
                  "ModelNameku": "کۆباڵت",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 557,
                  "ModelNameen": "Colorado",
                  "ModelNamear": "كولورادو",
                  "ModelNameku": "کۆلۆرادۆ",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 961,
                  "ModelNameen": "Corvair",
                  "ModelNamear": "كورفير",
                  "ModelNameku": "کۆرڤێر",
                  "Sort": 0,
                  "FromYearId": 10,
                  "ToYearId": 19,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 10,
                      "YearName": "1960",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 19,
                      "YearName": "1969",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 567,
                  "ModelNameen": "CSV CR8",
                  "ModelNamear": "CSV CR8",
                  "ModelNameku": "CSV CR8",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1175,
                  "ModelNameen": "DeSoto",
                  "ModelNamear": "ديسوتو",
                  "ModelNameku": "دیسۆتۆ",
                  "Sort": 0,
                  "FromYearId": 1,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 1,
                      "YearName": "1951",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 560,
                  "ModelNameen": "Epica",
                  "ModelNamear": "إبيكا",
                  "ModelNameku": "ئێپیکا",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 962,
                  "ModelNameen": "Express",
                  "ModelNamear": "إكسبرس",
                  "ModelNameku": "ئێکس پرێس",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1090,
                  "ModelNameen": "Groove",
                  "ModelNamear": "جرووف",
                  "ModelNameku": "گروڤ",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 963,
                  "ModelNameen": "HHR",
                  "ModelNamear": "HHR",
                  "ModelNameku": "HHR",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": 62,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 561,
                  "ModelNameen": "Lumina",
                  "ModelNamear": "لومنا",
                  "ModelNameku": "لومینا",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 964,
                  "ModelNameen": "Monte Carlo",
                  "ModelNamear": "مونت كارلو",
                  "ModelNameku": "مۆنتێ کارلۆ",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 965,
                  "ModelNameen": "Nova",
                  "ModelNamear": "نوفا",
                  "ModelNameku": "نۆڤا",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 966,
                  "ModelNameen": "Oldsmobile",
                  "ModelNamear": "اولدزموبيل",
                  "ModelNameku": "ئۆلدز مۆبیل",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 549,
                  "ModelNameen": "Onix",
                  "ModelNamear": "أونيكس",
                  "ModelNameku": "ئۆنیکس",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1200,
                  "ModelNameen": "Orlando",
                  "ModelNamear": "اورلاندو",
                  "ModelNameku": "ئۆرلاندۆ",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 968,
                  "ModelNameen": "Sail",
                  "ModelNamear": "سايل",
                  "ModelNameku": "سەیڵ",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 575,
                  "ModelNameen": "Sonic",
                  "ModelNamear": "سونيك",
                  "ModelNameku": "سۆنیک",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 565,
                  "ModelNameen": "Spark",
                  "ModelNamear": "سبارك",
                  "ModelNameku": "سپارک",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1120,
                  "ModelNameen": "SS",
                  "ModelNamear": "SS",
                  "ModelNameku": "SS",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": 67,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 969,
                  "ModelNameen": "SSR",
                  "ModelNamear": "SSR",
                  "ModelNameku": "SSR",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": 57,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 554,
                  "ModelNameen": "Suburban",
                  "ModelNamear": "سوبربان",
                  "ModelNameku": "سوبەربان",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 558,
                  "ModelNameen": "T-Series",
                  "ModelNamear": "سلسلة تي",
                  "ModelNameku": "T-سیریەس",
                  "Sort": 0,
                  "FromYearId": 52,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 970,
                  "ModelNameen": "Tracker",
                  "ModelNamear": "تراكر",
                  "ModelNameku": "تراکەر",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 563,
                  "ModelNameen": "Trailblazer",
                  "ModelNamear": "تريل بليزر",
                  "ModelNameku": "ترەیل بلایزەر",
                  "Sort": 0,
                  "FromYearId": 52,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 546,
                  "ModelNameen": "Trax",
                  "ModelNamear": "تراكس",
                  "ModelNameku": "تراکس",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 551,
                  "ModelNameen": "Uplander",
                  "ModelNamear": "ابلاندر",
                  "ModelNameku": "ئەپلاندەر",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1365,
                  "ModelNameen": "Venture",
                  "ModelNamear": "فنتشر",
                  "ModelNameku": "ڤێنچەر",
                  "Sort": 0,
                  "FromYearId": 47,
                  "ToYearId": 55,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 47,
                      "YearName": "1997",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 68,
          "BrandNameen": "Dodge",
          "BrandNamear": "دودج",
          "BrandNameku": "دۆج",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 96,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 25,
          "Attachment": {
              "ID": 25,
              "Url": "/img/BrandAttachments\\1689687384588.3416_Dodge.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "Dodge-Logo-PNG-Image.png",
              "Size": 19549.0
          },
          "Sort": 96,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 844,
                  "ModelNameen": "Charger",
                  "ModelNamear": "تشارجر",
                  "ModelNameku": "چارجەر",
                  "Sort": 100,
                  "FromYearId": 16,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 16,
                      "YearName": "1966",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 846,
                  "ModelNameen": "Challenger",
                  "ModelNamear": "جالنجر",
                  "ModelNameku": "چالنجەر",
                  "Sort": 98,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 845,
                  "ModelNameen": "Durango",
                  "ModelNamear": "دورانجو",
                  "ModelNameku": "دۆرانگۆ",
                  "Sort": 96,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 843,
                  "ModelNameen": "Ram",
                  "ModelNamear": "رام",
                  "ModelNameku": "رام",
                  "Sort": 94,
                  "FromYearId": 52,
                  "ToYearId": 59,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 972,
                  "ModelNameen": "Journey",
                  "ModelNamear": "جورني",
                  "ModelNameku": "جۆرنی",
                  "Sort": 92,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 973,
                  "ModelNameen": "Caravan",
                  "ModelNamear": "كارافان",
                  "ModelNameku": "کەرەڤان",
                  "Sort": 90,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 694,
                  "ModelNameen": "Caliber",
                  "ModelNamear": "كاليبر",
                  "ModelNameku": "کالیبەر",
                  "Sort": 88,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 840,
                  "ModelNameen": "Dart",
                  "ModelNamear": "دارت",
                  "ModelNameku": "دارت",
                  "Sort": 86,
                  "FromYearId": 13,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 13,
                      "YearName": "1963",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1228,
                  "ModelNameen": "Grand Caravan",
                  "ModelNamear": "جراند كارافان",
                  "ModelNameku": "گراند کاراڤان",
                  "Sort": 84,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 841,
                  "ModelNameen": "Neon",
                  "ModelNamear": "نيون",
                  "ModelNameku": "نیۆن",
                  "Sort": 80,
                  "FromYearId": 53,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 849,
                  "ModelNameen": "Avenger",
                  "ModelNamear": "افنجر",
                  "ModelNameku": "ئەڤێنجەر",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 848,
                  "ModelNameen": "Dakota",
                  "ModelNamear": "داكوتا",
                  "ModelNameku": "داکوتا",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1196,
                  "ModelNameen": "Hornet",
                  "ModelNamear": "هورنيت",
                  "ModelNameku": "هۆرنێت",
                  "Sort": 0,
                  "FromYearId": 72,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 72,
                      "YearName": "2022",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1251,
                  "ModelNameen": "Magnum",
                  "ModelNamear": "ماغنوم",
                  "ModelNameku": "ماگنۆم",
                  "Sort": 0,
                  "FromYearId": 28,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 28,
                      "YearName": "1978",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 842,
                  "ModelNameen": "Nitro",
                  "ModelNamear": "نيترو",
                  "ModelNameku": "نیترۆ",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 851,
                  "ModelNameen": "Viper",
                  "ModelNamear": "فايبر",
                  "ModelNameku": "ڤایپەر",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 55,
          "BrandNameen": "Cadillac",
          "BrandNamear": "كاديلاك",
          "BrandNameku": "کادیلاک",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 94,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 66,
          "Attachment": {
              "ID": 66,
              "Url": "/img/BrandAttachments\\1691930904897.538_Cadillac.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "cadillac-logo-2021-full-download.png",
              "Size": 62747.0
          },
          "Sort": 94,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 509,
                  "ModelNameen": "Escalade",
                  "ModelNamear": "اسكاليد",
                  "ModelNameku": "ئێسکەلەید",
                  "Sort": 100,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 465,
                  "ModelNameen": "ATS",
                  "ModelNamear": "ATS",
                  "ModelNameku": "ATS",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 489,
                  "ModelNameen": "ATS Coupe",
                  "ModelNamear": "أي تي إس كوبيه",
                  "ModelNameku": "ATS کوپ",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 466,
                  "ModelNameen": "ATS-V",
                  "ModelNamear": "ايه تي اس-في",
                  "ModelNameku": "ATS-V",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 475,
                  "ModelNameen": "ATS-V Coupe",
                  "ModelNamear": "أي تي اس-في كوبيه",
                  "ModelNameku": "ATS-V کوپ",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 468,
                  "ModelNameen": "BLS",
                  "ModelNamear": "BLS",
                  "ModelNameku": "BLS",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 464,
                  "ModelNameen": "CT4",
                  "ModelNamear": "CT4",
                  "ModelNameku": "CT4",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 513,
                  "ModelNameen": "CT5",
                  "ModelNamear": "CT5",
                  "ModelNameku": "CT5",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 504,
                  "ModelNameen": "CT6",
                  "ModelNamear": "CT6",
                  "ModelNameku": "CT6",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 514,
                  "ModelNameen": "CTS",
                  "ModelNamear": "CTS",
                  "ModelNameku": "CTS",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 510,
                  "ModelNameen": "CTS Coupe",
                  "ModelNamear": "كوبيه سي تي إس",
                  "ModelNameku": "CTS کوپ",
                  "Sort": 0,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 515,
                  "ModelNameen": "CTS-V",
                  "ModelNamear": "سي تي إس- في",
                  "ModelNameku": "CTS-V",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 511,
                  "ModelNameen": "CTS-V Coupe",
                  "ModelNamear": "كوبيه سي تي إس- في",
                  "ModelNameku": "CTS-V کوپ",
                  "Sort": 0,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1091,
                  "ModelNameen": "DeVille",
                  "ModelNamear": "ديفيل",
                  "ModelNameku": "دیڤیل",
                  "Sort": 0,
                  "FromYearId": 8,
                  "ToYearId": 55,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 8,
                      "YearName": "1958",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 505,
                  "ModelNameen": "DTS",
                  "ModelNamear": "DTS",
                  "ModelNameku": "DTS",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1061,
                  "ModelNameen": "Eldorado",
                  "ModelNamear": "ئيلدورادو",
                  "ModelNameku": "ئێلدۆرادۆ",
                  "Sort": 0,
                  "FromYearId": 2,
                  "ToYearId": 52,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 2,
                      "YearName": "1952",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1311,
                  "ModelNameen": "Seville",
                  "ModelNamear": "سيفيليا",
                  "ModelNameku": "سێڤیلیا",
                  "Sort": 0,
                  "FromYearId": 25,
                  "ToYearId": 54,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 25,
                      "YearName": "1975",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 506,
                  "ModelNameen": "SLS",
                  "ModelNamear": "SLS",
                  "ModelNameku": "SLS",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 491,
                  "ModelNameen": "SRX",
                  "ModelNamear": "SRX",
                  "ModelNameku": "SRX",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 507,
                  "ModelNameen": "STS",
                  "ModelNamear": "STS",
                  "ModelNameku": "STS",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 490,
                  "ModelNameen": "XLR",
                  "ModelNamear": "XLR",
                  "ModelNameku": "XLR",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 467,
                  "ModelNameen": "XT4",
                  "ModelNamear": "XT4",
                  "ModelNameku": "XT4",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 512,
                  "ModelNameen": "XT5",
                  "ModelNamear": "XT5",
                  "ModelNameku": "XT5",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 516,
                  "ModelNameen": "XT6",
                  "ModelNamear": "XT6",
                  "ModelNameku": "XT6",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 508,
                  "ModelNameen": "XTS",
                  "ModelNamear": "XTS",
                  "ModelNameku": "XTS",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 18,
          "BrandNameen": "Lexus",
          "BrandNamear": "لكزس",
          "BrandNameku": "لێکزس",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 92,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 41,
          "Attachment": {
              "ID": 41,
              "Url": "/img/BrandAttachments\\1598260860741.1309_Lexus",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260860741.1309_Lexus",
              "Size": 37052.0
          },
          "Sort": 92,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 118,
                  "ModelNameen": "LX",
                  "ModelNamear": "LX",
                  "ModelNameku": "LX",
                  "Sort": 100,
                  "FromYearId": 48,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 48,
                      "YearName": "1998",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 127,
                  "ModelNameen": "CT",
                  "ModelNamear": "CT",
                  "ModelNameku": "CT",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 120,
                  "ModelNameen": "ES",
                  "ModelNamear": "ES",
                  "ModelNameku": "ES",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 121,
                  "ModelNameen": "GS",
                  "ModelNamear": "GS",
                  "ModelNameku": "GS",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 122,
                  "ModelNameen": "GX",
                  "ModelNamear": "GX",
                  "ModelNameku": "GX",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 128,
                  "ModelNameen": "IS",
                  "ModelNamear": "IS",
                  "ModelNameku": "IS",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 124,
                  "ModelNameen": "IS C",
                  "ModelNamear": "IS C",
                  "ModelNameku": "IS C",
                  "Sort": 0,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 129,
                  "ModelNameen": "IS F",
                  "ModelNamear": "IS F",
                  "ModelNameku": "IS F",
                  "Sort": 0,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 119,
                  "ModelNameen": "LC",
                  "ModelNamear": "LC",
                  "ModelNameku": "LC",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 131,
                  "ModelNameen": "LFA",
                  "ModelNamear": "LFA",
                  "ModelNameku": "LFA",
                  "Sort": 0,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 117,
                  "ModelNameen": "LS",
                  "ModelNamear": "LS",
                  "ModelNameku": "LS",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 130,
                  "ModelNameen": "NX",
                  "ModelNamear": "NX",
                  "ModelNameku": "NX",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 125,
                  "ModelNameen": "RC ",
                  "ModelNamear": "RC",
                  "ModelNameku": "RC",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 126,
                  "ModelNameen": "RC F",
                  "ModelNamear": "RC F",
                  "ModelNameku": "RC F",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 123,
                  "ModelNameen": "RX",
                  "ModelNamear": "RX",
                  "ModelNameku": "RX",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 142,
                  "ModelNameen": "SC",
                  "ModelNamear": "SC",
                  "ModelNameku": "SC",
                  "Sort": 0,
                  "FromYearId": 52,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1300,
                  "ModelNameen": "TX",
                  "ModelNamear": "TX",
                  "ModelNameku": "TX",
                  "Sort": 0,
                  "FromYearId": 74,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 74,
                      "YearName": "2023",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 144,
                  "ModelNameen": "UX",
                  "ModelNamear": "UX",
                  "ModelNameku": "UX",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 71,
          "BrandNameen": "Jeep",
          "BrandNamear": "جيب",
          "BrandNameku": "جیپ",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 90,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 76,
          "Attachment": {
              "ID": 76,
              "Url": "/img/BrandAttachments\\1598260913313.6658_Jeep",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260913313.6658_Jeep",
              "Size": 49419.0
          },
          "Sort": 90,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 979,
                  "ModelNameen": "Asia Rocsta",
                  "ModelNamear": "اسيا",
                  "ModelNameku": "ئاسيا",
                  "Sort": 0,
                  "FromYearId": 35,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 35,
                      "YearName": "1985",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 753,
                  "ModelNameen": "Cherokee",
                  "ModelNamear": "شيروكي",
                  "ModelNameku": "شیرۆکی",
                  "Sort": 0,
                  "FromYearId": 52,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 756,
                  "ModelNameen": "Commander",
                  "ModelNamear": "كوماندر",
                  "ModelNameku": "کۆماندەر",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 754,
                  "ModelNameen": "Compass",
                  "ModelNamear": "كومباس",
                  "ModelNameku": "کۆمپاس",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 757,
                  "ModelNameen": "Gladiator",
                  "ModelNamear": "جلاديتور",
                  "ModelNameku": "گلادیەتەر",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 758,
                  "ModelNameen": "Grand Cherokee",
                  "ModelNamear": "جراند شيروكي",
                  "ModelNameku": "گراند شیرۆکی",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1022,
                  "ModelNameen": "Grand Cherokee L",
                  "ModelNamear": "جراند شيروكي إل",
                  "ModelNameku": "L گراند شیرۆکی",
                  "Sort": 0,
                  "FromYearId": 71,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 71,
                      "YearName": "2021",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1130,
                  "ModelNameen": "Grand Wagoneer",
                  "ModelNamear": "جراند واجونير",
                  "ModelNameku": "گراند واگۆنیەر",
                  "Sort": 0,
                  "FromYearId": 72,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 72,
                      "YearName": "2022",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 984,
                  "ModelNameen": "Liberty",
                  "ModelNamear": "ليبرتي",
                  "ModelNameku": "لیبێرتی",
                  "Sort": 0,
                  "FromYearId": 51,
                  "ToYearId": 62,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 51,
                      "YearName": "2001",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 755,
                  "ModelNameen": "Patriot",
                  "ModelNamear": "باتريوت",
                  "ModelNameku": "پاتریۆت",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 763,
                  "ModelNameen": "Renegade",
                  "ModelNamear": "رينيجيد",
                  "ModelNameku": "رێنێگەید",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1195,
                  "ModelNameen": "Willys",
                  "ModelNamear": "ويليز",
                  "ModelNameku": "ویلیس",
                  "Sort": 0,
                  "FromYearId": 75,
                  "ToYearId": 2,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 75,
                      "YearName": "1929",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 2,
                      "YearName": "1952",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 759,
                  "ModelNameen": "Wrangler",
                  "ModelNamear": "رانجلر",
                  "ModelNameku": "رانگلەر",
                  "Sort": 0,
                  "FromYearId": 36,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 36,
                      "YearName": "1986",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 34,
          "BrandNameen": "Skoda",
          "BrandNamear": "سكودا",
          "BrandNameku": "سکۆدا",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 87,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 58,
          "Attachment": {
              "ID": 58,
              "Url": "/img/BrandAttachments\\1598260874127.9526_Skoda",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260874127.9526_Skoda",
              "Size": 75099.0
          },
          "Sort": 87,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 428,
                  "ModelNameen": "Fabia",
                  "ModelNamear": "فابيا",
                  "ModelNameku": "فابیا",
                  "Sort": 0,
                  "FromYearId": 50,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 50,
                      "YearName": "2000",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 426,
                  "ModelNameen": "Kodiaq",
                  "ModelNamear": "كودياك",
                  "ModelNameku": "کۆدیاک",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 425,
                  "ModelNameen": "Octavia",
                  "ModelNamear": "اوكتافيا",
                  "ModelNameku": "ئۆکتاڤیا",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1097,
                  "ModelNameen": "Rapid",
                  "ModelNamear": "رابيد",
                  "ModelNameku": "راپيد",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 424,
                  "ModelNameen": "Roomster",
                  "ModelNamear": "رومستر",
                  "ModelNameku": "رومستەر",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 427,
                  "ModelNameen": "Superb",
                  "ModelNamear": "سوبرب",
                  "ModelNameku": "سوپێرب",
                  "Sort": 0,
                  "FromYearId": 52,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 429,
                  "ModelNameen": "Yeti",
                  "ModelNamear": "يتي",
                  "ModelNameku": "یەتی",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 4,
          "BrandNameen": "Mitsubishi",
          "BrandNamear": "ميتسوبيشي",
          "BrandNameku": "میتسوبیشی",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 43,
          "Attachment": {
              "ID": 43,
              "Url": "/img/BrandAttachments\\1598260850902.777_Mitsubishi",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260850902.777_Mitsubishi",
              "Size": 58470.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 87,
                  "ModelNameen": "Pajero",
                  "ModelNamear": "باجيرو",
                  "ModelNameku": "پاجیرۆ",
                  "Sort": 100,
                  "FromYearId": 51,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 51,
                      "YearName": "2001",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 63,
                  "ModelNameen": "ASX",
                  "ModelNamear": "ASX",
                  "ModelNameku": "ASX",
                  "Sort": 0,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 62,
                  "ModelNameen": "Attrage",
                  "ModelNamear": "اتراج",
                  "ModelNameku": "ئەتراج",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1088,
                  "ModelNameen": "Canter",
                  "ModelNamear": "كانتر",
                  "ModelNameku": "کانتەر",
                  "Sort": 0,
                  "FromYearId": 13,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 13,
                      "YearName": "1963",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1273,
                  "ModelNameen": "Chariot",
                  "ModelNamear": "شاريوت",
                  "ModelNameku": "چاریۆت",
                  "Sort": 0,
                  "FromYearId": 33,
                  "ToYearId": 53,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 33,
                      "YearName": "1983",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 60,
                  "ModelNameen": "Eclipse",
                  "ModelNamear": "إكليبس",
                  "ModelNameku": "ئیکلیپس",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 64,
                  "ModelNameen": "Eclipse Cross",
                  "ModelNamear": "إكليبس كروس",
                  "ModelNameku": "ئیکلیپس کرۆس",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1364,
                  "ModelNameen": "Endeavor",
                  "ModelNamear": "إنديفور",
                  "ModelNameku": "ئیندیڤۆر",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": 61,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 111,
                  "ModelNameen": "Galant",
                  "ModelNamear": "جالنت",
                  "ModelNameku": "گلانت",
                  "Sort": 0,
                  "FromYearId": 32,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 32,
                      "YearName": "1982",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 109,
                  "ModelNameen": "Grandis",
                  "ModelNamear": "جرانديس",
                  "ModelNameku": "گراندیس",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 110,
                  "ModelNameen": "L200",
                  "ModelNamear": "L200",
                  "ModelNameku": "L200",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 105,
                  "ModelNameen": "Lancer",
                  "ModelNamear": "لانسر",
                  "ModelNameku": "لانسەر",
                  "Sort": 0,
                  "FromYearId": 23,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 23,
                      "YearName": "1973",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 106,
                  "ModelNameen": "Lancer Evolution",
                  "ModelNamear": "لانسر إيفولوشن",
                  "ModelNameku": "لانسەر ئیڤۆلیوشن",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 104,
                  "ModelNameen": "Lancer EX",
                  "ModelNamear": "لانسرEX",
                  "ModelNameku": "لانسەر EX",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 107,
                  "ModelNameen": "Lancer Fortis",
                  "ModelNamear": "لانسر فورتس",
                  "ModelNameku": "لانسەر فۆرتیس",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 59,
                  "ModelNameen": "Magna",
                  "ModelNamear": "ماجنا",
                  "ModelNameku": "ماگنا",
                  "Sort": 0,
                  "FromYearId": 47,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 47,
                      "YearName": "1997",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 61,
                  "ModelNameen": "Mirage",
                  "ModelNamear": "ميراج",
                  "ModelNameku": "میراج",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1388,
                  "ModelNameen": "Mirage G4",
                  "ModelNamear": "ميراج G4",
                  "ModelNameku": "میراژ جی٤",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1010,
                  "ModelNameen": "Montero",
                  "ModelNamear": "مونتيرو",
                  "ModelNameku": "مۆنتێرۆ",
                  "Sort": 0,
                  "FromYearId": 50,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 50,
                      "YearName": "2000",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 112,
                  "ModelNameen": "Montero Sport",
                  "ModelNamear": "مونتيرو سبورت",
                  "ModelNameku": "مۆنتێرۆ سپۆرت",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 86,
                  "ModelNameen": "Nativa",
                  "ModelNamear": "ناتيفا",
                  "ModelNameku": "ناتیڤا",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 108,
                  "ModelNameen": "Outlander",
                  "ModelNamear": "اوتلاندر",
                  "ModelNameku": "ئاوتلاندەر",
                  "Sort": 0,
                  "FromYearId": 53,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1380,
                  "ModelNameen": "Outlander Sport",
                  "ModelNamear": "أوتلاندر سبورت",
                  "ModelNameku": "ئاوتلاندەر سپۆرت",
                  "Sort": 0,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 84,
                  "ModelNameen": "Pajero Sport",
                  "ModelNamear": "باجيرو سبورت",
                  "ModelNameku": "پاجیرۆ سپۆرت",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1217,
                  "ModelNameen": "RVR",
                  "ModelNamear": "RVR",
                  "ModelNameku": "RVR",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1023,
                  "ModelNameen": "Xpander",
                  "ModelNamear": "اكسباندر",
                  "ModelNameku": "ئێکسپاندەر",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1283,
                  "ModelNameen": "Xpander Cross",
                  "ModelNamear": "إكسباندر كروس",
                  "ModelNameku": "ئێکسپاندەر کرۆس",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 5,
          "BrandNameen": "Mini",
          "BrandNamear": "ميني",
          "BrandNameku": "مینی",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 44,
          "Attachment": {
              "ID": 44,
              "Url": "/img/BrandAttachments\\1598260852043.4907_Mini",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260852043.4907_Mini",
              "Size": 35142.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 100,
                  "ModelNameen": "Clubman",
                  "ModelNamear": "كلبمان",
                  "ModelNameku": "کلەب مان",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 101,
                  "ModelNameen": "Cooper",
                  "ModelNamear": "كوبر",
                  "ModelNameku": "کوپەر",
                  "Sort": 0,
                  "FromYearId": 52,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 102,
                  "ModelNameen": "Countryman",
                  "ModelNamear": "كونتريمان",
                  "ModelNameku": "کەنتری مان",
                  "Sort": 0,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 103,
                  "ModelNameen": "Paceman",
                  "ModelNamear": "بيسمان",
                  "ModelNameku": "پەیس مان",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 99,
                  "ModelNameen": "Roadster",
                  "ModelNamear": "رودستر",
                  "ModelNameku": "رۆدستەر",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 6,
          "BrandNameen": "MG",
          "BrandNamear": "ام جي",
          "BrandNameku": "ئێم جی",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 45,
          "Attachment": {
              "ID": 45,
              "Url": "/img/BrandAttachments\\1699454502387.4785_MG.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "MG_logo_PNG5.png",
              "Size": 21552.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 91,
                  "ModelNameen": "5",
                  "ModelNamear": "5",
                  "ModelNameku": "5",
                  "Sort": 100,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 94,
                  "ModelNameen": "HS",
                  "ModelNamear": "HS",
                  "ModelNameku": "HS",
                  "Sort": 95,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 98,
                  "ModelNameen": "ZS",
                  "ModelNamear": "ZS",
                  "ModelNameku": "ZS",
                  "Sort": 90,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 995,
                  "ModelNameen": "T60",
                  "ModelNamear": "T60",
                  "ModelNameku": "T60",
                  "Sort": 85,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1017,
                  "ModelNameen": "6",
                  "ModelNamear": "6",
                  "ModelNameku": "6",
                  "Sort": 80,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1026,
                  "ModelNameen": "GT",
                  "ModelNamear": "GT",
                  "ModelNameku": "GT",
                  "Sort": 75,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1146,
                  "ModelNameen": "One",
                  "ModelNamear": "ون",
                  "ModelNameku": "وەن",
                  "Sort": 70,
                  "FromYearId": 71,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 71,
                      "YearName": "2021",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 95,
                  "ModelNameen": "RX5",
                  "ModelNamear": "RX5",
                  "ModelNameku": "RX5",
                  "Sort": 65,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1214,
                  "ModelNameen": "Whale",
                  "ModelNamear": "ويل",
                  "ModelNameku": "وەیل",
                  "Sort": 65,
                  "FromYearId": 74,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 74,
                      "YearName": "2023",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 97,
                  "ModelNameen": "RX8",
                  "ModelNamear": "RX8",
                  "ModelNameku": "RX8",
                  "Sort": 60,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1121,
                  "ModelNameen": "3",
                  "ModelNamear": "3",
                  "ModelNameku": "3",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 92,
                  "ModelNameen": "350",
                  "ModelNamear": "350",
                  "ModelNameku": "350",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 974,
                  "ModelNameen": "360",
                  "ModelNamear": "360",
                  "ModelNameku": "360",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 93,
                  "ModelNameen": "550",
                  "ModelNamear": "550",
                  "ModelNameku": "550",
                  "Sort": 0,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1287,
                  "ModelNameen": "7",
                  "ModelNamear": "7",
                  "ModelNameku": "7",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 96,
                  "ModelNameen": "750",
                  "ModelNamear": "750",
                  "ModelNameku": "750",
                  "Sort": 0,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1174,
                  "ModelNameen": "Midget",
                  "ModelNamear": "ميدجيت",
                  "ModelNameku": "میدجێت",
                  "Sort": 0,
                  "FromYearId": 11,
                  "ToYearId": 29,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 11,
                      "YearName": "1961",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 29,
                      "YearName": "1979",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 7,
          "BrandNameen": "Mercury",
          "BrandNamear": "ميركوري",
          "BrandNameku": "مێرکوری",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 46,
          "Attachment": {
              "ID": 46,
              "Url": "/img/BrandAttachments\\1598260855512.6516_Mercury",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260855512.6516_Mercury",
              "Size": 94489.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 58,
                  "ModelNameen": "Grand Marquis",
                  "ModelNamear": "جراند ماركيز",
                  "ModelNameku": "گراند مارکویس",
                  "Sort": 0,
                  "FromYearId": 53,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 57,
                  "ModelNameen": "Mariner",
                  "ModelNamear": "مارنر",
                  "ModelNameku": "مارینەر",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 89,
                  "ModelNameen": "Milan",
                  "ModelNamear": "ميلان",
                  "ModelNameku": "میلان",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 88,
                  "ModelNameen": "Montego",
                  "ModelNamear": "مونتيغو",
                  "ModelNameku": "مۆنتێگۆ",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 999,
                  "ModelNameen": "Monterey",
                  "ModelNamear": "مونتيري",
                  "ModelNameku": "مۆنتێرێی",
                  "Sort": 0,
                  "FromYearId": 1,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 1,
                      "YearName": "1951",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 90,
                  "ModelNameen": "Mountaineer",
                  "ModelNamear": "ماونتنير",
                  "ModelNameku": "ماونتەینەر",
                  "Sort": 0,
                  "FromYearId": 52,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1321,
                  "ModelNameen": "Villager",
                  "ModelNamear": "فيلاجر",
                  "ModelNameku": "ڤیلاجەر",
                  "Sort": 0,
                  "FromYearId": 42,
                  "ToYearId": 52,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 42,
                      "YearName": "1992",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 8,
          "BrandNameen": "Mercedes-Benz",
          "BrandNamear": "مرسيدس بنز",
          "BrandNameku": "مێرسێدس بێنز",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 32,
          "Attachment": {
              "ID": 32,
              "Url": "/img/BrandAttachments\\1598260856554.4385_Mercedes-Benz",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": null,
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260856554.4385_Mercedes-Benz",
              "Size": 34929.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 39,
                  "ModelNameen": "E-Class",
                  "ModelNamear": "إي-كلاس",
                  "ModelNameku": "E-Class",
                  "Sort": 100,
                  "FromYearId": 1,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 1,
                      "YearName": "1951",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 9,
                  "ModelNameen": "C-Class",
                  "ModelNamear": "سي كلاس",
                  "ModelNameku": "C-Class",
                  "Sort": 98,
                  "FromYearId": 1,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 1,
                      "YearName": "1951",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 115,
                  "ModelNameen": "S-Class",
                  "ModelNamear": "إس كلاس",
                  "ModelNameku": "S-Class",
                  "Sort": 96,
                  "FromYearId": 1,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 1,
                      "YearName": "1951",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 43,
                  "ModelNameen": "G-Class",
                  "ModelNamear": "جي-كلاس",
                  "ModelNameku": "G-Class",
                  "Sort": 94,
                  "FromYearId": 30,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 30,
                      "YearName": "1980",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 199,
                  "ModelNameen": "CLS",
                  "ModelNamear": "CLS",
                  "ModelNameku": "CLS",
                  "Sort": 92,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 56,
                  "ModelNameen": "CLA",
                  "ModelNamear": "CLA",
                  "ModelNameku": "CLA",
                  "Sort": 90,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 46,
                  "ModelNameen": "GLE",
                  "ModelNamear": "GLE",
                  "ModelNameku": "GLE",
                  "Sort": 88,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 32,
                  "ModelNameen": "GLS",
                  "ModelNamear": "GLS",
                  "ModelNameku": "GLS",
                  "Sort": 86,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 15,
                  "ModelNameen": "GLC",
                  "ModelNamear": "GLC",
                  "ModelNameku": "GLC",
                  "Sort": 84,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 29,
                  "ModelNameen": "AMG GT",
                  "ModelNamear": "AMG GT",
                  "ModelNameku": "AMG GT",
                  "Sort": 82,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 24,
                  "ModelNameen": "A-Class",
                  "ModelNamear": "A-Class",
                  "ModelNameku": "A-Class",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 36,
                  "ModelNameen": "AMG GT 4-door Coupe",
                  "ModelNamear": "اي ام جي جي تي 4 دور كوبيه",
                  "ModelNameku": "AMG GT 4-door Coupe",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 191,
                  "ModelNameen": "B-Class",
                  "ModelNamear": "بي-كلاس",
                  "ModelNameku": "B-Class",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 196,
                  "ModelNameen": "CL-Class",
                  "ModelNamear": "سي أل كلاس",
                  "ModelNameku": "CL-Class",
                  "Sort": 0,
                  "FromYearId": 51,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 51,
                      "YearName": "2001",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 7,
                  "ModelNameen": "CLC-Class",
                  "ModelNamear": "سي ال سي كلاس",
                  "ModelNameku": "CLC-Class",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 4,
                  "ModelNameen": "CLK-Class",
                  "ModelNamear": "سي إل كيه",
                  "ModelNameku": "CLK-Class",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1289,
                  "ModelNameen": "EQV",
                  "ModelNamear": "EQV",
                  "ModelNameku": "EQV",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 33,
                  "ModelNameen": "GL-Class",
                  "ModelNamear": "جي أل-كلاس",
                  "ModelNameku": "GL-Class",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 5,
                  "ModelNameen": "GLA",
                  "ModelNamear": "GLA",
                  "ModelNameku": "GLA",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 10,
                  "ModelNameen": "GLB",
                  "ModelNamear": "GLB",
                  "ModelNameku": "GLB",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 16,
                  "ModelNameen": "GLC-Class Coupe",
                  "ModelNamear": "جي ال سي كلاس كوبيه",
                  "ModelNameku": "GLC-Class کوپ",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 17,
                  "ModelNameen": "GLK-Class",
                  "ModelNamear": "جي ال كي-كلاس",
                  "ModelNameku": "GLK-Class",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 48,
                  "ModelNameen": "ML-Class",
                  "ModelNamear": "ام ال- كلاس",
                  "ModelNameku": "ML-Class",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1244,
                  "ModelNameen": "Ponton",
                  "ModelNamear": "بونتون",
                  "ModelNameku": "پۆنتۆن",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": 20,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": {
                      "ID": 20,
                      "YearName": "1970",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 197,
                  "ModelNameen": "R-Class",
                  "ModelNamear": "آر كلاس",
                  "ModelNameku": "R-Class",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 114,
                  "ModelNameen": "S-Class Maybach",
                  "ModelNamear": "الفئة S مايباخ",
                  "ModelNameku": "S-Class مایباخ",
                  "Sort": 0,
                  "FromYearId": 52,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1279,
                  "ModelNameen": "SEC",
                  "ModelNamear": "SEC",
                  "ModelNameku": "SEC",
                  "Sort": 0,
                  "FromYearId": 36,
                  "ToYearId": 41,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 36,
                      "YearName": "1986",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 41,
                      "YearName": "1991",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 53,
                  "ModelNameen": "SL-Class",
                  "ModelNamear": "اس ال كلاس",
                  "ModelNameku": "SL-Class",
                  "Sort": 0,
                  "FromYearId": 4,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 4,
                      "YearName": "1954",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 20,
                  "ModelNameen": "SLC-Class",
                  "ModelNamear": "إس إل سي كلاس",
                  "ModelNameku": "SLC-Class",
                  "Sort": 0,
                  "FromYearId": 21,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 21,
                      "YearName": "1971",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 22,
                  "ModelNameen": "SLK-Class",
                  "ModelNamear": "اس ال كيه كلاس",
                  "ModelNameku": "SLK-Class",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 27,
                  "ModelNameen": "SLR McLaren",
                  "ModelNamear": "إس إل آر ماكلارين",
                  "ModelNameku": "SLR ماکلارن",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 28,
                  "ModelNameen": "SLS AMG",
                  "ModelNamear": "SLS AMG",
                  "ModelNameku": "SLS AMG",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 190,
                  "ModelNameen": "Sprinter",
                  "ModelNamear": "سبرنتر",
                  "ModelNameku": "سپرینتەر",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1069,
                  "ModelNameen": "SSK",
                  "ModelNamear": "SSK",
                  "ModelNameku": "SSK",
                  "Sort": 0,
                  "FromYearId": 75,
                  "ToYearId": 75,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 75,
                      "YearName": "1929",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 75,
                      "YearName": "1929",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 18,
                  "ModelNameen": "V-Class",
                  "ModelNamear": "في كلاس",
                  "ModelNameku": "V-Class",
                  "Sort": 0,
                  "FromYearId": 46,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 46,
                      "YearName": "1996",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1266,
                  "ModelNameen": "V-Class Maybach",
                  "ModelNamear": "في كلاس مايباخ",
                  "ModelNameku": "V-Class مایباخ",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 19,
                  "ModelNameen": "Viano",
                  "ModelNamear": "فيانو",
                  "ModelNameku": "ڤیانۆ",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 13,
                  "ModelNameen": "Vito",
                  "ModelNamear": "فيتو",
                  "ModelNameku": "ڤیتۆ",
                  "Sort": 0,
                  "FromYearId": 53,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 957,
                  "ModelNameen": "X-Class",
                  "ModelNamear": "اكس كلاس",
                  "ModelNameku": "X-Class",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 9,
          "BrandNameen": "McLaren",
          "BrandNamear": "مكلارين",
          "BrandNameku": "ماکلارن",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 47,
          "Attachment": {
              "ID": 47,
              "Url": "/img/BrandAttachments\\1598260857319.35_McLaren",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260857319.35_McLaren",
              "Size": 21579.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 182,
                  "ModelNameen": "540C Coupe",
                  "ModelNamear": "540C كوبيه",
                  "ModelNameku": "540C کوپ",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 183,
                  "ModelNameen": "570GT Coupe",
                  "ModelNamear": "570 جي تي كوبيه",
                  "ModelNameku": "570GT کوپ",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 184,
                  "ModelNameen": "570S Coupe",
                  "ModelNamear": "570S كوبيه",
                  "ModelNameku": "570S کوپ",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 180,
                  "ModelNameen": "570S Spider",
                  "ModelNamear": "570S سبايدر",
                  "ModelNameku": "570S سپایدەر",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 185,
                  "ModelNameen": "650S",
                  "ModelNamear": "650S",
                  "ModelNameku": "650S",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 179,
                  "ModelNameen": "675LT",
                  "ModelNamear": "675LT",
                  "ModelNameku": "675LT",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 187,
                  "ModelNameen": "720S",
                  "ModelNamear": "720S",
                  "ModelNameku": "720S",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1140,
                  "ModelNameen": "GT",
                  "ModelNamear": "GT",
                  "ModelNameku": "GT",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 189,
                  "ModelNameen": "P1",
                  "ModelNamear": "P1",
                  "ModelNameku": "P1",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 10,
          "BrandNameen": "Mazda",
          "BrandNamear": "مازدا",
          "BrandNameku": "مازدا",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 40,
          "Attachment": {
              "ID": 40,
              "Url": "/img/BrandAttachments\\1598260858056.92_Mazda",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260858056.92_Mazda",
              "Size": 90862.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1335,
                  "ModelNameen": "121",
                  "ModelNamear": "121",
                  "ModelNameku": "121",
                  "Sort": 0,
                  "FromYearId": 25,
                  "ToYearId": 52,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 25,
                      "YearName": "1975",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 175,
                  "ModelNameen": "2",
                  "ModelNamear": "2",
                  "ModelNameku": "2",
                  "Sort": 0,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 176,
                  "ModelNameen": "2 Sedan",
                  "ModelNamear": "2 Sedan",
                  "ModelNameku": "2 Sedan",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 222,
                  "ModelNameen": "3",
                  "ModelNamear": "3",
                  "ModelNameku": "3",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 223,
                  "ModelNameen": "3 Sedan",
                  "ModelNamear": "3 Sedan",
                  "ModelNameku": "3 Sedan",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1281,
                  "ModelNameen": "323",
                  "ModelNamear": "323",
                  "ModelNameku": "323",
                  "Sort": 0,
                  "FromYearId": 13,
                  "ToYearId": 53,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 13,
                      "YearName": "1963",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1074,
                  "ModelNameen": "5",
                  "ModelNamear": "5",
                  "ModelNameku": "5",
                  "Sort": 0,
                  "FromYearId": 49,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 49,
                      "YearName": "1999",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 227,
                  "ModelNameen": "6",
                  "ModelNamear": "6",
                  "ModelNameku": "6",
                  "Sort": 0,
                  "FromYearId": 30,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 30,
                      "YearName": "1980",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 228,
                  "ModelNameen": "6 Ultra",
                  "ModelNamear": "6 Ultra",
                  "ModelNameku": "6 Ultra",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 229,
                  "ModelNameen": "6 Wagon",
                  "ModelNamear": "6 واغن",
                  "ModelNameku": "6 واگن",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1155,
                  "ModelNameen": "626",
                  "ModelNamear": "626",
                  "ModelNameku": "626",
                  "Sort": 0,
                  "FromYearId": 37,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 37,
                      "YearName": "1987",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 226,
                  "ModelNameen": "B-Series",
                  "ModelNamear": "بي سيريس",
                  "ModelNameku": "B-Series",
                  "Sort": 0,
                  "FromYearId": 49,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 49,
                      "YearName": "1999",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 177,
                  "ModelNameen": "CX-3",
                  "ModelNamear": "سي إكس-3",
                  "ModelNameku": "CX-3",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1024,
                  "ModelNameen": "CX-30",
                  "ModelNamear": "سي اكس-30",
                  "ModelNameku": "CX-30",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 224,
                  "ModelNameen": "CX-5",
                  "ModelNamear": "سي اكس-5",
                  "ModelNameku": "CX-5",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1190,
                  "ModelNameen": "CX-50",
                  "ModelNamear": "سي اكس-50",
                  "ModelNameku": "CX-50",
                  "Sort": 0,
                  "FromYearId": 72,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 72,
                      "YearName": "2022",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 225,
                  "ModelNameen": "CX-7",
                  "ModelNamear": "CX-7",
                  "ModelNameku": "CX-7",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 203,
                  "ModelNameen": "CX-9",
                  "ModelNamear": "سي اكس-9",
                  "ModelNameku": "CX-9",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1301,
                  "ModelNameen": "MPV",
                  "ModelNamear": "MPV",
                  "ModelNameku": "MPV",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1392,
                  "ModelNameen": "MX-30",
                  "ModelNamear": "MX-30",
                  "ModelNameku": "MX-30",
                  "Sort": 0,
                  "FromYearId": 71,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 71,
                      "YearName": "2021",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 202,
                  "ModelNameen": "MX-5",
                  "ModelNamear": "إم إكس-5",
                  "ModelNameku": "MX-5",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 200,
                  "ModelNameen": "MX-5 RF",
                  "ModelNamear": "إم إكس-5 آر إف",
                  "ModelNameku": "MX-5 RF",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 13,
          "BrandNameen": "Maserati",
          "BrandNamear": "مازيراتي",
          "BrandNameku": "ماسێراتی",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 49,
          "Attachment": {
              "ID": 49,
              "Url": "/img/BrandAttachments\\1598260860015.0908_Maserati",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260860015.0908_Maserati",
              "Size": 24482.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 211,
                  "ModelNameen": "Ghibli",
                  "ModelNamear": "جيبلي",
                  "ModelNameku": "غیبلی",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 213,
                  "ModelNameen": "GranCabrio",
                  "ModelNamear": "جران كابريو",
                  "ModelNameku": "گران کابریۆ",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 214,
                  "ModelNameen": "GranTurismo",
                  "ModelNamear": "جران توريزمو",
                  "ModelNameku": "گران تۆریسمۆ",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1191,
                  "ModelNameen": "Grecale",
                  "ModelNamear": "جريكال",
                  "ModelNameku": "گرێکایل",
                  "Sort": 0,
                  "FromYearId": 74,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 74,
                      "YearName": "2023",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 212,
                  "ModelNameen": "Levante",
                  "ModelNamear": "ليفانتي",
                  "ModelNameku": "لێڤانتی",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 210,
                  "ModelNameen": "Quattroporte",
                  "ModelNamear": "كواتروبورتي",
                  "ModelNameku": "کواترۆپۆرت",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 215,
                  "ModelNameen": "Spyder",
                  "ModelNamear": "سبايدر",
                  "ModelNameku": "سپایدەر",
                  "Sort": 0,
                  "FromYearId": 52,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 17,
          "BrandNameen": "Lincoln",
          "BrandNamear": "لينكولن",
          "BrandNameku": "لینکۆڵن",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 88,
          "Attachment": {
              "ID": 88,
              "Url": "/img/BrandAttachments\\1598267657838.0986_Lincoln",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "Lincoln-logo-2019-1920x1080-2.png.png",
              "Size": 6941.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 138,
                  "ModelNameen": "Aviator",
                  "ModelNamear": "أفياتور",
                  "ModelNameku": "اڤیاتۆر",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 978,
                  "ModelNameen": "Continental",
                  "ModelNamear": "كونتننتال",
                  "ModelNameku": "کۆنتینێنتەڵ",
                  "Sort": 0,
                  "FromYearId": 1,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 1,
                      "YearName": "1951",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1096,
                  "ModelNameen": "Corsair",
                  "ModelNamear": "كورسير",
                  "ModelNameku": "کۆرسەر",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": 74,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 74,
                      "YearName": "2023",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 141,
                  "ModelNameen": "MKC",
                  "ModelNamear": "MKC",
                  "ModelNameku": "MKC",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 133,
                  "ModelNameen": "MKS",
                  "ModelNamear": "MKS",
                  "ModelNameku": "MKS",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 132,
                  "ModelNameen": "MKT",
                  "ModelNamear": "MKT",
                  "ModelNameku": "MKT",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 140,
                  "ModelNameen": "MKX",
                  "ModelNamear": "MKX",
                  "ModelNameku": "MKX",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 137,
                  "ModelNameen": "MKZ",
                  "ModelNamear": "MKZ",
                  "ModelNameku": "MKZ",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 139,
                  "ModelNameen": "Nautilus",
                  "ModelNamear": "ناوتلس",
                  "ModelNameku": "ناوتیلەس",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 135,
                  "ModelNameen": "Navigator",
                  "ModelNamear": "نافيجيتور",
                  "ModelNameku": "ناڤیگەیتەر",
                  "Sort": 0,
                  "FromYearId": 53,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 136,
                  "ModelNameen": "Navigator L",
                  "ModelNamear": "نافيجاتور إل",
                  "ModelNameku": "ناڤیگەیتەر L",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 134,
                  "ModelNameen": "Town Car",
                  "ModelNamear": "تاون كار",
                  "ModelNameku": "تاون کار",
                  "Sort": 0,
                  "FromYearId": 22,
                  "ToYearId": 61,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 22,
                      "YearName": "1972",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 20,
          "BrandNameen": "Lamborghini",
          "BrandNamear": "لامبورغيني",
          "BrandNameku": "لامبۆرگینی",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 87,
          "Attachment": {
              "ID": 87,
              "Url": "/img/BrandAttachments\\1728387024429.298_Lamborghini.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "logo-lamborghini (1).png",
              "Size": 78385.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 154,
                  "ModelNameen": "Aventador",
                  "ModelNamear": "أفينتادور",
                  "ModelNameku": "ئەڤێنتادۆر",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 153,
                  "ModelNameen": "Aventador Roadster",
                  "ModelNamear": "أفينتادور رودستر",
                  "ModelNameku": "ئەڤێنتادۆر رۆدستەر",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 157,
                  "ModelNameen": "Gallardo",
                  "ModelNamear": "غالاردو",
                  "ModelNameku": "گەلاردۆ",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 155,
                  "ModelNameen": "Huracan",
                  "ModelNamear": "هوراكان",
                  "ModelNameku": "هوراکان",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1254,
                  "ModelNameen": "Huracan EVO Spyder",
                  "ModelNamear": "هوراكان إيفو سبايدر",
                  "ModelNameku": "هوراکان ئیڤۆ سپایدەر",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 156,
                  "ModelNameen": "Huracan Spyder",
                  "ModelNamear": "هوراكان سبايدر",
                  "ModelNameku": "هوراکان سپایدەر",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 158,
                  "ModelNameen": "Murcielago",
                  "ModelNamear": "مورسيلاغو",
                  "ModelNameku": "مۆرشێلاگۆ",
                  "Sort": 0,
                  "FromYearId": 52,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 152,
                  "ModelNameen": "Urus",
                  "ModelNamear": "اوروس",
                  "ModelNameku": "ئوروس",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 23,
          "BrandNameen": "Opel",
          "BrandNamear": "اوبل",
          "BrandNameku": "ئۆپڵ",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 50,
          "Attachment": {
              "ID": 50,
              "Url": "/img/BrandAttachments\\1728389160019.5024_Opel.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "1691933751611.1597_Opel-removebg-preview.png",
              "Size": 39520.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 396,
                  "ModelNameen": "Adam",
                  "ModelNamear": "ادم",
                  "ModelNameku": "ئادەم",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 390,
                  "ModelNameen": "Antara",
                  "ModelNamear": "انترا",
                  "ModelNameku": "ئەنتەرا",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 387,
                  "ModelNameen": "Astra",
                  "ModelNamear": "استرا",
                  "ModelNameku": "ئەسترا",
                  "Sort": 0,
                  "FromYearId": 35,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 35,
                      "YearName": "1985",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 388,
                  "ModelNameen": "Astra GTC",
                  "ModelNamear": "استرا GTC",
                  "ModelNameku": "ئەسترا GTC",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 389,
                  "ModelNameen": "Astra OPC",
                  "ModelNamear": "استرا OPC",
                  "ModelNameku": "ئەسترا OPC",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1351,
                  "ModelNameen": "Calibra",
                  "ModelNamear": "كاليبرا",
                  "ModelNameku": "کالیبرا",
                  "Sort": 0,
                  "FromYearId": 39,
                  "ToYearId": 47,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 39,
                      "YearName": "1989",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 47,
                      "YearName": "1997",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1025,
                  "ModelNameen": "Combo",
                  "ModelNamear": "كومبو",
                  "ModelNameku": "کۆمبۆ",
                  "Sort": 0,
                  "FromYearId": 36,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 36,
                      "YearName": "1986",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 398,
                  "ModelNameen": "Corsa",
                  "ModelNamear": "كورسا",
                  "ModelNameku": "کۆرسا",
                  "Sort": 0,
                  "FromYearId": 51,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 51,
                      "YearName": "2001",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 397,
                  "ModelNameen": "Corsa OPC",
                  "ModelNamear": "كورسا أو بي سي",
                  "ModelNameku": "کۆرسا OPC",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1343,
                  "ModelNameen": "Crossland",
                  "ModelNamear": "كروس لاند",
                  "ModelNameku": "کرۆسلاند",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1013,
                  "ModelNameen": "Grandland X",
                  "ModelNamear": "جراند لاند إكس",
                  "ModelNameku": "گراندلاند ئێکس",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 394,
                  "ModelNameen": "Insignia",
                  "ModelNamear": "إنسيجنيا",
                  "ModelNameku": "ئینسیگنیا",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 392,
                  "ModelNameen": "Meriva",
                  "ModelNamear": "ميريفا",
                  "ModelNameku": "مێریڤا",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 399,
                  "ModelNameen": "Mokka",
                  "ModelNamear": "موكا",
                  "ModelNameku": "مۆکا",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1059,
                  "ModelNameen": "Omega",
                  "ModelNamear": "أوميغا",
                  "ModelNameku": "ئۆمێگا",
                  "Sort": 0,
                  "FromYearId": 36,
                  "ToYearId": 54,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 36,
                      "YearName": "1986",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 393,
                  "ModelNameen": "Vectra",
                  "ModelNamear": "فيكترا",
                  "ModelNameku": "ڤێکترا",
                  "Sort": 0,
                  "FromYearId": 40,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 40,
                      "YearName": "1990",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 391,
                  "ModelNameen": "Zafira",
                  "ModelNamear": "زافرا",
                  "ModelNameku": "زافیرا",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 25,
          "BrandNameen": "Volvo",
          "BrandNamear": "فولفو",
          "BrandNameku": "ڤۆڵڤۆ",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 51,
          "Attachment": {
              "ID": 51,
              "Url": "/img/BrandAttachments\\1598260866257.0234_Volvo",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260866257.0234_Volvo",
              "Size": 48021.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1344,
                  "ModelNameen": "740",
                  "ModelNamear": "740",
                  "ModelNameku": "740",
                  "Sort": 0,
                  "FromYearId": 34,
                  "ToYearId": 42,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 34,
                      "YearName": "1984",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 42,
                      "YearName": "1992",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1165,
                  "ModelNameen": "940",
                  "ModelNamear": "940",
                  "ModelNameku": "940",
                  "Sort": 0,
                  "FromYearId": 40,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 40,
                      "YearName": "1990",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 292,
                  "ModelNameen": "C30",
                  "ModelNamear": "C30",
                  "ModelNameku": "C30",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 304,
                  "ModelNameen": "C70",
                  "ModelNamear": "C70",
                  "ModelNameku": "C70",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1258,
                  "ModelNameen": "Polestar 2",
                  "ModelNamear": "بولستار 2",
                  "ModelNameku": "پۆلستار 2",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 298,
                  "ModelNameen": "S40",
                  "ModelNamear": "S40",
                  "ModelNameku": "S40",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 297,
                  "ModelNameen": "S60",
                  "ModelNamear": "S60",
                  "ModelNameku": "S60",
                  "Sort": 0,
                  "FromYearId": 51,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 51,
                      "YearName": "2001",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 299,
                  "ModelNameen": "S60 Polestar",
                  "ModelNamear": "S60 بولستار",
                  "ModelNameku": "S60 پۆلستار",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 294,
                  "ModelNameen": "S80",
                  "ModelNamear": "S80",
                  "ModelNameku": "S80",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 293,
                  "ModelNameen": "S90",
                  "ModelNamear": "S90",
                  "ModelNameku": "S90",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 291,
                  "ModelNameen": "V40",
                  "ModelNamear": "V40",
                  "ModelNameku": "V40",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 303,
                  "ModelNameen": "V50",
                  "ModelNamear": "V50",
                  "ModelNameku": "V50",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 301,
                  "ModelNameen": "V60",
                  "ModelNamear": "V60",
                  "ModelNameku": "V60",
                  "Sort": 0,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 302,
                  "ModelNameen": "V60 Polestar",
                  "ModelNamear": "V60 بولستار",
                  "ModelNameku": "V60 پۆلستار",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 306,
                  "ModelNameen": "V70",
                  "ModelNamear": "V70",
                  "ModelNameku": "V70",
                  "Sort": 0,
                  "FromYearId": 51,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 51,
                      "YearName": "2001",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 296,
                  "ModelNameen": "V90",
                  "ModelNamear": "V90",
                  "ModelNameku": "V90",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 307,
                  "ModelNameen": "XC40",
                  "ModelNamear": "XC40",
                  "ModelNameku": "XC40",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 300,
                  "ModelNameen": "XC60",
                  "ModelNamear": "XC60",
                  "ModelNameku": "XC60",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 305,
                  "ModelNameen": "XC70",
                  "ModelNamear": "XC70",
                  "ModelNameku": "XC70",
                  "Sort": 0,
                  "FromYearId": 51,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 51,
                      "YearName": "2001",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 295,
                  "ModelNameen": "XC90",
                  "ModelNamear": "XC90",
                  "ModelNameku": "XC90",
                  "Sort": 0,
                  "FromYearId": 53,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 26,
          "BrandNameen": "Volkswagen",
          "BrandNamear": "فولكسفاغن",
          "BrandNameku": "ڤۆڵکسواگن",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 52,
          "Attachment": {
              "ID": 52,
              "Url": "/img/BrandAttachments\\1728388293639.9167_Volkswagen.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "1711889647992.4038_Volkswagen-removebg-preview (1).png",
              "Size": 81438.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 333,
                  "ModelNameen": "Jetta",
                  "ModelNamear": "جيتا",
                  "ModelNameku": "جێتا",
                  "Sort": 100,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 342,
                  "ModelNameen": "Atlas",
                  "ModelNamear": "أطلس",
                  "ModelNameku": "ئەتلەس",
                  "Sort": 98,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 329,
                  "ModelNameen": "Golf",
                  "ModelNamear": "جولف",
                  "ModelNameku": "گۆلف",
                  "Sort": 96,
                  "FromYearId": 24,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 24,
                      "YearName": "1974",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 341,
                  "ModelNameen": "Passat",
                  "ModelNamear": "باسات",
                  "ModelNameku": "پاسات",
                  "Sort": 94,
                  "FromYearId": 30,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 30,
                      "YearName": "1980",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 337,
                  "ModelNameen": "Tiguan",
                  "ModelNamear": "تيغوان",
                  "ModelNameku": "تیگوان",
                  "Sort": 90,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1173,
                  "ModelNameen": "Atlas Cross Sport",
                  "ModelNamear": "أطلس كروس سبورت",
                  "ModelNameku": "ئەتڵەس کرۆس سپۆرت",
                  "Sort": 88,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 318,
                  "ModelNameen": "Arteon",
                  "ModelNamear": "أرتيون",
                  "ModelNameku": "ئارتیۆن",
                  "Sort": 86,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1077,
                  "ModelNameen": "Taos",
                  "ModelNamear": "تاوس",
                  "ModelNameku": "تاوس",
                  "Sort": 84,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 331,
                  "ModelNameen": "Golf R",
                  "ModelNamear": "جولف R",
                  "ModelNameku": "گۆلف R",
                  "Sort": 82,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 328,
                  "ModelNameen": "Beetle",
                  "ModelNamear": "بيتل",
                  "ModelNameku": "بیتڵ",
                  "Sort": 50,
                  "FromYearId": 1,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 1,
                      "YearName": "1951",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 325,
                  "ModelNameen": "Amarok",
                  "ModelNamear": "أماروك",
                  "ModelNameku": "ئەمارۆک",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1094,
                  "ModelNameen": "Bora",
                  "ModelNamear": "بورا",
                  "ModelNameku": "بۆڕا",
                  "Sort": 0,
                  "FromYearId": 49,
                  "ToYearId": 74,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 49,
                      "YearName": "1999",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 74,
                      "YearName": "2023",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1086,
                  "ModelNameen": "Caddy",
                  "ModelNamear": "كادي",
                  "ModelNameku": "کادی",
                  "Sort": 0,
                  "FromYearId": 30,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 30,
                      "YearName": "1980",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1359,
                  "ModelNameen": "e-Tharu",
                  "ModelNamear": "إي ثارو",
                  "ModelNameku": "ئی-ثارو",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 326,
                  "ModelNameen": "Eos",
                  "ModelNamear": "ايوس",
                  "ModelNameku": "ئیۆس",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1147,
                  "ModelNameen": "ID.3",
                  "ModelNamear": "آي دي.3",
                  "ModelNameku": "ID.3",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1116,
                  "ModelNameen": "ID.4",
                  "ModelNamear": "آي دي.4",
                  "ModelNameku": "ID.4",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1145,
                  "ModelNameen": "ID.6",
                  "ModelNamear": "آي دي.6",
                  "ModelNameku": "ID.6",
                  "Sort": 0,
                  "FromYearId": 71,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 71,
                      "YearName": "2021",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1285,
                  "ModelNameen": "Jetta VS7",
                  "ModelNamear": "جيتا VS7",
                  "ModelNameku": "جێتا VS7",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 343,
                  "ModelNameen": "Multivan",
                  "ModelNamear": "ملتيفان",
                  "ModelNameku": "مەلتیڤان",
                  "Sort": 0,
                  "FromYearId": 53,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1049,
                  "ModelNameen": "Multivan Alltrack",
                  "ModelNamear": "مولتيفان اولتراك",
                  "ModelNameku": "موڵتیڤان ئۆڵتراک",
                  "Sort": 0,
                  "FromYearId": 1,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 1,
                      "YearName": "1951",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 344,
                  "ModelNameen": "Passat CC",
                  "ModelNamear": "باسات CC",
                  "ModelNameku": "پاسات CC",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 339,
                  "ModelNameen": "Phaeton",
                  "ModelNamear": "فايتون",
                  "ModelNameku": "فایتۆن",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 336,
                  "ModelNameen": "Polo",
                  "ModelNamear": "بولو",
                  "ModelNameku": "پۆڵۆ",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1373,
                  "ModelNameen": "Routan",
                  "ModelNamear": "روتان",
                  "ModelNameku": "ڕۆوتان",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": 64,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 334,
                  "ModelNameen": "Scirocco",
                  "ModelNamear": "شيروكو",
                  "ModelNameku": "شیرۆکۆ",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 338,
                  "ModelNameen": "Sharan",
                  "ModelNamear": "شاران",
                  "ModelNameku": "شاران",
                  "Sort": 0,
                  "FromYearId": 50,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 50,
                      "YearName": "2000",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1108,
                  "ModelNameen": "T-Roc",
                  "ModelNamear": "تي روك",
                  "ModelNameku": "تی ڕۆک",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1126,
                  "ModelNameen": "Taigo",
                  "ModelNamear": "تايجو",
                  "ModelNameku": "تایگۆ",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 317,
                  "ModelNameen": "Touareg",
                  "ModelNamear": "طوارق",
                  "ModelNameku": "توارگ",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 340,
                  "ModelNameen": "Touran",
                  "ModelNamear": "توران",
                  "ModelNameku": "توران",
                  "Sort": 0,
                  "FromYearId": 53,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1340,
                  "ModelNameen": "Transporter",
                  "ModelNamear": "ترانسبورتر",
                  "ModelNameku": "ترانسپۆرتەر",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 28,
          "BrandNameen": "Tesla",
          "BrandNamear": "تسلا",
          "BrandNameku": "تێسلا",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 53,
          "Attachment": {
              "ID": 53,
              "Url": "/img/BrandAttachments\\1598260869995.639_Tesla",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260869995.639_Tesla",
              "Size": 6145.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 237,
                  "ModelNameen": "Cybertruck",
                  "ModelNamear": "سايبر تراك",
                  "ModelNameku": "سایبەر ترەک",
                  "Sort": 0,
                  "FromYearId": 71,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 71,
                      "YearName": "2021",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1095,
                  "ModelNameen": "Model 3",
                  "ModelNamear": "موديل 3",
                  "ModelNameku": "مۆدێل 3",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 239,
                  "ModelNameen": "Model S",
                  "ModelNamear": "Model S",
                  "ModelNameku": "Model S",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 238,
                  "ModelNameen": "Model X",
                  "ModelNamear": "Model X",
                  "ModelNameku": "Model X",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1093,
                  "ModelNameen": "Model Y",
                  "ModelNamear": "موديل Y",
                  "ModelNameku": "مۆدێلی Y",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 29,
          "BrandNameen": "Tata",
          "BrandNamear": "تاتا",
          "BrandNameku": "تاتا",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 54,
          "Attachment": {
              "ID": 54,
              "Url": "/img/BrandAttachments\\1598260870642.288_Tata",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260870642.288_Tata",
              "Size": 21634.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 235,
                  "ModelNameen": "Indica",
                  "ModelNamear": "إنديكا",
                  "ModelNameku": "ئیندیکا",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 236,
                  "ModelNameen": "Indigo",
                  "ModelNamear": "إنديغو",
                  "ModelNameku": "ئیندیگۆ",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 234,
                  "ModelNameen": "Xenon",
                  "ModelNamear": "زينون",
                  "ModelNameku": "زینۆن",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 31,
          "BrandNameen": "Subaru",
          "BrandNamear": "سوبارو",
          "BrandNameku": "سوبارو",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 56,
          "Attachment": {
              "ID": 56,
              "Url": "/img/BrandAttachments\\1598260872114.4111_Subaru",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260872114.4111_Subaru",
              "Size": 101378.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 270,
                  "ModelNameen": "Ascent",
                  "ModelNamear": "اسنت",
                  "ModelNameku": "اسێنت",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 272,
                  "ModelNameen": "BRZ",
                  "ModelNamear": "BRZ",
                  "ModelNameku": "BRZ",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1299,
                  "ModelNameen": "Crosstrek",
                  "ModelNamear": "كروس تريك",
                  "ModelNameku": "کرۆسترەک",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": 72,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 72,
                      "YearName": "2022",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 267,
                  "ModelNameen": "Forester",
                  "ModelNamear": "فورستر",
                  "ModelNameku": "فۆرێستەر",
                  "Sort": 0,
                  "FromYearId": 53,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 264,
                  "ModelNameen": "Impreza",
                  "ModelNamear": "امبريزا",
                  "ModelNameku": "ئیمپرێزا",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 265,
                  "ModelNameen": "Impreza WRX",
                  "ModelNamear": "امبريزا دبليو آر إكس",
                  "ModelNameku": "ئیمپرێزا WRX",
                  "Sort": 0,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 266,
                  "ModelNameen": "Impreza WRX STI",
                  "ModelNamear": "امبريزا دبليو آر إكس إس تي آي",
                  "ModelNameku": "ئیمپرێزا WRX STI",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 269,
                  "ModelNameen": "Legacy",
                  "ModelNamear": "ليجاسي",
                  "ModelNameku": "لێگەسی",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 268,
                  "ModelNameen": "Outback",
                  "ModelNamear": "أوت باك",
                  "ModelNameku": "ئاوتباک",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 271,
                  "ModelNameen": "Tribeca",
                  "ModelNamear": "تريبيكا",
                  "ModelNameku": "ترابێکا",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1328,
                  "ModelNameen": "WRX",
                  "ModelNamear": "WRX",
                  "ModelNameku": "WRX",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 263,
                  "ModelNameen": "XV",
                  "ModelNamear": "XV",
                  "ModelNameku": "XV",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 32,
          "BrandNameen": "Ssangyong",
          "BrandNamear": "سانغ يونغ",
          "BrandNameku": "سانگ یۆنگ",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 57,
          "Attachment": {
              "ID": 57,
              "Url": "/img/BrandAttachments\\1598260873408.5103_Ssangyong",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260873408.5103_Ssangyong",
              "Size": 26765.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 430,
                  "ModelNameen": "Actyon",
                  "ModelNamear": "أكتيون",
                  "ModelNameku": "ئاکتیۆن",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 261,
                  "ModelNameen": "Korando",
                  "ModelNamear": "كوراندو",
                  "ModelNameku": "کۆراندۆ",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 347,
                  "ModelNameen": "Kyron",
                  "ModelNamear": "كيرون",
                  "ModelNameku": "کیرۆن",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 316,
                  "ModelNameen": "Musso Grand",
                  "ModelNamear": "موسو جراند",
                  "ModelNameku": "موسۆ گراند",
                  "Sort": 0,
                  "FromYearId": 46,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 46,
                      "YearName": "1996",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 345,
                  "ModelNameen": "Rexton",
                  "ModelNamear": "ريكستون",
                  "ModelNameku": "رێکستۆن",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 346,
                  "ModelNameen": "Rodius",
                  "ModelNamear": "روديوس",
                  "ModelNameku": "رۆدیەس",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 262,
                  "ModelNameen": "Tivoli",
                  "ModelNamear": "تيفولي",
                  "ModelNameku": "تیڤۆلی",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1224,
                  "ModelNameen": "Torres",
                  "ModelNamear": "توريس",
                  "ModelNameku": "تۆرس",
                  "Sort": 0,
                  "FromYearId": 71,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 71,
                      "YearName": "2021",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1189,
                  "ModelNameen": "XLV",
                  "ModelNamear": "XLV",
                  "ModelNameku": "XLV",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 35,
          "BrandNameen": "Seat",
          "BrandNamear": "سيات",
          "BrandNameku": "سیات",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 105,
          "Attachment": {
              "ID": 105,
              "Url": "/img/BrandAttachments\\1680963377669.838_Seat.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "Seat_logo_PNG1.png",
              "Size": 13133.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 418,
                  "ModelNameen": "Altea",
                  "ModelNamear": "ألتيا",
                  "ModelNameku": "ئەلتیا",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 423,
                  "ModelNameen": "Cordoba",
                  "ModelNamear": "كوردوبا",
                  "ModelNameku": "کۆردۆبا",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1141,
                  "ModelNameen": "Cupra Formentor",
                  "ModelNamear": "كوبرا فورمينتور",
                  "ModelNameku": "کوپەرا فۆرمێنتۆر",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 421,
                  "ModelNameen": "Exeo",
                  "ModelNamear": "إكسيو",
                  "ModelNameku": "ئەکسیۆ",
                  "Sort": 0,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 419,
                  "ModelNameen": "Leon",
                  "ModelNamear": "ليون",
                  "ModelNameku": "لیۆن",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 420,
                  "ModelNameen": "Toledo",
                  "ModelNamear": "توليدو",
                  "ModelNameku": "تۆلیدۆ",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 37,
          "BrandNameen": "Rolls Royce",
          "BrandNamear": "رولز رويس",
          "BrandNameku": "ڕۆڵس ڕۆیس",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 59,
          "Attachment": {
              "ID": 59,
              "Url": "/img/BrandAttachments\\1598260875158.6497_Rolls Royce",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260875158.6497_Rolls Royce",
              "Size": 80862.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 414,
                  "ModelNameen": "Cullinan",
                  "ModelNamear": "كولينان",
                  "ModelNameku": "کۆلینەن",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 408,
                  "ModelNameen": "Dawn",
                  "ModelNamear": "داون",
                  "ModelNameku": "داون",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 410,
                  "ModelNameen": "Drophead Coupe",
                  "ModelNamear": "دروبهيد كوبيه",
                  "ModelNameku": "درۆفید کوپ",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 411,
                  "ModelNameen": "Ghost",
                  "ModelNamear": "گوست",
                  "ModelNameku": "گۆست",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 412,
                  "ModelNameen": "Phantom",
                  "ModelNamear": "فانتوم",
                  "ModelNameku": "فانتۆم",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 413,
                  "ModelNameen": "Silver Seraph",
                  "ModelNamear": "سلفر سراف",
                  "ModelNameku": "سیلڤەر سێراف",
                  "Sort": 0,
                  "FromYearId": 49,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 49,
                      "YearName": "1999",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1041,
                  "ModelNameen": "Silver Shadow",
                  "ModelNamear": "سلفر شادو",
                  "ModelNameku": "سیلڤەر شادۆو",
                  "Sort": 0,
                  "FromYearId": 15,
                  "ToYearId": 37,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 15,
                      "YearName": "1965",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 37,
                      "YearName": "1987",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1032,
                  "ModelNameen": "Silver Wraith II",
                  "ModelNamear": "سيلفر رايث II",
                  "ModelNameku": "II سيلفر رەیت",
                  "Sort": 0,
                  "FromYearId": 1,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 1,
                      "YearName": "1951",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 409,
                  "ModelNameen": "Wraith",
                  "ModelNamear": "ريث",
                  "ModelNameku": "رەیت",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 38,
          "BrandNameen": "Renault",
          "BrandNamear": "رينو",
          "BrandNameku": "رینۆ",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 60,
          "Attachment": {
              "ID": 60,
              "Url": "/img/BrandAttachments\\1728388179351.8872_Renault.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "1663664696105.1284_Renault-removebg-preview.png",
              "Size": 74413.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 446,
                  "ModelNameen": "Megane",
                  "ModelNamear": "ميجان",
                  "ModelNameku": "مێگان",
                  "Sort": 4,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 460,
                  "ModelNameen": "Symbol",
                  "ModelNamear": "سيمبل",
                  "ModelNameku": "سیمبۆل",
                  "Sort": 3,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 450,
                  "ModelNameen": "Koleos",
                  "ModelNamear": "كوليوس",
                  "ModelNameku": "کۆلیۆس",
                  "Sort": 2,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 455,
                  "ModelNameen": "Talisman",
                  "ModelNamear": "تلسمان",
                  "ModelNameku": "تالیسمان",
                  "Sort": 1,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 406,
                  "ModelNameen": "Captur",
                  "ModelNamear": "كابتشر",
                  "ModelNameku": "کاپچەر",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 431,
                  "ModelNameen": "Clio",
                  "ModelNamear": "Clio",
                  "ModelNameku": "Clio",
                  "Sort": 0,
                  "FromYearId": 52,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 459,
                  "ModelNameen": "Clio RS",
                  "ModelNamear": "Clio RS",
                  "ModelNameku": "Clio RS",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 442,
                  "ModelNameen": "Dokker Van",
                  "ModelNamear": "دوكر فان",
                  "ModelNameku": "دۆکەر ڤان",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 407,
                  "ModelNameen": "Duster",
                  "ModelNamear": "داستر",
                  "ModelNameku": "داستەر",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 451,
                  "ModelNameen": "Espace",
                  "ModelNamear": "اسكايب",
                  "ModelNameku": "ئیسکەیپ",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 449,
                  "ModelNameen": "Fluence",
                  "ModelNamear": "فلوينس",
                  "ModelNameku": "فلوێنس",
                  "Sort": 0,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1136,
                  "ModelNameen": "Kangoo",
                  "ModelNamear": "كانجو",
                  "ModelNameku": "کانگۆ",
                  "Sort": 0,
                  "FromYearId": 47,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 47,
                      "YearName": "1997",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 453,
                  "ModelNameen": "Laguna",
                  "ModelNamear": "لاجونا",
                  "ModelNameku": "لاگونا",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 452,
                  "ModelNameen": "Laguna Coupe",
                  "ModelNamear": "لاجونا كوبيه",
                  "ModelNameku": "لاگونا کوپ",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1062,
                  "ModelNameen": "Lodgy",
                  "ModelNamear": "لودجي",
                  "ModelNameku": "لۆدجي",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 433,
                  "ModelNameen": "Logan",
                  "ModelNamear": "لوجان",
                  "ModelNameku": "لۆگان",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 443,
                  "ModelNameen": "Logan Van",
                  "ModelNamear": "لوجان فان",
                  "ModelNameku": "لۆگان ڤان",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 445,
                  "ModelNameen": "Megane CC",
                  "ModelNamear": "ميجان سي سي",
                  "ModelNameku": "مێگان CC",
                  "Sort": 0,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 447,
                  "ModelNameen": "Megane GT",
                  "ModelNamear": "ميجان جي تي",
                  "ModelNameku": "مێگان GT",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 448,
                  "ModelNameen": "Megane RS",
                  "ModelNamear": "ميجان آر إس",
                  "ModelNameku": "مێگان RS",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 456,
                  "ModelNameen": "Safrane",
                  "ModelNamear": "سافران",
                  "ModelNameku": "سافران",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 434,
                  "ModelNameen": "Sandero",
                  "ModelNamear": "ساندرو",
                  "ModelNameku": "ساندێرۆ",
                  "Sort": 0,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 454,
                  "ModelNameen": "Scenic",
                  "ModelNamear": "سينيك",
                  "ModelNameku": "سێنیک",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 988,
                  "ModelNameen": "Tondar90",
                  "ModelNamear": "توندار 90",
                  "ModelNameku": "توندار 90",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 444,
                  "ModelNameen": "Trafic",
                  "ModelNamear": "ترافيك",
                  "ModelNameku": "ترافیک",
                  "Sort": 0,
                  "FromYearId": 51,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 51,
                      "YearName": "2001",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 457,
                  "ModelNameen": "Twizy",
                  "ModelNamear": "تويزي",
                  "ModelNameku": "تویزی",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 458,
                  "ModelNameen": "Zoe",
                  "ModelNamear": "زوي",
                  "ModelNameku": "زۆی",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 39,
          "BrandNameen": "Ram",
          "BrandNamear": "رام",
          "BrandNameku": "ڕام",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 42,
          "Attachment": {
              "ID": 42,
              "Url": "/img/BrandAttachments\\1728388408067.4375_Ram.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "R__3_-removebg-preview (1).png",
              "Size": 80961.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 441,
                  "ModelNameen": "1200",
                  "ModelNamear": "1200",
                  "ModelNameku": "1200",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 440,
                  "ModelNameen": "1500",
                  "ModelNamear": "1500",
                  "ModelNameku": "1500",
                  "Sort": 0,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1154,
                  "ModelNameen": "Promaster City",
                  "ModelNamear": "بروماستر سيتي",
                  "ModelNameku": "پرۆماستەر سیتی",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 40,
          "BrandNameen": "Proton",
          "BrandNamear": "بروتون",
          "BrandNameku": "پرۆتۆن",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 61,
          "Attachment": {
              "ID": 61,
              "Url": "/img/BrandAttachments\\1598260879376.8584_Proton",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260879376.8584_Proton",
              "Size": 66801.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 436,
                  "ModelNameen": "GEN-2",
                  "ModelNamear": "جين-2",
                  "ModelNameku": "GEN-2",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1246,
                  "ModelNameen": "Persona",
                  "ModelNamear": "بيرسونا",
                  "ModelNameku": "پێرسۆنا",
                  "Sort": 0,
                  "FromYearId": 43,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 43,
                      "YearName": "1993",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1219,
                  "ModelNameen": "Saga",
                  "ModelNamear": "ساغا",
                  "ModelNameku": "ساگا",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 439,
                  "ModelNameen": "Savvy",
                  "ModelNamear": "سافي",
                  "ModelNameku": "ساڤی",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 437,
                  "ModelNameen": "Waja",
                  "ModelNamear": "واجا",
                  "ModelNameku": "واجە",
                  "Sort": 0,
                  "FromYearId": 53,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 438,
                  "ModelNameen": "Wira",
                  "ModelNamear": "ويرا",
                  "ModelNameku": "ویرا",
                  "Sort": 0,
                  "FromYearId": 47,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 47,
                      "YearName": "1997",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 41,
          "BrandNameen": "Porsche",
          "BrandNamear": "بورش",
          "BrandNameku": "پۆڕش",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 62,
          "Attachment": {
              "ID": 62,
              "Url": "/img/BrandAttachments\\1728387346937.002_Porsche.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "1691931428513.7756_Porsche__1_-removebg-preview (1).png",
              "Size": 75223.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 374,
                  "ModelNameen": "911",
                  "ModelNamear": "911",
                  "ModelNameku": "911",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 370,
                  "ModelNameen": "911 Cabriolet",
                  "ModelNamear": "911 Cabriolet",
                  "ModelNameku": "911 Cabriolet",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 404,
                  "ModelNameen": "911 GT2",
                  "ModelNamear": "911 GT2",
                  "ModelNameku": "911 GT2",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 432,
                  "ModelNameen": "911 GT3",
                  "ModelNamear": "911 GT3",
                  "ModelNameku": "911 GT3",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 405,
                  "ModelNameen": "911 Turbo",
                  "ModelNamear": "911 توربو",
                  "ModelNameku": "911 توربۆ",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 371,
                  "ModelNameen": "911 Turbo Cabriolet",
                  "ModelNamear": "911 توربو كابريوليه",
                  "ModelNameku": "911 توربۆ کابریۆلێت",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 368,
                  "ModelNameen": "Boxster",
                  "ModelNamear": "بوكستر",
                  "ModelNameku": "بۆکستەر",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 366,
                  "ModelNameen": "Cayenne",
                  "ModelNamear": "كايين",
                  "ModelNameku": "کایان",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 367,
                  "ModelNameen": "Cayenne Coupe",
                  "ModelNamear": "كايين كوبيه",
                  "ModelNameku": "کایان کوپ",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 435,
                  "ModelNameen": "Cayman",
                  "ModelNamear": "كايمان",
                  "ModelNameku": "کەیمان",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 369,
                  "ModelNameen": "Macan",
                  "ModelNamear": "ماكان",
                  "ModelNameku": "ماکان",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 365,
                  "ModelNameen": "Panamera",
                  "ModelNamear": "باناميرا",
                  "ModelNameku": "پانەمێرا",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1325,
                  "ModelNameen": "Taycan",
                  "ModelNamear": "تايكان",
                  "ModelNameku": "تایکان",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 43,
          "BrandNameen": "Peugeot",
          "BrandNamear": "بيجو",
          "BrandNameku": "بیجۆ",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 63,
          "Attachment": {
              "ID": 63,
              "Url": "/img/BrandAttachments\\1598260881756.9626_Peugeot",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260881756.9626_Peugeot",
              "Size": 77391.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 362,
                  "ModelNameen": "2008",
                  "ModelNamear": "2008",
                  "ModelNameku": "2008",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 359,
                  "ModelNameen": "206",
                  "ModelNamear": "206",
                  "ModelNameku": "206",
                  "Sort": 0,
                  "FromYearId": 49,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 49,
                      "YearName": "1999",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 360,
                  "ModelNameen": "207",
                  "ModelNamear": "207",
                  "ModelNameku": "207",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 358,
                  "ModelNameen": "208",
                  "ModelNamear": "208",
                  "ModelNameku": "208",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 351,
                  "ModelNameen": "3008",
                  "ModelNamear": "3008",
                  "ModelNameku": "3008",
                  "Sort": 0,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 361,
                  "ModelNameen": "301",
                  "ModelNamear": "301",
                  "ModelNameku": "301",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 990,
                  "ModelNameen": "306",
                  "ModelNamear": "306",
                  "ModelNameku": "306",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 349,
                  "ModelNameen": "307",
                  "ModelNamear": "307",
                  "ModelNameku": "307",
                  "Sort": 0,
                  "FromYearId": 52,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 348,
                  "ModelNameen": "308",
                  "ModelNamear": "308",
                  "ModelNameku": "308",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 373,
                  "ModelNameen": "308 CC",
                  "ModelNamear": "308 CC",
                  "ModelNameku": "308 CC",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1006,
                  "ModelNameen": "405",
                  "ModelNamear": "405",
                  "ModelNameku": "405",
                  "Sort": 0,
                  "FromYearId": 47,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 47,
                      "YearName": "1997",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1105,
                  "ModelNameen": "406",
                  "ModelNamear": "406",
                  "ModelNameku": "406",
                  "Sort": 0,
                  "FromYearId": 45,
                  "ToYearId": 54,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 45,
                      "YearName": "1995",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 355,
                  "ModelNameen": "407",
                  "ModelNamear": "407",
                  "ModelNameku": "407",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 353,
                  "ModelNameen": "407 Coupe",
                  "ModelNamear": "407 كوبيه",
                  "ModelNameku": "407 کوپ",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 350,
                  "ModelNameen": "408",
                  "ModelNamear": "408",
                  "ModelNameku": "408",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 352,
                  "ModelNameen": "5008",
                  "ModelNamear": "5008",
                  "ModelNameku": "5008",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 354,
                  "ModelNameen": "508",
                  "ModelNamear": "508",
                  "ModelNameku": "508",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 356,
                  "ModelNameen": "607",
                  "ModelNamear": "607",
                  "ModelNameku": "607",
                  "Sort": 0,
                  "FromYearId": 52,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 402,
                  "ModelNameen": "Boxer",
                  "ModelNamear": "بوكسر",
                  "ModelNameku": "بۆکسەر",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 375,
                  "ModelNameen": "Expert",
                  "ModelNamear": "اكسبرت",
                  "ModelNameku": "ئێکسپێرت",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1210,
                  "ModelNameen": "Pars",
                  "ModelNamear": "بارس",
                  "ModelNameku": "پارس",
                  "Sort": 0,
                  "FromYearId": 50,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 50,
                      "YearName": "2000",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 376,
                  "ModelNameen": "Partner",
                  "ModelNamear": "بارتنر",
                  "ModelNameku": "پارتنەر",
                  "Sort": 0,
                  "FromYearId": 53,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 357,
                  "ModelNameen": "RCZ",
                  "ModelNamear": "RCZ",
                  "ModelNameku": "RCZ",
                  "Sort": 0,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1076,
                  "ModelNameen": "Roa",
                  "ModelNamear": "روا",
                  "ModelNameku": "رۆوا",
                  "Sort": 0,
                  "FromYearId": 42,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 42,
                      "YearName": "1992",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 49,
          "BrandNameen": "Citroen",
          "BrandNamear": "سيتروين",
          "BrandNameku": "سیترۆن",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 26,
          "Attachment": {
              "ID": 26,
              "Url": "/img/BrandAttachments\\1598260883893.538_Citroen",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": null,
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260883893.538_Citroen",
              "Size": 34741.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 540,
                  "ModelNameen": "C-Elysee",
                  "ModelNamear": "سي إليزيه",
                  "ModelNameku": "C-Elysee",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 536,
                  "ModelNameen": "C2",
                  "ModelNamear": "C2",
                  "ModelNameku": "C2",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 538,
                  "ModelNameen": "C3",
                  "ModelNamear": "C3",
                  "ModelNameku": "C3",
                  "Sort": 0,
                  "FromYearId": 53,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 530,
                  "ModelNameen": "C4",
                  "ModelNamear": "C4",
                  "ModelNameku": "C4",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 532,
                  "ModelNameen": "C4 Picasso",
                  "ModelNamear": "C4 بيكاسو",
                  "ModelNameku": "C4 پیکاسۆ",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 534,
                  "ModelNameen": "C5",
                  "ModelNamear": "C5",
                  "ModelNameku": "C5",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 533,
                  "ModelNameen": "C8",
                  "ModelNamear": "C8",
                  "ModelNameku": "C8",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 535,
                  "ModelNameen": "DS3",
                  "ModelNamear": "DS3",
                  "ModelNameku": "DS3",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 531,
                  "ModelNameen": "DS4",
                  "ModelNamear": "DS4",
                  "ModelNameku": "DS4",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 537,
                  "ModelNameen": "DS5",
                  "ModelNamear": "DS5",
                  "ModelNameku": "DS5",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 539,
                  "ModelNameen": "Saxo",
                  "ModelNamear": "ساخو",
                  "ModelNameku": "ساخۆ",
                  "Sort": 0,
                  "FromYearId": 50,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 50,
                      "YearName": "2000",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 529,
                  "ModelNameen": "Xsara Picasso",
                  "ModelNamear": "كسارا بيكاسو",
                  "ModelNameku": "Xsara پیکاسۆ",
                  "Sort": 0,
                  "FromYearId": 52,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 50,
          "BrandNameen": "Chrysler",
          "BrandNamear": "كرايسلر",
          "BrandNameku": "کرایسلەر",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 27,
          "Attachment": {
              "ID": 27,
              "Url": "/img/BrandAttachments\\1598260884706.6172_Chrysler",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260884706.6172_Chrysler",
              "Size": 39844.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 527,
                  "ModelNameen": "300",
                  "ModelNamear": "300",
                  "ModelNameku": "300",
                  "Sort": 100,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 524,
                  "ModelNameen": "200",
                  "ModelNamear": "200",
                  "ModelNameku": "200",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1238,
                  "ModelNameen": "Concorde",
                  "ModelNamear": "كونكورد",
                  "ModelNameku": "کۆنکۆرد",
                  "Sort": 0,
                  "FromYearId": 42,
                  "ToYearId": 54,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 42,
                      "YearName": "1992",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 528,
                  "ModelNameen": "Crossfire",
                  "ModelNamear": "كروس فاير",
                  "ModelNameku": "کرۆس فایەر",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1382,
                  "ModelNameen": "Grand Caravan",
                  "ModelNamear": "جراند كارافان",
                  "ModelNameku": "گراند کەرەڤان",
                  "Sort": 0,
                  "FromYearId": 46,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 46,
                      "YearName": "1996",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 523,
                  "ModelNameen": "Grand Voyager",
                  "ModelNamear": "جراند فوياجر",
                  "ModelNameku": "گراند ڤۆیاگر",
                  "Sort": 0,
                  "FromYearId": 51,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 51,
                      "YearName": "2001",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1159,
                  "ModelNameen": "New Yorker",
                  "ModelNamear": "نيو يوركر",
                  "ModelNameku": "نیو یۆرکەر",
                  "Sort": 0,
                  "FromYearId": 75,
                  "ToYearId": 47,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 75,
                      "YearName": "1929",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 47,
                      "YearName": "1997",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 522,
                  "ModelNameen": "Pacifica",
                  "ModelNamear": "باسيفيكا",
                  "ModelNameku": "پاسیفیکا",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 521,
                  "ModelNameen": "PT Cruiser",
                  "ModelNamear": "بي تي كروزر",
                  "ModelNameku": "PT کروزەر",
                  "Sort": 0,
                  "FromYearId": 51,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 51,
                      "YearName": "2001",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 525,
                  "ModelNameen": "Sebring",
                  "ModelNamear": "سيبرينج",
                  "ModelNameku": "سێبرینگ",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1001,
                  "ModelNameen": "Town and Country",
                  "ModelNamear": "تاون آند كانتري",
                  "ModelNameku": "تاون ئاند کەنتری",
                  "Sort": 0,
                  "FromYearId": 40,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 40,
                      "YearName": "1990",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 52,
          "BrandNameen": "Chery",
          "BrandNamear": "شيري",
          "BrandNameku": "شێری",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 64,
          "Attachment": {
              "ID": 64,
              "Url": "/img/BrandAttachments\\1662019682936.592_Chery.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "chery-logo (1).png",
              "Size": 42090.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 487,
                  "ModelNameen": "Arrizo 3",
                  "ModelNamear": "أريزو 3",
                  "ModelNameku": "اریزۆ 3",
                  "Sort": 10,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 476,
                  "ModelNameen": "Arrizo 5",
                  "ModelNamear": "أريزو 5",
                  "ModelNameku": "ئەریزۆ 5",
                  "Sort": 9,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1033,
                  "ModelNameen": "Arrizo 6",
                  "ModelNamear": "أريزو 6",
                  "ModelNameku": "ئەریزۆ 6",
                  "Sort": 8,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 488,
                  "ModelNameen": "Arrizo 7",
                  "ModelNamear": "أريزو 7",
                  "ModelNameku": "ئەریزۆ 7",
                  "Sort": 7,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1034,
                  "ModelNameen": "Tiggo 2",
                  "ModelNamear": "تيجو 2",
                  "ModelNameku": "تیگۆ2",
                  "Sort": 6,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1037,
                  "ModelNameen": "Tiggo 2 Pro",
                  "ModelNamear": "تيكو 2 برو",
                  "ModelNameku": "تیگۆ ٢ پرۆ",
                  "Sort": 5,
                  "FromYearId": 71,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 71,
                      "YearName": "2021",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 479,
                  "ModelNameen": "Tiggo 3",
                  "ModelNamear": "تيجو 3",
                  "ModelNameku": "تیگۆ 3",
                  "Sort": 5,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1085,
                  "ModelNameen": "Tiggo 4 Pro",
                  "ModelNamear": "تيجو4 برو",
                  "ModelNameku": "تیگۆ 4 پرۆ",
                  "Sort": 4,
                  "FromYearId": 72,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 72,
                      "YearName": "2022",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 480,
                  "ModelNameen": "Tiggo 5",
                  "ModelNamear": "تيجو 5",
                  "ModelNameku": "تیگۆ 5",
                  "Sort": 3,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 481,
                  "ModelNameen": "Tiggo 7",
                  "ModelNamear": "تيجو 7",
                  "ModelNameku": "تیگۆ 7",
                  "Sort": 2,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1084,
                  "ModelNameen": "Tiggo 7 Pro",
                  "ModelNamear": "تيجو 7 برو",
                  "ModelNameku": "تیگۆ 7 پرۆ",
                  "Sort": 1,
                  "FromYearId": 72,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 72,
                      "YearName": "2022",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1029,
                  "ModelNameen": "Tiggo 8",
                  "ModelNamear": "تيجو 8",
                  "ModelNameku": "تیگۆ",
                  "Sort": 1,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1083,
                  "ModelNameen": "Tiggo 8 Pro",
                  "ModelNamear": "تيجو 8 برو",
                  "ModelNameku": "تیگۆ 8 پرۆ",
                  "Sort": 1,
                  "FromYearId": 72,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 72,
                      "YearName": "2022",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1218,
                  "ModelNameen": "A1",
                  "ModelNamear": "A1",
                  "ModelNameku": "A1",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 477,
                  "ModelNameen": "A11 Windcloud",
                  "ModelNamear": "A11 ويند كلاود",
                  "ModelNameku": "A11 ویند کلاود",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1002,
                  "ModelNameen": "A3",
                  "ModelNamear": "A3",
                  "ModelNameku": "A3",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1306,
                  "ModelNameen": "A5",
                  "ModelNamear": "A5",
                  "ModelNameku": "A5",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": 60,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1008,
                  "ModelNameen": "A515",
                  "ModelNamear": "A515",
                  "ModelNameku": "A515",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1248,
                  "ModelNameen": "A520",
                  "ModelNamear": "A520",
                  "ModelNameku": "A520",
                  "Sort": 0,
                  "FromYearId": 50,
                  "ToYearId": 65,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 50,
                      "YearName": "2000",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 484,
                  "ModelNameen": "B11 Oriental Son",
                  "ModelNamear": "B11 اورنتال سن",
                  "ModelNameku": "B11 ئۆریێنتەڵ سەن",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1374,
                  "ModelNameen": "Chery Arrizo 8",
                  "ModelNamear": "اريزو 8",
                  "ModelNameku": "ئاریزۆ ٨",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1240,
                  "ModelNameen": "Cowin",
                  "ModelNamear": "كوين",
                  "ModelNameku": "کۆوین",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": 66,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 478,
                  "ModelNameen": "E5",
                  "ModelNamear": "E5",
                  "ModelNameku": "E5",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 485,
                  "ModelNameen": "E8",
                  "ModelNamear": "E8",
                  "ModelNameku": "E8",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1280,
                  "ModelNameen": "Eastar",
                  "ModelNamear": "إيستار",
                  "ModelNameku": "ئیستار",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1005,
                  "ModelNameen": "Fulwin 2",
                  "ModelNamear": "فولوين 2",
                  "ModelNameku": "فولوین 2",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1371,
                  "ModelNameen": "Jaecoo J8",
                  "ModelNamear": "جايكو J8",
                  "ModelNameku": "جایکۆ J8",
                  "Sort": 0,
                  "FromYearId": 74,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 74,
                      "YearName": "2023",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1329,
                  "ModelNameen": "P10",
                  "ModelNamear": "P10",
                  "ModelNameku": "P10",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 486,
                  "ModelNameen": "S11 QQ",
                  "ModelNamear": "S11 QQ",
                  "ModelNameku": "S11 QQ",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 482,
                  "ModelNameen": "T11 Tiggo",
                  "ModelNamear": "T11 تيجو",
                  "ModelNameku": "T11 تیگۆ",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1288,
                  "ModelNameen": "Tiggo 7 Pro Max",
                  "ModelNamear": "تيجو 7 برو ماكس",
                  "ModelNameku": "تیگۆ ۷ پرۆ ماکس",
                  "Sort": 0,
                  "FromYearId": 74,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 74,
                      "YearName": "2023",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 483,
                  "ModelNameen": "V5 Crossover",
                  "ModelNamear": "في 5 كروس أوفر",
                  "ModelNameku": "V5 کرۆس ئۆڤەر",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1067,
                  "ModelNameen": "X1",
                  "ModelNamear": "X1",
                  "ModelNameku": "X1",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 56,
          "BrandNameen": "BYD",
          "BrandNamear": "بي واي دي",
          "BrandNameku": "بی وای دی",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 67,
          "Attachment": {
              "ID": 67,
              "Url": "/img/BrandAttachments\\1598260893928.4695_BYD",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260893928.4695_BYD",
              "Size": 12182.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 996,
                  "ModelNameen": "F0",
                  "ModelNamear": "F0",
                  "ModelNameku": "F0",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1355,
                  "ModelNameen": "F1",
                  "ModelNamear": "F1",
                  "ModelNameku": "F1",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": 66,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 502,
                  "ModelNameen": "F3",
                  "ModelNamear": "F3",
                  "ModelNameku": "F3",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 503,
                  "ModelNameen": "F6",
                  "ModelNamear": "F6",
                  "ModelNameku": "F6",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1000,
                  "ModelNameen": "G3",
                  "ModelNamear": "G3",
                  "ModelNameku": "G3",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1356,
                  "ModelNameen": "Seagull",
                  "ModelNamear": "سيجول",
                  "ModelNameku": "سیگوڵ",
                  "Sort": 0,
                  "FromYearId": 72,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 72,
                      "YearName": "2022",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 57,
          "BrandNameen": "Bugatti",
          "BrandNamear": "بوجاتي",
          "BrandNameku": "بوگاتی",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 68,
          "Attachment": {
              "ID": 68,
              "Url": "/img/BrandAttachments\\1598260894567.9463_Bugatti",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260894567.9463_Bugatti",
              "Size": 95403.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 500,
                  "ModelNameen": "Chiron",
                  "ModelNamear": "تشيرون",
                  "ModelNameku": "چیرون",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 501,
                  "ModelNameen": "Veyron",
                  "ModelNamear": "فيرون",
                  "ModelNameku": "ڤیرۆن",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 58,
          "BrandNameen": "Brilliance",
          "BrandNamear": "بريليانس",
          "BrandNameku": "بریلیانس",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 107,
          "Attachment": {
              "ID": 107,
              "Url": "/img/BrandAttachments\\1728387184228.976_Brilliance.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "1683812222792.9443_Brilliance__1_-removebg-preview.png",
              "Size": 80773.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1150,
                  "ModelNameen": "BS2",
                  "ModelNamear": "BS2",
                  "ModelNameku": "BS2",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": 63,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 498,
                  "ModelNameen": "FRV",
                  "ModelNamear": "FRV",
                  "ModelNameku": "FRV",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 499,
                  "ModelNameen": "FRV Cross",
                  "ModelNamear": "FRV Cross",
                  "ModelNameku": "FRV Cross",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 495,
                  "ModelNameen": "FSV",
                  "ModelNamear": "FSV",
                  "ModelNameku": "FSV",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 496,
                  "ModelNameen": "H530",
                  "ModelNamear": "H530",
                  "ModelNameku": "H530",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 497,
                  "ModelNameen": "V5",
                  "ModelNamear": "V5",
                  "ModelNameku": "V5",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 59,
          "BrandNameen": "Borgward",
          "BrandNamear": "بورجوارد",
          "BrandNameku": "بۆرگوارد",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 75,
          "Attachment": {
              "ID": 75,
              "Url": "/img/BrandAttachments\\1606393635291.2903_Borgward.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "Borgward.png",
              "Size": 96717.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 494,
                  "ModelNameen": "BX5",
                  "ModelNamear": "BX5",
                  "ModelNameku": "BX5",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 493,
                  "ModelNameen": "BX7",
                  "ModelNamear": "BX7",
                  "ModelNameku": "BX7",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 61,
          "BrandNameen": "Bentley",
          "BrandNamear": "بينتلي",
          "BrandNameku": "بێنتلی",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 30,
          "Attachment": {
              "ID": 30,
              "Url": "/img/BrandAttachments\\1598260897740.8428_Bentley",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": null,
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260897740.8428_Bentley",
              "Size": 20465.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 685,
                  "ModelNameen": "Arnage",
                  "ModelNamear": "ارنج",
                  "ModelNameku": "ئارنەیج",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 679,
                  "ModelNameen": "Azure",
                  "ModelNamear": "ازور",
                  "ModelNameku": "ئازور",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 687,
                  "ModelNameen": "Bentayga",
                  "ModelNamear": "بينتايجا",
                  "ModelNameku": "بێنتایگا",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 681,
                  "ModelNameen": "Brooklands",
                  "ModelNamear": "بروكلاندز",
                  "ModelNameku": "بروکلاندس",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 682,
                  "ModelNameen": "Continental",
                  "ModelNamear": "كونتننتال",
                  "ModelNameku": "کۆنتینێناڵ",
                  "Sort": 0,
                  "FromYearId": 42,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 42,
                      "YearName": "1992",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 686,
                  "ModelNameen": "Continental Flying Spur",
                  "ModelNamear": "كونتيننتال فلاينج سبير",
                  "ModelNameku": "کۆنتینێناڵ فلاینگ سپور",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 680,
                  "ModelNameen": "Continental GT",
                  "ModelNamear": "كونتيننتال جي تي",
                  "ModelNameku": "کۆنتینێناڵ GT",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 678,
                  "ModelNameen": "Continental GTC",
                  "ModelNamear": "كونتيننتال جي تي سي",
                  "ModelNameku": "کۆنتینێناڵ GTC",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 683,
                  "ModelNameen": "Flying Spur",
                  "ModelNamear": "فلاينج سبير",
                  "ModelNameku": "فلاینگ سپور",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 684,
                  "ModelNameen": "Mulsanne",
                  "ModelNamear": "ملسان",
                  "ModelNameku": "مولسان",
                  "Sort": 0,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 62,
          "BrandNameen": "BAIC",
          "BrandNamear": "بايك",
          "BrandNameku": "بایک",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 69,
          "Attachment": {
              "ID": 69,
              "Url": "/img/BrandAttachments\\1728388779952.5215_BAIC.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "baic-logo-61DC36BBC8-seeklogo.com.png",
              "Size": 12088.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 675,
                  "ModelNameen": "A1 Hatchback",
                  "ModelNamear": "A1 هاتشباك",
                  "ModelNameku": "A1 جانتا",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 677,
                  "ModelNameen": "A1 Sedan",
                  "ModelNamear": "A1 سيدان",
                  "ModelNameku": "A1 سیدان",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1313,
                  "ModelNameen": "A315",
                  "ModelNamear": "A315",
                  "ModelNameku": "A315",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 676,
                  "ModelNameen": "A5 Sedan",
                  "ModelNamear": "A5 سيدان",
                  "ModelNameku": "A5 سیدان",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1293,
                  "ModelNameen": "BJ20",
                  "ModelNamear": "BJ20",
                  "ModelNameku": "BJ20",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1021,
                  "ModelNameen": "BJ40L",
                  "ModelNamear": "BJ40L",
                  "ModelNameku": "BJ40L",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1249,
                  "ModelNameen": "BJ80",
                  "ModelNamear": "BJ80",
                  "ModelNameku": "BJ80",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1330,
                  "ModelNameen": "D20",
                  "ModelNamear": "D20",
                  "ModelNameku": "D20",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1235,
                  "ModelNameen": "D50",
                  "ModelNamear": "D50",
                  "ModelNameku": "D50",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1376,
                  "ModelNameen": "Q35",
                  "ModelNamear": "Q35",
                  "ModelNameku": "Q35",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1129,
                  "ModelNameen": "Senova X65",
                  "ModelNamear": "سينوفا X65",
                  "ModelNameku": "سێنۆڤا X65",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": 67,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1222,
                  "ModelNameen": "U5 PLUS",
                  "ModelNamear": "U5 بلس",
                  "ModelNameku": "U5 پڵەس",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1128,
                  "ModelNameen": "X25",
                  "ModelNamear": "X25",
                  "ModelNameku": "X25",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1223,
                  "ModelNameen": "X3",
                  "ModelNamear": "X3",
                  "ModelNameku": "X3",
                  "Sort": 0,
                  "FromYearId": 71,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 71,
                      "YearName": "2021",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1080,
                  "ModelNameen": "X35",
                  "ModelNamear": "X35",
                  "ModelNameku": "X35",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1357,
                  "ModelNameen": "X55",
                  "ModelNamear": "X55",
                  "ModelNameku": "X55",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 64,
          "BrandNameen": "Aston Martin",
          "BrandNamear": "استون مارتن",
          "BrandNameku": "ئەستۆن مارتن",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 71,
          "Attachment": {
              "ID": 71,
              "Url": "/img/BrandAttachments\\1598260900789.5151_Aston Martin",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260900789.5151_Aston Martin",
              "Size": 22429.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 632,
                  "ModelNameen": "DB11",
                  "ModelNamear": "DB11",
                  "ModelNameku": "DB11",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 629,
                  "ModelNameen": "DB11 Volante",
                  "ModelNamear": "DB11 فولانتي",
                  "ModelNameku": "DB11 ڤۆلانتی",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 604,
                  "ModelNameen": "DB7",
                  "ModelNamear": "DB7",
                  "ModelNameku": "DB7",
                  "Sort": 0,
                  "FromYearId": 45,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 45,
                      "YearName": "1995",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 579,
                  "ModelNameen": "DB9",
                  "ModelNamear": "DB9",
                  "ModelNameku": "DB9",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 631,
                  "ModelNameen": "DB9 Volante",
                  "ModelNamear": "DB9 فولانتي",
                  "ModelNameku": "DB9 ڤۆلانتی",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 607,
                  "ModelNameen": "DBS",
                  "ModelNamear": "DBS",
                  "ModelNameku": "DBS",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1242,
                  "ModelNameen": "DBX",
                  "ModelNamear": "DBX",
                  "ModelNameku": "DBX",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 580,
                  "ModelNameen": "One-77",
                  "ModelNamear": "ون-77",
                  "ModelNameku": "وەن 77",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 628,
                  "ModelNameen": "Rapide",
                  "ModelNamear": "رابد",
                  "ModelNameku": "راپید",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 583,
                  "ModelNameen": "V12 Vantage",
                  "ModelNamear": "V12 فانتج",
                  "ModelNameku": "V12  ڤانتەیج",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 581,
                  "ModelNameen": "V12 Vantage Roadster",
                  "ModelNamear": "V12 فانتج رودستر",
                  "ModelNameku": "V12 ڤانتەیج رۆدستر",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 633,
                  "ModelNameen": "V8 Vantage",
                  "ModelNamear": "V8 فانتج",
                  "ModelNameku": "V8 ڤانتەیج",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 582,
                  "ModelNameen": "V8 Vantage Roadster",
                  "ModelNamear": "V8 فانتج رودستر",
                  "ModelNameku": "V8 ڤانتەیج رۆدستەر",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 606,
                  "ModelNameen": "Vanquish",
                  "ModelNamear": "فانكيش",
                  "ModelNameku": "ڤانکویش",
                  "Sort": 0,
                  "FromYearId": 52,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 630,
                  "ModelNameen": "Vanquish Volante",
                  "ModelNamear": "فانكيش فولانتي",
                  "ModelNameku": "ڤانکویش ڤۆلانتی",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 584,
                  "ModelNameen": "Zagato",
                  "ModelNamear": "زاغاتو",
                  "ModelNameku": "زاگاتۆ",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 65,
          "BrandNameen": "Alfa Romeo",
          "BrandNamear": "الفا روميو",
          "BrandNameku": "ئەلفا رۆمیۆ",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 38,
          "Attachment": {
              "ID": 38,
              "Url": "/img/BrandAttachments\\1728387533719.9182_Alfa Romeo.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "R (1) (1).png",
              "Size": 80061.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 614,
                  "ModelNameen": "147",
                  "ModelNamear": "147",
                  "ModelNameku": "147",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 621,
                  "ModelNameen": "156",
                  "ModelNamear": "156",
                  "ModelNameku": "156",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 622,
                  "ModelNameen": "159",
                  "ModelNamear": "159",
                  "ModelNameku": "159",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 626,
                  "ModelNameen": "166",
                  "ModelNamear": "166",
                  "ModelNameku": "166",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 627,
                  "ModelNameen": "4C",
                  "ModelNamear": "4C",
                  "ModelNameku": "4C",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 615,
                  "ModelNameen": "Brera",
                  "ModelNamear": "Brera",
                  "ModelNameku": "Brera",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 619,
                  "ModelNameen": "Giulia",
                  "ModelNamear": "Giulia",
                  "ModelNameku": "Giulia",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 620,
                  "ModelNameen": "Giulia Quadrifoglio",
                  "ModelNamear": "Giulia Quadrifoglio",
                  "ModelNameku": "Giulia Quadrifoglio",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 613,
                  "ModelNameen": "Giulietta",
                  "ModelNamear": "Giulietta",
                  "ModelNameku": "Giulietta",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 616,
                  "ModelNameen": "Giulietta QV",
                  "ModelNamear": "Giulietta QV",
                  "ModelNameku": "Giulietta QV",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 625,
                  "ModelNameen": "GT Coupe",
                  "ModelNamear": "GT Coupe",
                  "ModelNameku": "GT Coupe",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 617,
                  "ModelNameen": "Mito",
                  "ModelNamear": "Mito",
                  "ModelNameku": "Mito",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 618,
                  "ModelNameen": "Spider",
                  "ModelNamear": "Spider",
                  "ModelNameku": "Spider",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 623,
                  "ModelNameen": "Stelvio",
                  "ModelNamear": "Stelvio",
                  "ModelNameku": "Stelvio",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 624,
                  "ModelNameen": "Stelvio Quadrifoglio",
                  "ModelNamear": "Stelvio Quadrifoglio",
                  "ModelNameku": "Stelvio Quadrifoglio",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 66,
          "BrandNameen": "Acura",
          "BrandNamear": "أكورا",
          "BrandNameku": "ئاکورا",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 31,
          "Attachment": {
              "ID": 31,
              "Url": "/img/BrandAttachments\\1728389085333.996_Acura.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "Acura-Logo-removebg-preview.png",
              "Size": 74864.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1322,
                  "ModelNameen": "Integra",
                  "ModelNamear": "انتيغرا",
                  "ModelNameku": "ئینتێگرا",
                  "Sort": 0,
                  "FromYearId": 74,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 74,
                      "YearName": "2023",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 611,
                  "ModelNameen": "MDX",
                  "ModelNamear": "MDX",
                  "ModelNameku": "MDX",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 612,
                  "ModelNameen": "NSX",
                  "ModelNamear": "NSX",
                  "ModelNameku": "NSX",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1079,
                  "ModelNameen": "RDX",
                  "ModelNamear": "RDX",
                  "ModelNameku": "RDX",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 610,
                  "ModelNameen": "TLX",
                  "ModelNamear": "TLX",
                  "ModelNameku": "TLX",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 67,
          "BrandNameen": "Daihatsu",
          "BrandNamear": "دايهاتسو",
          "BrandNameku": "دایهاتسو",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 72,
          "Attachment": {
              "ID": 72,
              "Url": "/img/BrandAttachments\\1598260904788.1248_Daihatsu",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260904788.1248_Daihatsu",
              "Size": 8184.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 544,
                  "ModelNameen": "Charade",
                  "ModelNamear": "شاريد",
                  "ModelNameku": "چەرەید",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 542,
                  "ModelNameen": "Gran Max Pickup",
                  "ModelNamear": "غران ماكس بيك اب",
                  "ModelNameku": "گران ماکس پیکاپ",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 543,
                  "ModelNameen": "Gran Max Van",
                  "ModelNamear": "جران ماكس فان",
                  "ModelNameku": "گران ماکس ڤان",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 545,
                  "ModelNameen": "Materia",
                  "ModelNamear": "ماتيريا",
                  "ModelNameku": "ماتریا",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 634,
                  "ModelNameen": "Sirion",
                  "ModelNamear": "سيريون",
                  "ModelNameku": "سیریۆن",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 693,
                  "ModelNameen": "Terios",
                  "ModelNamear": "تيريوس",
                  "ModelNameku": "تێریۆس",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 692,
                  "ModelNameen": "YRV",
                  "ModelNamear": "YRV",
                  "ModelNameku": "YRV",
                  "Sort": 0,
                  "FromYearId": 52,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 69,
          "BrandNameen": "Dongfeng",
          "BrandNamear": "دونج فينج",
          "BrandNameku": "دۆنگ فینگ",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 73,
          "Attachment": {
              "ID": 73,
              "Url": "/img/BrandAttachments\\1598260906452.3535_Dongfeng",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260906452.3535_Dongfeng",
              "Size": 18396.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1075,
                  "ModelNameen": "Aeolus A30",
                  "ModelNamear": "Aeolus A30",
                  "ModelNameku": "Aeolus A30",
                  "Sort": 0,
                  "FromYearId": 50,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 50,
                      "YearName": "2000",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1118,
                  "ModelNameen": "Aeolus A60 MAX",
                  "ModelNamear": "Aeolus A60 MAX",
                  "ModelNameku": "Aeolus A60 MAX",
                  "Sort": 0,
                  "FromYearId": 71,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 71,
                      "YearName": "2021",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1205,
                  "ModelNameen": "Aeolus Huge",
                  "ModelNamear": "ايلوس هيوج",
                  "ModelNameku": "ئیلۆس هیوج",
                  "Sort": 0,
                  "FromYearId": 76,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 76,
                      "YearName": "2024",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1073,
                  "ModelNameen": "Aeolus Yixuan GS",
                  "ModelNamear": "Aeolus Yixuan GS",
                  "ModelNameku": "Aeolus Yixuan GS",
                  "Sort": 0,
                  "FromYearId": 50,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 50,
                      "YearName": "2000",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 983,
                  "ModelNameen": "AX7",
                  "ModelNamear": "AX7",
                  "ModelNameku": "AX7",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1337,
                  "ModelNameen": "C32",
                  "ModelNamear": "C32",
                  "ModelNameku": "C32",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1336,
                  "ModelNameen": "C35",
                  "ModelNamear": "C35",
                  "ModelNameku": "C35",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1314,
                  "ModelNameen": "C37",
                  "ModelNamear": "C37",
                  "ModelNameku": "C37",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1353,
                  "ModelNameen": "Glory",
                  "ModelNamear": "جلوري",
                  "ModelNameku": "گلۆری",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 853,
                  "ModelNameen": "H30 Cross",
                  "ModelNamear": "H30 Cross",
                  "ModelNameku": "H30 Cross",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1142,
                  "ModelNameen": "iX5",
                  "ModelNamear": "iX5",
                  "ModelNameku": "iX5",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1354,
                  "ModelNameen": "ix7",
                  "ModelNamear": "ix7",
                  "ModelNameku": "ix7",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1209,
                  "ModelNameen": "Rich",
                  "ModelNamear": "ريج",
                  "ModelNameku": "ڕیچ",
                  "Sort": 0,
                  "FromYearId": 49,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 49,
                      "YearName": "1999",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 852,
                  "ModelNameen": "S30",
                  "ModelNamear": "S30",
                  "ModelNameku": "S30",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1368,
                  "ModelNameen": "Shine",
                  "ModelNamear": "شاين",
                  "ModelNameku": "شاین",
                  "Sort": 0,
                  "FromYearId": 71,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 71,
                      "YearName": "2021",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1068,
                  "ModelNameen": "T5 EVO",
                  "ModelNamear": "T5 EVO",
                  "ModelNameku": "T5 EVO",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1072,
                  "ModelNameen": "T5L",
                  "ModelNamear": "T5L",
                  "ModelNameku": "T5L",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1125,
                  "ModelNameen": "Venucia",
                  "ModelNamear": "فينوسيا",
                  "ModelNameku": "ڤێنۆسیا",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 70,
          "BrandNameen": "FAW",
          "BrandNamear": "فاو",
          "BrandNameku": "فاو",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 74,
          "Attachment": {
              "ID": 74,
              "Url": "/img/BrandAttachments\\1598260912284.5725_FAW",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260912284.5725_FAW",
              "Size": 83383.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1272,
                  "ModelNameen": "Bestune B50",
                  "ModelNamear": "بيستون B50",
                  "ModelNameku": "بێستون B50",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 981,
                  "ModelNameen": "Besturn B30",
                  "ModelNamear": "بسترن B30",
                  "ModelNameku": "بێستێرن B30",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 855,
                  "ModelNameen": "Besturn X40",
                  "ModelNamear": "بيستورن X40",
                  "ModelNameku": "بێستێرن X40",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 854,
                  "ModelNameen": "Besturn X80",
                  "ModelNamear": "بيستورن X80",
                  "ModelNameku": "بێستێرن X80",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 994,
                  "ModelNameen": "H5",
                  "ModelNamear": "H5",
                  "ModelNameku": "H5",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1370,
                  "ModelNameen": "N5",
                  "ModelNamear": "إن 5",
                  "ModelNameku": "ئێن ٥",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": 67,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1231,
                  "ModelNameen": "Oley",
                  "ModelNamear": "أولي",
                  "ModelNameku": "ئۆلی",
                  "Sort": 0,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 73,
          "BrandNameen": "JAC",
          "BrandNamear": "جاك",
          "BrandNameku": "جاک",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 78,
          "Attachment": {
              "ID": 78,
              "Url": "/img/BrandAttachments\\1598260914973.952_JAC",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260914973.952_JAC",
              "Size": 46259.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1103,
                  "ModelNameen": "J3",
                  "ModelNamear": "J3",
                  "ModelNameku": "J3",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 793,
                  "ModelNameen": "J4",
                  "ModelNamear": "J4",
                  "ModelNameku": "J4",
                  "Sort": 0,
                  "FromYearId": 50,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 50,
                      "YearName": "2000",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 790,
                  "ModelNameen": "J5",
                  "ModelNamear": "J5",
                  "ModelNameku": "J5",
                  "Sort": 0,
                  "FromYearId": 50,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 50,
                      "YearName": "2000",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 792,
                  "ModelNameen": "J6",
                  "ModelNamear": "J6",
                  "ModelNameku": "J6",
                  "Sort": 0,
                  "FromYearId": 50,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 50,
                      "YearName": "2000",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1346,
                  "ModelNameen": "J7",
                  "ModelNamear": "J7",
                  "ModelNameku": "J7",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1369,
                  "ModelNameen": "JS3",
                  "ModelNamear": "جي اس 3",
                  "ModelNameku": "جەی ئێس ٣",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1377,
                  "ModelNameen": "JS4",
                  "ModelNamear": "JS4",
                  "ModelNameku": "JS4",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 791,
                  "ModelNameen": "M5 MPV",
                  "ModelNamear": "M5 MPV",
                  "ModelNameku": "M5 MPV",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 794,
                  "ModelNameen": "S3 SUV",
                  "ModelNamear": "S3 SUV",
                  "ModelNameku": "S3 SUV",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 74,
          "BrandNameen": "Isuzu",
          "BrandNamear": "اسوزو",
          "BrandNameku": "ئاسوزو",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 79,
          "Attachment": {
              "ID": 79,
              "Url": "/img/BrandAttachments\\1598260915701.0845_Isuzu",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260915701.0845_Isuzu",
              "Size": 4147.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 786,
                  "ModelNameen": "D-Max",
                  "ModelNamear": "دي-ماكس",
                  "ModelNameku": "D-Max",
                  "Sort": 0,
                  "FromYearId": 53,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 788,
                  "ModelNameen": "MU-X",
                  "ModelNamear": "إم يو-اكس",
                  "ModelNameku": "MU-X",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1216,
                  "ModelNameen": "Rodeo",
                  "ModelNamear": "روديو",
                  "ModelNameku": "ڕۆدیۆ",
                  "Sort": 0,
                  "FromYearId": 20,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 20,
                      "YearName": "1970",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 789,
                  "ModelNameen": "Trooper",
                  "ModelNamear": "تروبر",
                  "ModelNameku": "تروپەر",
                  "Sort": 0,
                  "FromYearId": 48,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 48,
                      "YearName": "1998",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 76,
          "BrandNameen": "Infiniti",
          "BrandNamear": "انفنتي",
          "BrandNameku": "ئينفنتي",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 80,
          "Attachment": {
              "ID": 80,
              "Url": "/img/BrandAttachments\\1598260916329.2903_Infiniti",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260916329.2903_Infiniti",
              "Size": 28757.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 752,
                  "ModelNameen": "EX",
                  "ModelNamear": "EX",
                  "ModelNameku": "EX",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 715,
                  "ModelNameen": "FX",
                  "ModelNamear": "FX",
                  "ModelNameku": "FX",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 711,
                  "ModelNameen": "G",
                  "ModelNamear": "G",
                  "ModelNameku": "G",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 751,
                  "ModelNameen": "G35",
                  "ModelNamear": "G35",
                  "ModelNameku": "G35",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 782,
                  "ModelNameen": "G35 Coupe",
                  "ModelNamear": "G35 كوبيه",
                  "ModelNameku": "G35 کوپ",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 718,
                  "ModelNameen": "G37 Convertible",
                  "ModelNamear": "G37كونفرتبل",
                  "ModelNameku": "G37 کۆنڤێرتەبل",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 721,
                  "ModelNameen": "G37 Coupe",
                  "ModelNamear": "G37 كوبيه",
                  "ModelNameku": "G37 کوپ",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 716,
                  "ModelNameen": "JX",
                  "ModelNamear": "JX",
                  "ModelNameku": "JX",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 712,
                  "ModelNameen": "M",
                  "ModelNamear": "M",
                  "ModelNameku": "M",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 783,
                  "ModelNameen": "Q30",
                  "ModelNamear": "Q30",
                  "ModelNameku": "Q30",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 707,
                  "ModelNameen": "Q45",
                  "ModelNamear": "Q45",
                  "ModelNameku": "Q45",
                  "Sort": 0,
                  "FromYearId": 52,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 706,
                  "ModelNameen": "Q50",
                  "ModelNamear": "Q50",
                  "ModelNameku": "Q50",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 717,
                  "ModelNameen": "Q60 Convertible",
                  "ModelNamear": "Q60 المكشوفة",
                  "ModelNameku": "Q60 کۆنڤێرتەبل",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 719,
                  "ModelNameen": "Q60 Coupe",
                  "ModelNamear": "Q60 كوبيه",
                  "ModelNameku": "Q60 کوپ",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 710,
                  "ModelNameen": "Q70",
                  "ModelNamear": "Q70",
                  "ModelNameku": "Q70",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 784,
                  "ModelNameen": "QX30",
                  "ModelNamear": "QX30",
                  "ModelNameku": "QX30",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1305,
                  "ModelNameen": "QX4",
                  "ModelNamear": "QX4",
                  "ModelNameku": "QX4",
                  "Sort": 0,
                  "FromYearId": 47,
                  "ToYearId": 51,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 47,
                      "YearName": "1997",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 51,
                      "YearName": "2001",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 779,
                  "ModelNameen": "QX50",
                  "ModelNamear": "QX50",
                  "ModelNameku": "QX50",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 709,
                  "ModelNameen": "QX56",
                  "ModelNamear": "QX56",
                  "ModelNameku": "QX56",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 713,
                  "ModelNameen": "QX60",
                  "ModelNamear": "QX60",
                  "ModelNameku": "QX60",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 714,
                  "ModelNameen": "QX70",
                  "ModelNamear": "QX70",
                  "ModelNameku": "QX70",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 708,
                  "ModelNameen": "QX80",
                  "ModelNamear": "QX80",
                  "ModelNameku": "QX80",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 78,
          "BrandNameen": "Hummer",
          "BrandNamear": "همر",
          "BrandNameku": "هەمەر",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 39,
          "Attachment": {
              "ID": 39,
              "Url": "/img/BrandAttachments\\1598260918136.6067_Hummer",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260918136.6067_Hummer",
              "Size": 11878.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 734,
                  "ModelNameen": "H2",
                  "ModelNamear": "H2",
                  "ModelNameku": "H2",
                  "Sort": 0,
                  "FromYearId": 53,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 733,
                  "ModelNameen": "H3",
                  "ModelNamear": "H3",
                  "ModelNameku": "H3",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 79,
          "BrandNameen": "Honda",
          "BrandNamear": "هوندا",
          "BrandNameku": "هۆندا",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 29,
          "Attachment": {
              "ID": 29,
              "Url": "/img/BrandAttachments\\1598260918755.125_Honda",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": null,
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260918755.125_Honda",
              "Size": 71623.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 726,
                  "ModelNameen": "Accord",
                  "ModelNamear": "أكورد",
                  "ModelNameku": "ئەکۆرد",
                  "Sort": 0,
                  "FromYearId": 48,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 48,
                      "YearName": "1998",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 725,
                  "ModelNameen": "Accord Coupe",
                  "ModelNamear": "أكورد كوبيه",
                  "ModelNameku": "ئەکۆرد کوپ",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 729,
                  "ModelNameen": "Accord Crosstour",
                  "ModelNamear": "أكورد كروس تور",
                  "ModelNameku": "ئەکۆرد Crosstour",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1135,
                  "ModelNameen": "Acura RDX",
                  "ModelNamear": "RDX أكورا",
                  "ModelNameku": "RDX ئاکورا",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 732,
                  "ModelNameen": "City",
                  "ModelNamear": "سيتي",
                  "ModelNameku": "سیتی",
                  "Sort": 0,
                  "FromYearId": 53,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 893,
                  "ModelNameen": "Civic",
                  "ModelNamear": "سيفيك",
                  "ModelNameku": "سیڤیک",
                  "Sort": 0,
                  "FromYearId": 34,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 34,
                      "YearName": "1984",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 891,
                  "ModelNameen": "Civic Hatchback",
                  "ModelNamear": "سيفيك هاتشباك",
                  "ModelNameku": "سیڤیک جانتا",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 810,
                  "ModelNameen": "CR-V",
                  "ModelNamear": "سي ار في",
                  "ModelNameku": "CR-V",
                  "Sort": 0,
                  "FromYearId": 52,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 892,
                  "ModelNameen": "CRX",
                  "ModelNamear": "CRX",
                  "ModelNameku": "CRX",
                  "Sort": 0,
                  "FromYearId": 34,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 34,
                      "YearName": "1984",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1363,
                  "ModelNameen": "Element",
                  "ModelNamear": "إليمنت",
                  "ModelNameku": "ئێلیمێنت",
                  "Sort": 0,
                  "FromYearId": 53,
                  "ToYearId": 61,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1133,
                  "ModelNameen": "eNS1",
                  "ModelNamear": "eNS1",
                  "ModelNameku": "eNS1",
                  "Sort": 0,
                  "FromYearId": 72,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 72,
                      "YearName": "2022",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 866,
                  "ModelNameen": "HR-V",
                  "ModelNamear": "HR-V",
                  "ModelNameku": "HR-V",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1264,
                  "ModelNameen": "Insight",
                  "ModelNamear": "إنسايت",
                  "ModelNameku": "ئینسایت",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 731,
                  "ModelNameen": "Jazz",
                  "ModelNamear": "جاز",
                  "ModelNameku": "جاز",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 724,
                  "ModelNameen": "Legend",
                  "ModelNamear": "لجند",
                  "ModelNameku": "لیجێند",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 728,
                  "ModelNameen": "MR-V",
                  "ModelNamear": "إم آر في",
                  "ModelNameku": "MR-V",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 809,
                  "ModelNameen": "Odyssey",
                  "ModelNamear": "اوديسي",
                  "ModelNameku": "ئۆدسی",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 750,
                  "ModelNameen": "Odyssey J",
                  "ModelNamear": "أوديسي جي",
                  "ModelNameku": "ئۆدسی J",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1047,
                  "ModelNameen": "Passport",
                  "ModelNamear": "با سبورت",
                  "ModelNameku": "پاسپۆرت",
                  "Sort": 0,
                  "FromYearId": 42,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 42,
                      "YearName": "1992",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 727,
                  "ModelNameen": "Pilot",
                  "ModelNamear": "بايلوت",
                  "ModelNameku": "پایلۆت",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 730,
                  "ModelNameen": "S2000",
                  "ModelNamear": "S2000",
                  "ModelNameku": "S2000",
                  "Sort": 0,
                  "FromYearId": 50,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 50,
                      "YearName": "2000",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 81,
          "BrandNameen": "Haval",
          "BrandNamear": "هفال",
          "BrandNameku": "هەڤاڵ",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 81,
          "Attachment": {
              "ID": 81,
              "Url": "/img/BrandAttachments\\1598260919868.6785_Haval",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260919868.6785_Haval",
              "Size": 20765.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1031,
                  "ModelNameen": "Dargo",
                  "ModelNamear": "دارجو",
                  "ModelNameku": "دارگۆ",
                  "Sort": 0,
                  "FromYearId": 72,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 72,
                      "YearName": "2022",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 890,
                  "ModelNameen": "H2 Crossover",
                  "ModelNamear": "H2 Crossover",
                  "ModelNameku": "H2 Crossover",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 887,
                  "ModelNameen": "H6",
                  "ModelNamear": "H6",
                  "ModelNameku": "H6",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1131,
                  "ModelNameen": "H6 GT",
                  "ModelNamear": "جي تي H6",
                  "ModelNameku": "جى تى H6",
                  "Sort": 0,
                  "FromYearId": 71,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 71,
                      "YearName": "2021",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 980,
                  "ModelNameen": "H7",
                  "ModelNamear": "H7",
                  "ModelNameku": "H7",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 889,
                  "ModelNameen": "H8",
                  "ModelNamear": "H8",
                  "ModelNameku": "H8",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 888,
                  "ModelNameen": "H9",
                  "ModelNamear": "H9",
                  "ModelNameku": "H9",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1009,
                  "ModelNameen": "Jolion",
                  "ModelNamear": "جوليون",
                  "ModelNameku": "جۆلیۆن",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 82,
          "BrandNameen": "GMC",
          "BrandNamear": "جي ام سي",
          "BrandNameku": "جی ئێم سی",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 22,
          "Attachment": {
              "ID": 22,
              "Url": "/img/BrandAttachments\\1598260920688.86_GMC",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": null,
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260920688.86_GMC",
              "Size": 34923.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 883,
                  "ModelNameen": "Acadia",
                  "ModelNamear": "اكاديا",
                  "ModelNameku": "ئەکادیا",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1193,
                  "ModelNameen": "Canyon",
                  "ModelNamear": "كانيون",
                  "ModelNameku": "کانیۆن",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 884,
                  "ModelNameen": "Envoy",
                  "ModelNamear": "انفوي",
                  "ModelNameku": "ئینڤۆی",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1292,
                  "ModelNameen": "Hummer EV",
                  "ModelNamear": "هامر إي في",
                  "ModelNameku": "هەمەر ئیڤی",
                  "Sort": 0,
                  "FromYearId": 72,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 72,
                      "YearName": "2022",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1220,
                  "ModelNameen": "Jimmy",
                  "ModelNamear": "جيمي",
                  "ModelNameku": "جیمی",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1028,
                  "ModelNameen": "Savana",
                  "ModelNamear": "سافانا",
                  "ModelNameku": "ساڤانا",
                  "Sort": 0,
                  "FromYearId": 45,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 45,
                      "YearName": "1995",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 881,
                  "ModelNameen": "Sierra",
                  "ModelNamear": "سييرا",
                  "ModelNameku": "سیرا",
                  "Sort": 0,
                  "FromYearId": 31,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 31,
                      "YearName": "1981",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 880,
                  "ModelNameen": "Terrain",
                  "ModelNamear": "تيراين",
                  "ModelNameku": "تیرەین",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 882,
                  "ModelNameen": "Yukon",
                  "ModelNamear": "يوكون",
                  "ModelNameku": "یوکۆن",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1375,
                  "ModelNameen": "Yukon XL",
                  "ModelNamear": "يوكون إكس إل",
                  "ModelNameku": "یوکۆن ئێکس ئێڵ",
                  "Sort": 0,
                  "FromYearId": 50,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 50,
                      "YearName": "2000",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 83,
          "BrandNameen": "Genesis",
          "BrandNamear": "جنسس",
          "BrandNameku": "جێنسس",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 82,
          "Attachment": {
              "ID": 82,
              "Url": "/img/BrandAttachments\\1691934297627.7222_Genesis.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "Genesis_logo_PNG555555.png",
              "Size": 12001.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 878,
                  "ModelNameen": "G70",
                  "ModelNamear": "G70",
                  "ModelNameku": "G70",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 879,
                  "ModelNameen": "G80",
                  "ModelNamear": "G80",
                  "ModelNameku": "G80",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 877,
                  "ModelNameen": "G90",
                  "ModelNamear": "G90",
                  "ModelNameku": "G90",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1058,
                  "ModelNameen": "GV70",
                  "ModelNamear": "GV70",
                  "ModelNameku": "GV70",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1111,
                  "ModelNameen": "GV80",
                  "ModelNamear": "GV80",
                  "ModelNameku": "GV80",
                  "Sort": 0,
                  "FromYearId": 71,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 71,
                      "YearName": "2021",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 84,
          "BrandNameen": "Geely",
          "BrandNamear": "جيلي",
          "BrandNameku": "جیلی",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 83,
          "Attachment": {
              "ID": 83,
              "Url": "/img/BrandAttachments\\1691317258728.4275_Geely.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "Geely-Auto-New-01ZOOM2.png",
              "Size": 14712.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1082,
                  "ModelNameen": "Coolray",
                  "ModelNamear": "كولراي",
                  "ModelNameku": "کوڵڕەی",
                  "Sort": 10,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1157,
                  "ModelNameen": "Emgrand",
                  "ModelNamear": "امجراند",
                  "ModelNameku": "ئیمگراند",
                  "Sort": 9,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1081,
                  "ModelNameen": "Azkarra",
                  "ModelNamear": "أزكارا",
                  "ModelNameku": "ئەزکارا",
                  "Sort": 6,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1158,
                  "ModelNameen": "Okavango",
                  "ModelNamear": "أوكافانغو",
                  "ModelNameku": "ئۆکاڤانگۆ",
                  "Sort": 4,
                  "FromYearId": 74,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 74,
                      "YearName": "2023",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1182,
                  "ModelNameen": "GX3 Pro",
                  "ModelNamear": "برو GX3",
                  "ModelNameku": "پرۆ GX3",
                  "Sort": 2,
                  "FromYearId": 76,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 76,
                      "YearName": "2024",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1012,
                  "ModelNameen": "Boyue",
                  "ModelNamear": "بويو",
                  "ModelNameku": "بۆیو",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 875,
                  "ModelNameen": "CK",
                  "ModelNamear": "CK",
                  "ModelNameku": "CK",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 896,
                  "ModelNameen": "Emgrand 7",
                  "ModelNamear": "امجراند 7",
                  "ModelNameku": "ئێم گراند 7",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 871,
                  "ModelNameen": "Emgrand 8",
                  "ModelNamear": "امجراند 8",
                  "ModelNameku": "ئێم گراند 8",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 923,
                  "ModelNameen": "Emgrand GS",
                  "ModelNamear": "إمجراند جي إس",
                  "ModelNameku": "ئێم گراند GS",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 872,
                  "ModelNameen": "Emgrand GT",
                  "ModelNamear": "امجراند جي تي",
                  "ModelNameku": "ئێم گراند GT",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 869,
                  "ModelNameen": "Emgrand X7",
                  "ModelNamear": "امجراند X7",
                  "ModelNameku": "ئێم گراند X7",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 870,
                  "ModelNameen": "Emgrand X7 Sport",
                  "ModelNamear": "امجراند X7 سبورت",
                  "ModelNameku": "ئێم گراند X7 Sport",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 873,
                  "ModelNameen": "GC2",
                  "ModelNamear": "GC2",
                  "ModelNameku": "GC2",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 874,
                  "ModelNameen": "GC6",
                  "ModelNamear": "GC6",
                  "ModelNameku": "GC6",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 894,
                  "ModelNameen": "GC7",
                  "ModelNamear": "GC7",
                  "ModelNameku": "GC7",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 876,
                  "ModelNameen": "GX2",
                  "ModelNamear": "GX2",
                  "ModelNameku": "GX2",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1180,
                  "ModelNameen": "GX3",
                  "ModelNamear": "GX3",
                  "ModelNameku": "GX3",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1226,
                  "ModelNameen": "LC",
                  "ModelNamear": "LC",
                  "ModelNameku": "LC",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1198,
                  "ModelNameen": "MK",
                  "ModelNamear": "MK",
                  "ModelNameku": "MK",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": 70,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1394,
                  "ModelNameen": "Monjaro",
                  "ModelNamear": "مونجارو",
                  "ModelNameku": "مۆنجارۆ",
                  "Sort": 0,
                  "FromYearId": 71,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 71,
                      "YearName": "2021",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1007,
                  "ModelNameen": "SC7",
                  "ModelNamear": "SC7",
                  "ModelNameku": "SC7",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1342,
                  "ModelNameen": "STARRAY",
                  "ModelNamear": "ستاراي",
                  "ModelNameku": "ستارەی",
                  "Sort": 0,
                  "FromYearId": 72,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 72,
                      "YearName": "2022",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1181,
                  "ModelNameen": "Tugella",
                  "ModelNamear": "توجيلا",
                  "ModelNameku": "توگێلا",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 86,
          "BrandNameen": "Foton",
          "BrandNamear": "فوتون",
          "BrandNameku": "فۆتۆن",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 89,
          "Attachment": {
              "ID": 89,
              "Url": "/img/BrandAttachments\\1728388936947.5994_Foton.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "Foton-emblem-768x666-removebg-preview (1).png",
              "Size": 81276.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1386,
                  "ModelNameen": "SUP",
                  "ModelNamear": "سوب",
                  "ModelNameku": "سوپ",
                  "Sort": 0,
                  "FromYearId": 53,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 915,
                  "ModelNameen": "Tunland",
                  "ModelNamear": "تونلاند",
                  "ModelNameku": "تونلاند",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1152,
                  "ModelNameen": "View CS2",
                  "ModelNamear": "فيو CS2",
                  "ModelNameku": "فيو CS2",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 89,
          "BrandNameen": "Fiat",
          "BrandNamear": "فيات",
          "BrandNameku": "فیات",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 23,
          "Attachment": {
              "ID": 23,
              "Url": "/img/BrandAttachments\\1728300260191.9973_Fiat.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "Fiat-Logo.png",
              "Size": 22706.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1004,
                  "ModelNameen": "124 Spider",
                  "ModelNamear": "124 سبايدر",
                  "ModelNameku": "124 سپایدەر",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 823,
                  "ModelNameen": "500",
                  "ModelNamear": "500",
                  "ModelNameku": "500",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 821,
                  "ModelNameen": "500L",
                  "ModelNamear": "500L",
                  "ModelNameku": "500L",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 825,
                  "ModelNameen": "500X",
                  "ModelNamear": "500X",
                  "ModelNameku": "500X",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 819,
                  "ModelNameen": "Bravo",
                  "ModelNamear": "برافو",
                  "ModelNameku": "براڤۆ",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 818,
                  "ModelNameen": "Ducato",
                  "ModelNamear": "دوكاتو",
                  "ModelNameku": "دوکاتۆ",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 817,
                  "ModelNameen": "Fiorino",
                  "ModelNamear": "فيورينو",
                  "ModelNameku": "فیۆرینۆ",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1099,
                  "ModelNameen": "Freemont",
                  "ModelNamear": "فريمونت",
                  "ModelNameku": "فریمۆنت",
                  "Sort": 0,
                  "FromYearId": 61,
                  "ToYearId": 66,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 820,
                  "ModelNameen": "Linea",
                  "ModelNamear": "لينيا",
                  "ModelNameku": "لینیە",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 824,
                  "ModelNameen": "Punto",
                  "ModelNamear": "بونتو",
                  "ModelNameku": "پونتۆ",
                  "Sort": 0,
                  "FromYearId": 50,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 50,
                      "YearName": "2000",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1247,
                  "ModelNameen": "Tipo",
                  "ModelNamear": "تيبو",
                  "ModelNameku": "تیپۆ",
                  "Sort": 0,
                  "FromYearId": 38,
                  "ToYearId": 50,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 38,
                      "YearName": "1988",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 50,
                      "YearName": "2000",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 90,
          "BrandNameen": "Ferrari",
          "BrandNamear": "فراري",
          "BrandNameku": "فێراری",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 24,
          "Attachment": {
              "ID": 24,
              "Url": "/img/BrandAttachments\\1598260927538.164_Ferrari",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260927538.164_Ferrari",
              "Size": 88871.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 813,
                  "ModelNameen": "360",
                  "ModelNamear": "360",
                  "ModelNameku": "360",
                  "Sort": 0,
                  "FromYearId": 50,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 50,
                      "YearName": "2000",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 814,
                  "ModelNameen": "458",
                  "ModelNamear": "458",
                  "ModelNameku": "458",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 865,
                  "ModelNameen": "458 Spider",
                  "ModelNamear": "458 سبايدر",
                  "ModelNameku": "458 سپایدەر",
                  "Sort": 0,
                  "FromYearId": 63,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 815,
                  "ModelNameen": "488 GTB",
                  "ModelNamear": "488 GTB",
                  "ModelNameku": "488 GTB",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 838,
                  "ModelNameen": "488 Spider",
                  "ModelNamear": "488 سبايدر",
                  "ModelNameku": "488 سپایدەر",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 860,
                  "ModelNameen": "599 GTB Fiorano",
                  "ModelNamear": "599  جي تي بي فيورانو",
                  "ModelNameku": "599 GTB فیۆرانۆ",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 861,
                  "ModelNameen": "612 Scaglietti",
                  "ModelNamear": "612 سكاجليتي",
                  "ModelNameku": "612 سکاگلێتی",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 859,
                  "ModelNameen": "812 Superfast",
                  "ModelNamear": "812 سوبر فاست",
                  "ModelNameku": "812 سوپەر فاست",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 857,
                  "ModelNameen": "California",
                  "ModelNamear": "كاليفورنيا",
                  "ModelNameku": "کالیفۆرنیا",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 858,
                  "ModelNameen": "California T",
                  "ModelNamear": "كاليفورنيا تي",
                  "ModelNameku": "کالیفۆرنیا T",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 862,
                  "ModelNameen": "F12 Berlinetta",
                  "ModelNamear": "F12 بيرلينيتا",
                  "ModelNameku": "F12 بێرلینێتا",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 816,
                  "ModelNameen": "F430",
                  "ModelNamear": "F430",
                  "ModelNameku": "F430",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 836,
                  "ModelNameen": "F430 Spider",
                  "ModelNamear": "F430 سبايدر",
                  "ModelNameku": "F430 سپایدەر",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 811,
                  "ModelNameen": "F8 Tributo",
                  "ModelNamear": "F8 تريبوتو",
                  "ModelNameku": "F8 ترایبوتۆ",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 839,
                  "ModelNameen": "FF",
                  "ModelNamear": "FF",
                  "ModelNameku": "FF",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 863,
                  "ModelNameen": "GTC4Lusso",
                  "ModelNamear": "GTC4Lusso",
                  "ModelNameku": "GTC4Lusso",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 864,
                  "ModelNameen": "GTC4Lusso T",
                  "ModelNamear": "GTC4Lusso T",
                  "ModelNameku": "GTC4Lusso T",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 812,
                  "ModelNameen": "LaFerrari",
                  "ModelNamear": "لافيراري",
                  "ModelNameku": "لا فێراری",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 856,
                  "ModelNameen": "Portofino",
                  "ModelNamear": "بورتوفينو",
                  "ModelNameku": "پۆرتۆفینۆ",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1391,
                  "ModelNameen": "Roma",
                  "ModelNamear": "روما",
                  "ModelNameku": "ڕۆما",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 91,
          "BrandNameen": "Great Wall",
          "BrandNamear": "جريت وول",
          "BrandNameku": "گرەیتۆڵ",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 85,
          "Attachment": {
              "ID": 85,
              "Url": "/img/BrandAttachments\\1598260928696.6147_Great Wall",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "BrandAttachments\\1598260928696.6147_Great Wall",
              "Size": 62120.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1060,
                  "ModelNameen": "Deer",
                  "ModelNamear": "دير",
                  "ModelNameku": "دير",
                  "Sort": 0,
                  "FromYearId": 46,
                  "ToYearId": 63,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 46,
                      "YearName": "1996",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 63,
                      "YearName": "2013",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 886,
                  "ModelNameen": "Florid",
                  "ModelNamear": "فلوريد",
                  "ModelNameku": "فلۆرید",
                  "Sort": 0,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1192,
                  "ModelNameen": "ORA",
                  "ModelNamear": "اورا",
                  "ModelNameku": "ئۆرا",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 998,
                  "ModelNameen": "Poer",
                  "ModelNamear": "بوير",
                  "ModelNameku": "پۆیر",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1179,
                  "ModelNameen": "Poer King Kong",
                  "ModelNamear": "بوير كينغ كونغ",
                  "ModelNameku": "پۆیر کینگ کۆنگ",
                  "Sort": 0,
                  "FromYearId": 71,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 71,
                      "YearName": "2021",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1110,
                  "ModelNameen": "Tank 300",
                  "ModelNamear": "تانك 300",
                  "ModelNameku": "تانک 300",
                  "Sort": 0,
                  "FromYearId": 50,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 50,
                      "YearName": "2000",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1194,
                  "ModelNameen": "Tank 500",
                  "ModelNamear": "تانك 500",
                  "ModelNameku": "تانک 500",
                  "Sort": 0,
                  "FromYearId": 71,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 71,
                      "YearName": "2021",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1290,
                  "ModelNameen": "Voleex C30",
                  "ModelNamear": "فوليكس سي 30",
                  "ModelNameku": "ڤۆلێکس C30",
                  "Sort": 0,
                  "FromYearId": 60,
                  "ToYearId": 66,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 60,
                      "YearName": "2010",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 885,
                  "ModelNameen": "Wingle",
                  "ModelNamear": "وينجل",
                  "ModelNameku": "وینگل",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 92,
          "BrandNameen": "Zotye",
          "BrandNamear": "زوتي",
          "BrandNameku": "زۆتی",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 91,
          "Attachment": {
              "ID": 91,
              "Url": "/img/BrandAttachments\\1599659445612.2131_Zotye.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "kisspng-car-zotye-t600-zotye-auto-renault-subaru-logo-5b1685c2c7d4f6.png.png",
              "Size": 28282.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1178,
                  "ModelNameen": "Domy X5",
                  "ModelNamear": "دومي X5",
                  "ModelNameku": "دۆمی X5",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": 71,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 71,
                      "YearName": "2021",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 971,
                  "ModelNameen": "Domy X7",
                  "ModelNamear": "دومي X7",
                  "ModelNameku": "دۆمی X7",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 312,
                  "ModelNameen": "SR9",
                  "ModelNamear": "SR9",
                  "ModelNameku": "SR9",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 924,
                  "ModelNameen": "T300",
                  "ModelNamear": "T300",
                  "ModelNameku": "T300",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 313,
                  "ModelNameen": "T600",
                  "ModelNamear": "T600",
                  "ModelNameku": "T600",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 314,
                  "ModelNameen": "T600 Sport",
                  "ModelNamear": "T600 Sport",
                  "ModelNameku": "T600 Sport",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 808,
                  "ModelNameen": "T700",
                  "ModelNamear": "T700",
                  "ModelNameku": "T700",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 311,
                  "ModelNameen": "Z100",
                  "ModelNamear": "Z100",
                  "ModelNameku": "Z100",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1316,
                  "ModelNameen": "Z300",
                  "ModelNamear": "Z300",
                  "ModelNameku": "Z300",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": 67,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 93,
          "BrandNameen": "Hawtai",
          "BrandNamear": "هاوتاي",
          "BrandNameku": "هاوتای",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 92,
          "Attachment": {
              "ID": 92,
              "Url": "/img/BrandAttachments\\1606646937700.9832_Hawtai.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "Hawtai.png",
              "Size": 70280.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 982,
                  "ModelNameen": "A25",
                  "ModelNamear": "A25",
                  "ModelNameku": "A25",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 94,
          "BrandNameen": "Mitsuoka",
          "BrandNamear": "ميتسوكا",
          "BrandNameku": "میتسوکا",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 93,
          "Attachment": {
              "ID": 93,
              "Url": "/img/BrandAttachments\\1630507675471.5483_Mitsuoka.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "Mitsuoka-Motors-Logo-1024x768-copy.png",
              "Size": 26758.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1042,
                  "ModelNameen": "Galio",
                  "ModelNamear": "كاليو",
                  "ModelNameku": "گالیۆ",
                  "Sort": 0,
                  "FromYearId": 46,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 46,
                      "YearName": "1996",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 95,
          "BrandNameen": "UAZ",
          "BrandNamear": "واز",
          "BrandNameku": "واز",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 94,
          "Attachment": {
              "ID": 94,
              "Url": "/img/BrandAttachments\\1728386143243.4172_UAZ.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "1643026503457.9946_UAZ (1).png",
              "Size": 76025.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1119,
                  "ModelNameen": "Hunter",
                  "ModelNamear": "هنتر",
                  "ModelNameku": "هەنتەر",
                  "Sort": 0,
                  "FromYearId": 53,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1039,
                  "ModelNameen": "Patriot",
                  "ModelNamear": "باتريوت",
                  "ModelNameku": "پاتریۆت",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1030,
                  "ModelNameen": "Pickup",
                  "ModelNamear": "بيك اب",
                  "ModelNameku": "پیکاپ",
                  "Sort": 0,
                  "FromYearId": 1,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 1,
                      "YearName": "1951",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 96,
          "BrandNameen": "KAWEI",
          "BrandNamear": "كاويي",
          "BrandNameku": "کاوەی",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 95,
          "Attachment": {
              "ID": 95,
              "Url": "/img/BrandAttachments\\1649602110393.486_KAWEI.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "Kawei-logo-evgenius-clear-bg-200.png",
              "Size": 3557.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1044,
                  "ModelNameen": "K-150",
                  "ModelNamear": "ك-150",
                  "ModelNameku": "K-150",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1045,
                  "ModelNameen": "K1",
                  "ModelNamear": "K1",
                  "ModelNameku": "K1",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 97,
          "BrandNameen": "ZX AUTO",
          "BrandNamear": "زي إكس أوتو",
          "BrandNameku": "زی ئێکس ئەوتۆ",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 96,
          "Attachment": {
              "ID": 96,
              "Url": "/img/BrandAttachments\\1728299839451.4026_ZX AUTO.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "1649771962865.1726_ZX AUTO (2).png",
              "Size": 52452.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1046,
                  "ModelNameen": "Grand Tiger",
                  "ModelNamear": "كراتد تايكر",
                  "ModelNameku": "گراند تایگەر",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1122,
                  "ModelNameen": "Terralord",
                  "ModelNamear": "تيرالورد",
                  "ModelNameku": "تیرالۆرد",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 98,
          "BrandNameen": "Polaris",
          "BrandNamear": "بولاريس",
          "BrandNameku": "پۆلاریس",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 97,
          "Attachment": {
              "ID": 97,
              "Url": "/img/BrandAttachments\\1649936961141.3513_Polaris.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "polaris-logo-png-transparent.png",
              "Size": 46115.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1054,
                  "ModelNameen": "ProStar",
                  "ModelNamear": "برو ستار",
                  "ModelNameku": "پرۆ ستار",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1051,
                  "ModelNameen": "Ranger 570",
                  "ModelNamear": "رينجةر 570",
                  "ModelNameku": "رەینجەر 570",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1050,
                  "ModelNameen": "Ranger Crew 1000",
                  "ModelNamear": "رينجر كرو 1000",
                  "ModelNameku": "ڕەینجەر کریو ١٠٠٠",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1052,
                  "ModelNameen": "Ranger Crew 570",
                  "ModelNamear": "رينجةر كريو 570",
                  "ModelNameku": "رەینجەر کریو 570",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1048,
                  "ModelNameen": "RZR",
                  "ModelNamear": "RZR",
                  "ModelNameku": "RZR",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1053,
                  "ModelNameen": "RZR PRO XP 4 Turbo",
                  "ModelNamear": "RZR PRO XP 4 Turbo",
                  "ModelNameku": "RZR PRO XP 4 Turbo",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1156,
                  "ModelNameen": "Slingshot R",
                  "ModelNamear": "Slingshot R",
                  "ModelNameku": "Slingshot R",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 99,
          "BrandNameen": "Smart",
          "BrandNamear": "سمارت",
          "BrandNameku": "سمارت",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 98,
          "Attachment": {
              "ID": 98,
              "Url": "/img/BrandAttachments\\1650821938204.584_Smart.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "smart-logo-black-and-white.png",
              "Size": 5021.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1055,
                  "ModelNameen": "Fortwo",
                  "ModelNamear": "فورتو",
                  "ModelNameku": "فۆرتو",
                  "Sort": 0,
                  "FromYearId": 50,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 50,
                      "YearName": "2000",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1056,
                  "ModelNameen": "Smart 1",
                  "ModelNamear": "سمارت 1",
                  "ModelNameku": "سمارت 1",
                  "Sort": 0,
                  "FromYearId": 72,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 72,
                      "YearName": "2022",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 100,
          "BrandNameen": "Jetour",
          "BrandNamear": "جيتور",
          "BrandNameku": "جیتۆر",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 99,
          "Attachment": {
              "ID": 99,
              "Url": "/img/BrandAttachments\\1658155891611.9832_Jetour.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "jetour.png",
              "Size": 51842.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1162,
                  "ModelNameen": "Dashing",
                  "ModelNamear": "داشينج",
                  "ModelNameku": "داشينگ",
                  "Sort": 0,
                  "FromYearId": 72,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 72,
                      "YearName": "2022",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1232,
                  "ModelNameen": "T2",
                  "ModelNamear": "T2",
                  "ModelNameku": "T2",
                  "Sort": 0,
                  "FromYearId": 74,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 74,
                      "YearName": "2023",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1070,
                  "ModelNameen": "X70",
                  "ModelNamear": "X70",
                  "ModelNameku": "X70",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1212,
                  "ModelNameen": "X70 Plus",
                  "ModelNamear": "X70 بلس",
                  "ModelNameku": "X70 پڵەس",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1098,
                  "ModelNameen": "X90 PLUS",
                  "ModelNamear": "X90 بلس",
                  "ModelNameku": "X90 پڵەس",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 101,
          "BrandNameen": "Haima",
          "BrandNamear": "هيما",
          "BrandNameku": "هەیما",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 100,
          "Attachment": {
              "ID": 100,
              "Url": "/img/BrandAttachments\\1661438972740.713_Haima.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "haima-logo-4E443976DA-seeklogo.com.png",
              "Size": 25295.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1078,
                  "ModelNameen": "8S",
                  "ModelNamear": "8S",
                  "ModelNameku": "8S",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1213,
                  "ModelNameen": "Family",
                  "ModelNamear": "فاملي",
                  "ModelNameku": "فامیلی",
                  "Sort": 0,
                  "FromYearId": 53,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1149,
                  "ModelNameen": "S5",
                  "ModelNamear": "S5",
                  "ModelNameku": "S5",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 102,
          "BrandNameen": "Buick",
          "BrandNamear": "بويك",
          "BrandNameku": "بیوک",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 101,
          "Attachment": {
              "ID": 101,
              "Url": "/img/BrandAttachments\\1728387831893.7954_Buick.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "OIP-removebg-preview (1).png",
              "Size": 80952.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1106,
                  "ModelNameen": "Electra",
                  "ModelNamear": "إليكترا",
                  "ModelNameku": "ئەلێکترا",
                  "Sort": 0,
                  "FromYearId": 9,
                  "ToYearId": 40,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 9,
                      "YearName": "1959",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 40,
                      "YearName": "1990",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1310,
                  "ModelNameen": "Enclave",
                  "ModelNamear": "إنكليف",
                  "ModelNameku": "ئینکلیف",
                  "Sort": 0,
                  "FromYearId": 57,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 57,
                      "YearName": "2007",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1137,
                  "ModelNameen": "Encore",
                  "ModelNamear": "انكور",
                  "ModelNameku": "ئینکۆر",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1275,
                  "ModelNameen": "Encore GX",
                  "ModelNamear": "إنكور جي إكس",
                  "ModelNameku": "ئێنکۆر جی ئێکس",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1291,
                  "ModelNameen": "Envision",
                  "ModelNamear": "إنفيجن",
                  "ModelNameku": "ئینڤیشن",
                  "Sort": 0,
                  "FromYearId": 64,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 64,
                      "YearName": "2014",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1324,
                  "ModelNameen": "Envista",
                  "ModelNamear": "إنفيستا",
                  "ModelNameku": "ئینڤیستا",
                  "Sort": 0,
                  "FromYearId": 76,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 76,
                      "YearName": "2024",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1203,
                  "ModelNameen": "LaCrosse",
                  "ModelNamear": "لاكروس",
                  "ModelNameku": "لاکرۆس",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 103,
          "BrandNameen": "Bestune",
          "BrandNamear": "بيستون",
          "BrandNameku": "بێستون",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 102,
          "Attachment": {
              "ID": 102,
              "Url": "/img/BrandAttachments\\1728386340841.1829_Bestune.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "bestune-logo-600x1100-show (2).png",
              "Size": 69660.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1113,
                  "ModelNameen": "B70",
                  "ModelNamear": "B70",
                  "ModelNameku": "B70",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1183,
                  "ModelNameen": "B70S",
                  "ModelNamear": "B70S",
                  "ModelNameku": "B70S",
                  "Sort": 0,
                  "FromYearId": 52,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1185,
                  "ModelNameen": "T55",
                  "ModelNamear": "T55",
                  "ModelNameku": "T55",
                  "Sort": 0,
                  "FromYearId": 71,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 71,
                      "YearName": "2021",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1115,
                  "ModelNameen": "T77",
                  "ModelNamear": "T77",
                  "ModelNameku": "T77",
                  "Sort": 0,
                  "FromYearId": 68,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1114,
                  "ModelNameen": "T99",
                  "ModelNamear": "T99",
                  "ModelNameku": "T99",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 104,
          "BrandNameen": "Pontiac",
          "BrandNamear": "بونتياك",
          "BrandNameku": "پۆنتیاک",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 103,
          "Attachment": {
              "ID": 103,
              "Url": "/img/BrandAttachments\\1674900515188.2915_Pontiac.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "pngfind.com-buick-logo-png-2598538.png",
              "Size": 77370.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1117,
                  "ModelNameen": "Catalina",
                  "ModelNamear": "كاتالينا",
                  "ModelNameku": "کەتەلینا",
                  "Sort": 0,
                  "FromYearId": 1,
                  "ToYearId": 31,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 1,
                      "YearName": "1951",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 31,
                      "YearName": "1981",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1167,
                  "ModelNameen": "Grand Am",
                  "ModelNamear": "جراند ام",
                  "ModelNameku": "گراند ئەی ئێم",
                  "Sort": 0,
                  "FromYearId": 23,
                  "ToYearId": 55,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 23,
                      "YearName": "1973",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1304,
                  "ModelNameen": "Grand Prix",
                  "ModelNamear": "جراند بريكس",
                  "ModelNameku": "گراند پریکس",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1339,
                  "ModelNameen": "Torrent",
                  "ModelNamear": "تورنت",
                  "ModelNameku": "تۆرێنت",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": 59,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 105,
          "BrandNameen": "Hongqi",
          "BrandNamear": "هونشي",
          "BrandNameku": "هۆنگچی",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 104,
          "Attachment": {
              "ID": 104,
              "Url": "/img/BrandAttachments\\1728388126725.6724_Hongqi.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "1677760350908.2812_Hongqi-removebg-preview (1).png",
              "Size": 79700.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1132,
                  "ModelNameen": "H9",
                  "ModelNamear": "H9",
                  "ModelNameku": "H9",
                  "Sort": 0,
                  "FromYearId": 70,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 70,
                      "YearName": "2020",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 106,
          "BrandNameen": "Karry",
          "BrandNamear": "كاري",
          "BrandNameku": "کاری",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 106,
          "Attachment": {
              "ID": 106,
              "Url": "/img/BrandAttachments\\1728386004294.1104_Karry.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "1680963752267.572_Karry (1).png",
              "Size": 48559.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1143,
                  "ModelNameen": "Pickup",
                  "ModelNamear": "بيك اب",
                  "ModelNameku": "پیکاب",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1277,
                  "ModelNameen": "UU",
                  "ModelNamear": "UU",
                  "ModelNameku": "UU",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 107,
          "BrandNameen": "Lifan",
          "BrandNamear": "ليفان",
          "BrandNameku": "ليفان",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 108,
          "Attachment": {
              "ID": 108,
              "Url": "/img/BrandAttachments\\1684318934080.8694_Lifan.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "pngegg (2).png",
              "Size": 15710.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1239,
                  "ModelNameen": "320",
                  "ModelNamear": "320",
                  "ModelNameku": "320",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": 68,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1282,
                  "ModelNameen": "520",
                  "ModelNamear": "520",
                  "ModelNameku": "520",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1151,
                  "ModelNameen": "620",
                  "ModelNamear": "620",
                  "ModelNameku": "620",
                  "Sort": 0,
                  "FromYearId": 58,
                  "ToYearId": 68,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 58,
                      "YearName": "2008",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1318,
                  "ModelNameen": "Foison",
                  "ModelNamear": "فويسون",
                  "ModelNameku": "فۆیسۆن",
                  "Sort": 0,
                  "FromYearId": 61,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1171,
                  "ModelNameen": "X60",
                  "ModelNamear": "X60",
                  "ModelNameku": "X60",
                  "Sort": 0,
                  "FromYearId": 61,
                  "ToYearId": 68,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 61,
                      "YearName": "2011",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 68,
                      "YearName": "2018",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 108,
          "BrandNameen": "KAIYI",
          "BrandNamear": "كايي",
          "BrandNameku": "کای",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 109,
          "Attachment": {
              "ID": 109,
              "Url": "/img/BrandAttachments\\1689423684257.134_KAIYI.PNG",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".PNG",
              "ContentType": "image/png",
              "FileName": "cropped-kaiyi-logo.PNG",
              "Size": 42830.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1163,
                  "ModelNameen": "X3",
                  "ModelNamear": "X3",
                  "ModelNameku": "X3",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 109,
          "BrandNameen": "Saipa",
          "BrandNamear": "سايبا",
          "BrandNameku": "سایپا",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 110,
          "Attachment": {
              "ID": 110,
              "Url": "/img/BrandAttachments\\1728389259302.9685_Saipa.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "R__4_-removebg-preview.png",
              "Size": 51795.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1168,
                  "ModelNameen": "131",
                  "ModelNamear": "131",
                  "ModelNameku": "131",
                  "Sort": 0,
                  "FromYearId": 31,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 31,
                      "YearName": "1981",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1278,
                  "ModelNameen": "Ario",
                  "ModelNamear": "أريو",
                  "ModelNameku": "ئاریۆ",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1166,
                  "ModelNameen": "Tiba",
                  "ModelNamear": "طيبا",
                  "ModelNameku": "تیبا",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 111,
          "BrandNameen": "Renault Samsung Motors",
          "BrandNamear": "رينو سامسونج موتورس",
          "BrandNameku": "رینۆ سامسۆنگ مۆتۆرس",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 112,
          "Attachment": {
              "ID": 112,
              "Url": "/img/BrandAttachments\\1728386087585.0576_Renault Samsung Motors.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "1704644016432.9966_Renault Samsung Motors (1).png",
              "Size": 54470.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1199,
                  "ModelNameen": "QM6",
                  "ModelNamear": "QM6",
                  "ModelNameku": "QM6",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1271,
                  "ModelNameen": "SM3",
                  "ModelNamear": "SM3",
                  "ModelNameku": "SM3",
                  "Sort": 0,
                  "FromYearId": 52,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1201,
                  "ModelNameen": "SM6",
                  "ModelNamear": "SM6",
                  "ModelNameku": "SM6",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1237,
                  "ModelNameen": "SM7",
                  "ModelNamear": "SM7",
                  "ModelNameku": "SM7",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1234,
                  "ModelNameen": "XM3",
                  "ModelNamear": "XM3",
                  "ModelNameku": "XM3",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 112,
          "BrandNameen": "Soueast",
          "BrandNamear": "ساوايست",
          "BrandNameku": "ساوئیست",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 113,
          "Attachment": {
              "ID": 113,
              "Url": "/img/BrandAttachments\\1710698624175.4355_Soueast.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "Soueast_logo_PNG2.png",
              "Size": 14801.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1390,
                  "ModelNameen": "DX5",
                  "ModelNamear": "DX5",
                  "ModelNameku": "DX5",
                  "Sort": 0,
                  "FromYearId": 69,
                  "ToYearId": 74,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 69,
                      "YearName": "2019",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 74,
                      "YearName": "2023",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1229,
                  "ModelNameen": "DX8S",
                  "ModelNamear": "DX8S",
                  "ModelNameku": "DX8S",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 113,
          "BrandNameen": "Daewoo",
          "BrandNamear": "دايو",
          "BrandNameku": "دایۆ",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 114,
          "Attachment": {
              "ID": 114,
              "Url": "/img/BrandAttachments\\1728388672320.864_Daewoo.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "1711619542489.8008_Daewoo-removebg-preview (1).png",
              "Size": 80968.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1323,
                  "ModelNameen": "Cielo",
                  "ModelNamear": "سيلو",
                  "ModelNameku": "سیلۆ",
                  "Sort": 0,
                  "FromYearId": 44,
                  "ToYearId": 47,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 44,
                      "YearName": "1994",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 47,
                      "YearName": "1997",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1312,
                  "ModelNameen": "Espero",
                  "ModelNamear": "اسبيرو",
                  "ModelNameku": "ئێسپێرۆ",
                  "Sort": 0,
                  "FromYearId": 40,
                  "ToYearId": 49,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 40,
                      "YearName": "1990",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 49,
                      "YearName": "1999",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1361,
                  "ModelNameen": "Gentra",
                  "ModelNamear": "جينترا",
                  "ModelNameku": "جێنترا",
                  "Sort": 0,
                  "FromYearId": 55,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 55,
                      "YearName": "2005",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1295,
                  "ModelNameen": "Lacetti",
                  "ModelNamear": "لاسيتي",
                  "ModelNameku": "لەیسیتی",
                  "Sort": 0,
                  "FromYearId": 52,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 52,
                      "YearName": "2002",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1319,
                  "ModelNameen": "LeMans",
                  "ModelNamear": "ليمانس",
                  "ModelNameku": "لێمانس",
                  "Sort": 0,
                  "FromYearId": 36,
                  "ToYearId": 66,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 36,
                      "YearName": "1986",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1233,
                  "ModelNameen": "Nubira",
                  "ModelNamear": "نوبيرا",
                  "ModelNameku": "نوبیرا",
                  "Sort": 0,
                  "FromYearId": 47,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 47,
                      "YearName": "1997",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1262,
                  "ModelNameen": "Prince",
                  "ModelNamear": "برنس",
                  "ModelNameku": "پرینس",
                  "Sort": 0,
                  "FromYearId": 40,
                  "ToYearId": 48,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 40,
                      "YearName": "1990",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 48,
                      "YearName": "1998",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 114,
          "BrandNameen": "Iran Khodro",
          "BrandNamear": "إيران خودرو",
          "BrandNameku": "ئێران خودرۆ",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 115,
          "Attachment": {
              "ID": 115,
              "Url": "/img/BrandAttachments\\1714907855758.6047_Iran Khodro.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "Iran-Khodro-logo-3000x3000.png",
              "Size": 95977.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1269,
                  "ModelNameen": "Arisun",
                  "ModelNamear": "أريسون",
                  "ModelNameku": "ئەریسون",
                  "Sort": 0,
                  "FromYearId": 65,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1253,
                  "ModelNameen": "Dena",
                  "ModelNamear": "دينا",
                  "ModelNameku": "دێنا",
                  "Sort": 0,
                  "FromYearId": 66,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1256,
                  "ModelNameen": "Samand",
                  "ModelNamear": "سمند",
                  "ModelNameku": "سه مه ند",
                  "Sort": 0,
                  "FromYearId": 51,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 51,
                      "YearName": "2001",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1268,
                  "ModelNameen": "Soren",
                  "ModelNamear": "سورين",
                  "ModelNameku": "سۆرێن",
                  "Sort": 0,
                  "FromYearId": 62,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 62,
                      "YearName": "2012",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 115,
          "BrandNameen": "Ineos",
          "BrandNamear": "إنيوس",
          "BrandNameku": "ئینیۆس",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 116,
          "Attachment": {
              "ID": 116,
              "Url": "/img/BrandAttachments\\1716130051679.2295_Ineos.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "ineos-logo.png",
              "Size": 11606.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1259,
                  "ModelNameen": "Grenadier",
                  "ModelNamear": "غرينادير",
                  "ModelNameku": "گرینادیەر",
                  "Sort": 0,
                  "FromYearId": 72,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 72,
                      "YearName": "2022",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 117,
          "BrandNameen": "GAZ",
          "BrandNamear": "GAZ",
          "BrandNameku": "GAZ",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 118,
          "Attachment": {
              "ID": 118,
              "Url": "/img/BrandAttachments\\1719221785240.4197_GAZ.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "GAZ_2015_logo (1).png",
              "Size": 20507.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1284,
                  "ModelNameen": "Volga",
                  "ModelNamear": "فولكا",
                  "ModelNameku": "ڤۆڵگا",
                  "Sort": 0,
                  "FromYearId": null,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": null,
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 118,
          "BrandNameen": "Avatr",
          "BrandNamear": "أفاتر",
          "BrandNameku": "ئاڤاتر",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 119,
          "Attachment": {
              "ID": 119,
              "Url": "/img/BrandAttachments\\1723037110015.2932_Avatr.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "avatr new.png",
              "Size": 28379.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1308,
                  "ModelNameen": "12",
                  "ModelNamear": "12",
                  "ModelNameku": "12",
                  "Sort": 0,
                  "FromYearId": 74,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 74,
                      "YearName": "2023",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 119,
          "BrandNameen": "XEV",
          "BrandNamear": "إكس إي في",
          "BrandNameku": "ئێکس ئی ڤی",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 120,
          "Attachment": {
              "ID": 120,
              "Url": "/img/BrandAttachments\\1725200472146.0725_XEV.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "XEV-logo-.png",
              "Size": 46852.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1327,
                  "ModelNameen": "Yoyo Pro",
                  "ModelNamear": "يويو برو",
                  "ModelNameku": "یۆیۆ پڕۆ",
                  "Sort": 0,
                  "FromYearId": 71,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 71,
                      "YearName": "2021",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 120,
          "BrandNameen": "Hafei",
          "BrandNamear": "هافي",
          "BrandNameku": "هافێی",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 121,
          "Attachment": {
              "ID": 121,
              "Url": "/img/BrandAttachments\\1728898873711.9043_Hafei.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "Hafei_logo.png",
              "Size": 56469.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1367,
                  "ModelNameen": "Minyi pickup",
                  "ModelNamear": "مينيي حمل",
                  "ModelNameku": "مینیی بارهەڵگر",
                  "Sort": 0,
                  "FromYearId": 53,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 53,
                      "YearName": "2003",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 121,
          "BrandNameen": "Jonway",
          "BrandNamear": "جونواي",
          "BrandNameku": "جۆنوەی",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 122,
          "Attachment": {
              "ID": 122,
              "Url": "/img/BrandAttachments\\1729936858251.6787_Jonway.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "Jonway_logo-removebg-preview.png",
              "Size": 93449.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1378,
                  "ModelNameen": "A380",
                  "ModelNamear": "A380",
                  "ModelNameku": "A380",
                  "Sort": 0,
                  "FromYearId": 56,
                  "ToYearId": 65,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 56,
                      "YearName": "2006",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 122,
          "BrandNameen": "Jinbei",
          "BrandNamear": "جينبي",
          "BrandNameku": "جینبەی",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 123,
          "Attachment": {
              "ID": 123,
              "Url": "/img/BrandAttachments\\1729937497930.891_Jinbei.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "Jinbei-removebg-preview.png",
              "Size": 52478.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1379,
                  "ModelNameen": "Haise",
                  "ModelNamear": "هايز",
                  "ModelNameku": "هەیس",
                  "Sort": 0,
                  "FromYearId": 41,
                  "ToYearId": 65,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 41,
                      "YearName": "1991",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 65,
                      "YearName": "2015",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              },
              {
                  "ID": 1395,
                  "ModelNameen": "Hercules",
                  "ModelNamear": "هرقل",
                  "ModelNameku": "هێرقل",
                  "Sort": 0,
                  "FromYearId": 59,
                  "ToYearId": 66,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 59,
                      "YearName": "2009",
                      "Sort": 0
                  },
                  "ToYear": {
                      "ID": 66,
                      "YearName": "2016",
                      "Sort": 0
                  },
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 123,
          "BrandNameen": "Higer",
          "BrandNamear": "هايجر",
          "BrandNameku": "هیگەر",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": 0,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 124,
          "Attachment": {
              "ID": 124,
              "Url": "/img/BrandAttachments\\1730718611923.032_Higer.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "Higer_logo.png",
              "Size": 47970.0
          },
          "Sort": 0,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1387,
                  "ModelNameen": "H5F",
                  "ModelNamear": "H5F",
                  "ModelNameku": "H5F",
                  "Sort": 0,
                  "FromYearId": 54,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 54,
                      "YearName": "2004",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      },
      {
          "ID": 110,
          "BrandNameen": "LYNK & CO",
          "BrandNamear": "لينك اند كو",
          "BrandNameku": "لینک ئاند کۆ",
          "CategoryId": 1,
          "Category": {
              "ID": 1,
              "ShowRoomCategoryen": "Cars",
              "ShowRoomCategoryar": "سيارات",
              "ShowRoomCategoryku": "ئوتۆمبێل",
              "Sort": -67,
              "Brands": null,
              "Total": 0
          },
          "BrandAttachmentId": 111,
          "Attachment": {
              "ID": 111,
              "Url": "/img/BrandAttachments\\1693482458725.6074_LYNK & CO.png",
              "ThumbUrl": null,
              "DetailUrl": null,
              "CardUrl": null,
              "OrginalUrl": null,
              "Extension": ".png",
              "ContentType": "image/png",
              "FileName": "Lynk-Co-Logo-PNG2.png",
              "Size": 11750.0
          },
          "Sort": -67,
          "Descriptionen": null,
          "Descriptionar": null,
          "Descriptionku": null,
          "Models": [
              {
                  "ID": 1169,
                  "ModelNameen": "01",
                  "ModelNamear": "01",
                  "ModelNameku": "01",
                  "Sort": 0,
                  "FromYearId": 67,
                  "ToYearId": null,
                  "SegmentId": null,
                  "Segment": null,
                  "FromYear": {
                      "ID": 67,
                      "YearName": "2017",
                      "Sort": 0
                  },
                  "ToYear": null,
                  "ModelSFXes": null,
                  "Years": null,
                  "Brand": null
              }
          ]
      }
  ]
  console.log(brands)


  }, [location]);

  const onError = (errors) => {
    console.log(errors);
  };

  if (success == true) {
    const result = chasis?.result?.report;
    let updatedResult = dataInFormatCheck(result);
    // setCarData(updatedResult);
    // setStep(3);
    if (result) {
      navigate(paths.singleCarCheck, { state: { data: updatedResult } });
    }
    dispatch(clearStatus());
  }

  if (error) {
    const err = error;
    dispatch(clearStatus());
    navigate(paths.singleCarCheck, {
      state: { data: { found: false, msg: err } },
    });
  }

  if (location.state) {
    return (
      <div className="min-vh-100 d-flex justify-content-center mt-5 pt-5">
        <div className="d-flex justify-content-center">
          <div class="spinner-grow" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <CheckMyCar step={step} setStep={setStep} setVin={setVin} vin={vin} />
      <JsonLDSchemaHandler data={ldSchemas?.checkYourCars} />
    </>
  );
}

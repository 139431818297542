import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ModalButton from "../../Tools/ModalButton";
import ListItem from "../../Tools/ListItem";
import { useTranslation } from "react-i18next";
import { headerData } from "../../../data/content";
import {
  changeLanguage,
  clearToken,
  clearUser,
} from "../../../Reducer/authSlice";
import LangSelectOptions from "../../Tools/LangSelectOptions";
import $ from "jquery";
import { Icon } from "@iconify/react";
import {
  // Badge,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { choiceSelection } from "../../../Reducer/choiceSelection";
import { changePreferredLanguage } from "../../../Reducer/api";
import { paths } from "../../../utils/paths";
import { Badge } from "@mui/material";
import { checkProfileComplete } from "../../../utils/constants";
import CountdownTimer from "../../Tools/Timer/timer";
export default function Header() {
  const dispatch = useDispatch();
  const cl = useSelector((state) => state.auth.language);
  const navigate = useNavigate();
  const location = useLocation();
  let mainClass = "main-header";
  switch (location.pathname) {
    case paths.signup:
      mainClass = "main-header headerlogin";
      break;
    case paths.login:
      mainClass = "main-header headerlogin";
      break;
    case paths.basicDetails:
      mainClass = "main-header headerlogin";
      break;
  }

  const { t, i18n } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(i18n.language);
  const token = useSelector((state) => state.auth.token);
  const udata = useSelector((state) => state.auth);
  const profileData = useSelector((state) => state.myprofile.profilearr);
  const [accountDelisted, setAccountDelisted] = useState(false);
  const datalist = headerData;
  const [openInApp, setOpenInApp] = useState(true);
  const [timerActive, setTimerActive] = useState(false);

  document.body.classList.remove(...document.body.classList);
  document.body.classList.add(i18n.language);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, [location.pathname]);

  useEffect(() => {
    if (profileData && profileData?.allowedToLogin === false) {
      setAccountDelisted(true);
      setTimerActive(true);
      localStorage.removeItem("token");
    } else {
      setAccountDelisted(false);
    }
  }, [profileData]);

  const afterCountdown = () => {
    handlelogout();
  };

  const handleOptionChange = (e) => {
    window.location.reload();
    setSelectedOption(e.target.value);
    $("body").removeClass("side-inherit");
    $(".mob-lang").addClass("d-none");
    $(".navbar-collapse").removeClass("show");

    dispatch(changeLanguage(e.target.value));
    i18n.changeLanguage(e.target.value);
    document.body.classList.remove(...document.body.classList);
    document.body.classList.add(e.target.value);
  };

  const handleListChange = (value) => {
    if (token) {
      changePreferredLanguage({ value: value }).then((res) => {
        if (res.status) {
          navigate(paths.home);
          window.location.reload();
        }
      });
    } else {
      window.location.reload();
    }
    setSelectedOption(value);
    dispatch(changeLanguage(value));
    i18n.changeLanguage(value);
    $("body").removeClass("side-inherit");
    $(".mob-lang").addClass("d-none");
    $(".navbar-collapse").removeClass("show");
    document.body.classList.remove(...document.body.classList);
    document.body.classList.add(value);
  };

  useEffect(() => {
    const langT = localStorage.getItem("cl");
    console.log(langT, i18n.language);
    if (langT) {
      if (langT !== i18n.language) handleListChange(langT);
    }
  }, [localStorage.getItem("cl")]);

  const handlelogout = () => {
    dispatch(clearUser());
    dispatch(clearToken());
    navigate(paths.home);
    remove();
    window.location.reload();
    console.log("t-token", token);
  };

  const remove = () => {
    var element = document.getElementById("navbarCollapse");
    element.classList.remove("show");
  };
  const optionList = [
    { name: "English", value: "English" },
    { name: "عربي", value: "Arabic" },
    { name: "كوردى", value: "Kurdish" },
  ];

  const [modal, setModal] = useState(process.env.NODE_ENV === "development");
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (localStorage.getItem("boloBallu")) {
      setModal(false);
    } else {
      setModal(true);
    }
  }, []);

  return (
    <>
      {!accountDelisted && openInApp && (
        <div
          className="container-fluid position-sticky top-0 d-sm-none d-flex justify-content-between align-items-center text-light"
          style={{
            backgroundColor: "#4c698f",
            zIndex: 999999,
            minHeight: "60px",
          }}
        >
          <div className="d-flex align-items-center gap-3">
            <span
              onClick={(e) => {
                // localStorage.setItem("openInApp", "opened");
                setOpenInApp(false);
              }}
            >
              <Icon
                icon="line-md:close"
                fontSize={20}
                style={{ color: "white" }}
              />
            </span>
            <span className="rounded-2 bg-light p-1 d-block">
              <img
                src={"/assets/images/tab-icon.png"}
                height={30}
                width={30}
                alt=""
              />
            </span>
            <span className="fw-semibold d-flex flex-column">
              <span style={{ fontSize: "16px" }}>{t("Ako Sayara App")}</span>
              {/* <span style={{ fontSize: '13px' }}>133k downloads</span> */}
            </span>
          </div>
          <div>
            <button
              className="btn btn-outline-light"
              onClick={(e) => {
                // localStorage.setItem("openInApp", "opened");
                // setOpenInApp(false)
                window.location.href = paths.storeDetect;
              }}
            >
              {t("Open")}
            </button>
          </div>
        </div>
      )}

      {accountDelisted && (
        <div
          className="container-fluid position-sticky top-0 d-flex justify-content-between align-items-center text-light"
          style={{
            backgroundColor: "#E44E4E",
            zIndex: 999999,
            minHeight: "60px",
          }}
        >
          <div className="d-flex align-items-center gap-3 w-75">
            {/* <span className="rounded-2 bg-light p-1 d-block">
              <img
                src={"/assets/images/tab-icon.png"}
                height={30}
                width={30}
                alt=""
              />
            </span> */}
            <span className="fw-semibold d-flex flex-column">
              <span className="">
                {t(
                  "Your account has been delisted from the platform we will redirect you to login"
                )}
              </span>
            </span>
          </div>
          <div className="position-relative" style={{ width: "100px" }}>
            <Icon
              icon="line-md:loading-twotone-loop"
              fontSize={48}
              style={{
                color: "white",
                position: "absolute",
                top: "-23px",
                [i18n.language === "English" ? "right" : "left"]: "10px",
              }}
            />
            <div
              style={{
                position: "absolute",
                top: "-10px",
                [i18n.language === "English" ? "right" : "left"]: "24px",
              }}
            >
              <CountdownTimer
                initialSeconds={10}
                setIsActive={setTimerActive}
                isActive={timerActive}
                taskAfterCountdown={afterCountdown}
              />
            </div>
          </div>
        </div>
      )}

      <header class={mainClass}>
        {process.env.REACT_APP_PROCESS_MODE === "development" && (
          <div>
            {modal ? (
              <Modal
                isOpen={modal}
                fullscreen
                centered
                style={{ zIndex: 9999 }}
              >
                <ModalBody>
                  <div className="d-flex justify-content-evenly align-items-center h-100">
                    <div className="d-flex justify-content-evenly align-items-center flex-column">
                      <Icon icon="tabler:password-user" fontSize={100} />
                      <h5
                        className="my-1"
                        style={{
                          fontWeight: "800",
                          fontSize: "35px",
                          textAlign: "center",
                        }}
                      >
                        Please enter passcode to continue
                      </h5>
                      <input
                        placeholder="xxxxxxxxxx"
                        type="password"
                        style={{
                          outline: "none",
                          borderRadius: "25px",
                          border: "1px solid grey",
                          height: "45px",
                          paddingInline: "10px",
                          fontSize: "20px",
                          marginTop: "10px",
                          textAlign: "center",
                        }}
                        onChange={(e) => setInputValue(e.target.value)}
                      ></input>
                      <div className="mt-3">
                        <Button
                          color="primary"
                          style={{
                            backgroundColor: "#334660",
                            border: "none",
                            outline: "none",
                            borderRadius: "16px",
                            fontWeight: "500",
                          }}
                          onClick={() => {
                            if (inputValue === choiceSelection) {
                              localStorage.setItem("boloBallu", "true");
                              setModal(false);
                            }
                          }}
                        >
                          Continue
                        </Button>{" "}
                      </div>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
            ) : null}
          </div>
        )}

        <div class="container">
          <nav class="navbar navbar-expand-md">
            <NavLink className="logo" to={paths.home}>
              <img src={`../assets/images/${t("MainIcon")}`} />
            </NavLink>
            <ModalButton
              type={"button"}
              styleClass={"navbar-toggler"}
              toggle={"collapse"}
              target={"#navbarCollapse"}
              controls={"navbarCollapse"}
              expanded={"expanded"}
              label={"Toggle navigation"}
              title={
                <>
                  <i
                    onClick={() => {
                      $("body").addClass("side-inherit");
                    }}
                    class="fa-solid fa-bars"
                  ></i>
                  <span class="d-none">{t("Menu")}</span>
                </>
              }
            />
            <div class="collapse navbar-collapse " id="navbarCollapse">
              <ul class="navbar-nav me-auto mb-2 mb-md-0">
                <ListItem
                  style={"nav-item d-block d-lg-none"}
                  innerhtmlType={"html"}
                  value={
                    <>
                      <NavLink
                        className="logo d-inline-block mt-1"
                        to={paths.home}
                      >
                        <img src={`../assets/images/${t("MainIcon")}`} />
                      </NavLink>
                      <span class="navbar-toggler" onClick={remove}>
                        {" "}
                        <img src="../assets/images/Close.png" />
                      </span>
                    </>
                  }
                ></ListItem>
                {datalist.map((item, key) => {
                  return (
                    <ListItem
                      key={key}
                      listClick={() => remove()}
                      value={t(item.name)}
                      style={`nav-item ${item.segment}`}
                      segment={item.segment}
                      innerhtmlType={"link"}
                      inlineStyle={{ color: "red !important" }}
                    />
                  );
                })}
                <ListItem
                  key={10}
                  btnType={"button"}
                  style={"lang-btn"}
                  buttonTitle={t("Select Language", {
                    lng:
                      i18n.language === "English"
                        ? "Arabic"
                        : i18n.language === "Arabic"
                        ? "Kurdish"
                        : "English",
                  })}
                  onClick={() => {
                    $(".mob-lang").removeClass("d-none");
                  }}
                  innerhtmlType={"button"}
                />
                <div className="mob-lang d-none">
                  <h5>
                    <img
                      src="../assets/images/Close.png"
                      onClick={() => {
                        $(".mob-lang").addClass("d-none");
                      }}
                    />
                    {t("Select Language")}
                  </h5>
                  {/* Mobile view */}
                  <ul>
                    {optionList.map((item, key) => {
                      return (
                        <ListItem
                          key={key}
                          value={item.name}
                          listClick={() => {
                            handleListChange(item.value);
                          }}
                          style={`${
                            item.value == selectedOption
                              ? item.value + " active"
                              : item.value
                          }`}
                          innerhtmlType={"html"}
                        />
                      );
                    })}
                  </ul>
                </div>
                {token && (
                  <ListItem
                    key={6}
                    style="nav-item d-block d-lg-none"
                    innerhtmlType={"html"}
                    value={
                      <NavLink
                        className="nav-link "
                        to={paths.myVehicles}
                        onClick={() => remove()}
                      >
                        <div
                          className={` position-absolute ${
                            i18n.language === "English" ? "end" : "start"
                          }-0 px-2`}
                        >
                          <Badge
                            badgeContent={profileData?.unreadMessages}
                            showZero={false}
                            style={{ margin: "0px 6px 3px 6px" }}
                            color="error"
                            overlap="circular"
                          ></Badge>
                        </div>
                        {t("My Account")}
                      </NavLink>
                    }
                  />
                )}
                {token && (
                  <ListItem
                    key={6}
                    style="nav-item d-block d-lg-none"
                    innerhtmlType={"html"}
                    value={
                      <NavLink
                        className="nav-link red"
                        to={paths.login}
                        onClick={() => handlelogout()}
                      >
                        {" "}
                        {t("Logout")}
                      </NavLink>
                    }
                  />
                )}
                {token == "" && (
                  <ListItem
                    key={6}
                    style="nav-item d-block d-lg-none"
                    value={
                      !token && (
                        <NavLink
                          className="nav-link red"
                          to={paths.login}
                          onClick={() => remove()}
                        >
                          {t("Login")}
                        </NavLink>
                      )
                    }
                    innerhtmlType={"html"}
                  />
                )}
              </ul>
            </div>
            <div class="log">
              <NavLink className="Saved fw-semibold" to={paths.saved}>
                {/* <img
                src={`../assets/images/${
                  location.pathname === paths.saved
                    ? "red_fill.png"
                    : "red_unfill.png"
                }`}
                style={{ margin: "0px 6px 3px 6px" }}
                className="heartIcon"
                alt="heart"
              /> */}
                <Icon
                  icon={`streamline:heart${
                    location.pathname === paths.saved ? "-solid" : ""
                  }`}
                  fontSize={18}
                  style={{ color: "#ea1933", margin: "0px 5px 3px 5px" }}
                />
                {t("Saved")}
              </NavLink>
              {token && token != "" ? (
                <NavLink className="login" to={paths.myVehicles}>
                  <span className=" position-relative">
                    {/* {profileData?.unreadMessages &&
                  profileData?.unreadMessages > 0 ? (
                  ) : null} */}
                    <Badge
                      badgeContent={
                        checkProfileComplete(profileData, true) ? 0 : 1
                      }
                      showZero={false}
                      style={{ margin: "0px 6px 3px 6px" }}
                      color="error"
                      overlap="circular"
                      variant="dot"
                    >
                      {profileData?.profilePicture ? (
                        <img
                          src={profileData?.profilePicture}
                          className="rounded-circle"
                          style={{ height: "30px" }}
                          alt=""
                        />
                      ) : (
                        <Icon
                          icon={`${
                            location.pathname.includes(paths.myAccount)
                              ? "carbon:user-avatar-filled"
                              : "carbon:user-avatar-filled"
                          }`}
                          fontSize={20}
                          style={{
                            color: location.pathname.includes(paths.myAccount)
                              ? "#4C7EE8"
                              : "#646464",
                          }}
                        />
                      )}
                    </Badge>

                    {t("My Account")}
                  </span>
                </NavLink>
              ) : (
                <NavLink className="login" to={paths.login}>
                  <img src="../assets/images/user.png" alt="user" />
                  {t("Login")}
                </NavLink>
              )}
              {/* Web view */}
              <a className="login lang">
                <img src="../assets/images/gloab_alt.png" alt="globe-alt" />
                <LangSelectOptions
                  onChange={(e) => handleListChange(e.target.value)}
                  selectedOption={selectedOption}
                  values={optionList}
                />
              </a>
            </div>
            <div class="log mob d-none">
              <NavLink className="Saved" to={paths.sellYourCar}>
                <Icon
                  icon="cryptocurrency:usd"
                  fontSize={25}
                  color={
                    location.pathname === paths.sellYourCar
                      ? "#6cde07"
                      : "#00000094"
                  }
                  style={{ marginBottom: "-10px" }}
                />
                <span>{t("Sell")}</span>
              </NavLink>
              <NavLink className="logo" to={paths.home}>
                <img src={`../assets/images/${t("MoboIcon")}`} />
              </NavLink>
              <NavLink className="Saved" to={paths.saved}>
                <img
                  src={`../assets/images/${
                    location.pathname === paths.saved
                      ? "red_fill.png"
                      : "red_unfill.png"
                  }`}
                  className="heartIcon2"
                  alt="heart"
                />
                <span>{t("Saved")}</span>
              </NavLink>
              <NavLink
                className="login"
                to={paths.advancedSearch}
                state={{
                  selectedOption:
                    location.pathname === paths.home ? "Cars" : "Rentals",
                }}
                style={{ marginTop: "-4px" }}
              >
                {/* <img src="../assets/images/search.png" alt="user" /> */}
                <Icon
                  icon={`ph:magnifying-glass${
                    location.pathname === paths.advancedSearch ? "-fill" : ""
                  }`}
                  fontSize={30}
                  color={
                    location.pathname === paths.advancedSearch ? "#4C7EE8" : ""
                  }
                />
                <span>{t("Search")}</span>
              </NavLink>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}

import { t } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import { paths } from "../../../utils/paths";
import { NavLink } from "react-router-dom";
import { faq } from "../FaqPage/FaqPage";
import { Icon } from "@iconify/react/dist/iconify.js";

function FaqSection() {
  const { t, i18n } = useTranslation();
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";

  const [accordion, setAccordion] = useState([]);
  const toggleAccordion = (e) => {
    // if (accordion.includes(e)) {
    //   setAccordion([...accordion.filter((v) => v !== e)]);
    // } else {
    //   setAccordion([...accordion, e]);
    // }
    if (e === accordion[0]) {
      setAccordion([]);
    } else {
      setAccordion([e]);
    }
  };
  const setTitle = (i) => {
    switch (i) {
      case 0:
        return t("Basic Details");
      case 1:
        return t("Specifications");
      case 2:
        return t("Features");
    }
  };

  return (
    <section className="place_an">
      <div className="container mb-5 faq">
        <div
          className="main_set row rounded-md-2 shadow-sm"
          style={{ backgroundColor: "#334660", height: "fit-content" }}
        >
          <div className="col-md-5 px-0 position-relative">
            <div
              className="text-light faq-heading"
              style={{ padding: "30px 30px 0px 30px" }}
            >
              <h3>{t("FAQ")}</h3>
              <h5 className="text-light">
                {t("Here are some of the frequently asked questions")}
              </h5>
              <div
                style={{
                  position: "",
                  [lang === "en" ? "right" : "left"]: "120px",
                  bottom: "40px",
                }}
                className="d-flex mt-4"
              >
                <a
                  className={"text-light fs-6 d-none d-md-block mt-5 w-75"}
                  href={paths.faqPage}
                >
                  <span style={{ boxShadow: "0px 2px 0px 0px white" }}>
                    {t("View all questions")}
                  </span>
                </a>
                <div className="d-flex justify-content-center w-100">
                  <Icon
                    className="d-md-block d-none "
                    icon="f7:question-diamond-fill"
                    fontSize={150}
                  />
                  <Icon
                    className="d-block d-md-none "
                    icon="f7:question-diamond-fill"
                    fontSize={90}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 p-3" style={{ height: "unset" }}>
            <Accordion open={accordion} toggle={toggleAccordion}>
              {faq &&
                faq.slice(0, 5)?.map((item, index) => {
                  return (
                    <AccordionItem className="rounded-2 m-2 outline-none">
                      <AccordionHeader
                        targetId={index + 1}
                        style={{ width: "100%", borderRadius: "20px" }}
                      >
                        <span style={{ fontWeight: 600 }}>
                          {item?.que?.[lang]}
                        </span>
                      </AccordionHeader>
                      <AccordionBody accordionId={index + 1}>
                        <div style={{ fontSize: "16px" }}>
                          {item?.ans?.[lang]}
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                  );
                })}
            </Accordion>
          </div>
          <a
            className={"text-light d-block d-md-none fs-6 text-center mb-4"}
            href={paths.faqPage}
          >
            <span style={{ boxShadow: "0px 2px 0px 0px white" }}>
              {t("View all questions")}
            </span>
          </a>
        </div>
      </div>
    </section>
  );
}

export default FaqSection;

import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { t } from "i18next";

const CookieConsent = () => {
  const [moreOptions, setMoreOptions] = useState(false);
  const [modal, setModal] = useState({ show: false });
  const closeModal = () => {
    setModal({ show: false, data: null });
  };
  const openModal = (data = {}) => {
    setModal({ show: true, data: data });
  };
  const [preferences, setPreferences] = useState({
    analytics: true,
    socialMedia: true,
    advertising: true,
  });

  useEffect(() => {
    const consent = Cookies.get("cookieConsent");
    if (!consent) {
      openModal();
    } else {
      const storedPreferences = JSON.parse(consent);
      setPreferences(storedPreferences);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set("cookieConsent", JSON.stringify(preferences), { expires: 365 });
    closeModal();
  };

  const preferencesData = [
    {
      name: "analytics",
      title: t("Allow Analytics Cookies"),
      description: t(`We may allow third parties to use analytics cookies to understand how you use our websites so we can make them better and the third parties can develop and improve their products, which they may use on websites that are not owned or operated by us.`),
    },
    {
      name: "socialMedia",
      title: t("Allow Social Media Cookies"),
      description:
        t(`We may use social media cookies to show you content based on your social media profiles and activity on our platform. They are used to connect your activity on our website to your social media profiles so the content you see on our website and on social media will better reflect your interests.`),
    },
    {
      name: "advertising",
      title: t("Allow Advertising Cookies"),
      description:
        t(`Enable the use of cookies for making advertising more relevant and to support the sourcing of high-quality content on this site. If you do not allow this use, then ads shown to you may be less relevant.`),
    },
  ];

  return (
    <div>
      <Modal
        isOpen={modal.show}
        // toggle={closeModal}
        size="lg"
        centered
        style={{ position: "relative" }}
      >
        <ModalHeader
          toggle={closeModal}
          className="border-0 px-4 pt-4 pb-0"
          // style={{ alignSelf: "end" }}
          style={{ color: "#324760" }}
        >
          {moreOptions ? (
            <h4>Manage cookie preferences</h4>
          ) : (
            <img height={35} src={`../assets/images/${t("MainIcon")}`} />
          )}
        </ModalHeader>
        <ModalBody className="p-4" style={{ color: "#324760" }}>
          {moreOptions ? (
            <>
              {preferencesData.map((item, key) => {
                return (
                  <div
                    key={key}
                    className="shadow-sm rounded-1 p-2 mb-2"
                    style={{ minHeight: "120px" }}
                  >
                    <label
                      htmlFor={`switchCookie${item?.name}`}
                      className="d-flex justify-content-between mb-1 fw-semibold fs-6"
                    >
                      <div>{item?.title}</div>
                      <FormGroup switch style={{ paddingInline: "2.5em" }}>
                        <Input
                          id={`switchCookie${item?.name}`}
                          type="switch"
                          onChange={(e) =>
                            setPreferences({
                              ...preferences,
                              [item.name]: !preferences[item.name],
                            })
                          }
                          checked={preferences[item?.name]}
                          role="switch"
                        />
                      </FormGroup>
                    </label>
                    <p className="mb-0">{item?.description}</p>
                  </div>
                );
              })}
              <div className="mt-3 d-flex justify-content-end">
                <Button
                  className="themeButton"
                  disabled={
                    !preferences.analytics &&
                    !preferences?.advertising &&
                    !preferences?.socialMedia
                  }
                  style={{
                    fontSize: "15px",
                    height: "36px",
                    backgroundColor: "#324760",
                  }}
                  onClick={() => handleAccept()}
                >
                  {t("Save settings")}
                </Button>
              </div>
            </>
          ) : (
            <>
              <h6 className="mb-4">
                {t(
                  `Our platform uses cookies and similar technologies to deliver, maintain and improve our services and ads. If you agree, we will use this data for ads personalisation and associated analytics.`
                )}
              </h6>
              <h6 className="my-4">
                {t(
                  `You can select 'Accept' to consent to these uses, 'Reject' to decline these uses or click on 'More options' to review your options.`
                )}
              </h6>
              <div className="row">
                <div className="mb-3 col-lg-6 d-flex justify-content-center align-items-center ">
                  <h6
                    className="text-decoration-underline"
                    style={{ fontSize: "17px", color: "#4c7ee8" }}
                  >
                    <a onClick={(e) => setMoreOptions(true)}>
                      {t("View more options")}
                    </a>
                  </h6>
                </div>
                <div className="mb-3 col-lg-6 d-flex justify-content-around">
                  <Button
                    className="themeButton"
                    style={{
                      fontSize: "15px",
                      height: "36px",
                      backgroundColor: "#324760",
                    }}
                    onClick={() => closeModal()}
                  >
                    {t("Reject")}
                  </Button>
                  <Button
                    className="themeButton"
                    style={{
                      fontSize: "15px",
                      height: "36px",
                      backgroundColor: "#324760",
                    }}
                    onClick={() => handleAccept()}
                  >
                    {t("Accept")}
                  </Button>
                </div>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>

      {/* <h3>Manage Cookie Preferences</h3>
      <div style={styles.toggleContainer}>
        <label>
          <input
            type="checkbox"
            checked={preferences.analytics}
            onChange={() => handleToggle("analytics")}
          />
          Allow Analytics Cookies
        </label>
      </div>
      <div style={styles.toggleContainer}>
        <label>
          <input
            type="checkbox"
            checked={preferences.socialMedia}
            onChange={() => handleToggle("socialMedia")}
          />
          Allow Social Media Cookies
        </label>
      </div>
      <div style={styles.toggleContainer}>
        <label>
          <input
            type="checkbox"
            checked={preferences.advertising}
            onChange={() => handleToggle("advertising")}
          />
          Allow Advertising Cookies
        </label>
      </div>
      <button onClick={handleAccept}>
        Save Preferences
      </button> */}
    </div>
  );
};

export default CookieConsent;

import React from "react";
import CompareItem from "./CompareItem";
import { useNavigate } from "react-router-dom";
import Button from "../Button";
import { useDispatch } from "react-redux";
import { setCars } from "../../../Reducer/authSlice";
import { paths } from "../../../utils/paths";
export default function CompareBox({
  data,
  onClick,
  mainTitle,
  subTitle,
  buttonTitle,
  onChange
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = (index) => {
    // setlistOfChecked([
    //   ...listOfChecked.slice(0, index),
    //   ...listOfChecked.slice(index + 1, listOfChecked.length),
    // ]);
    onChange(data?.filter((v, i) => i !== index));
  };
  return (
    <>
      <div class="main_ehicles">
        <div class="Vehicles mb-5">
          <h2 class="d-sm-none d-block">{mainTitle}</h2>
          <h6 class="text-center mb-4">{subTitle}</h6>
          <div class="row pb-4">
            {data.length > 0 &&
              data.map((item, index) => {
                return (
                  <CompareItem
                    onChange={() => handleClick(index)}
                    style={"col-6 col-sm-6 col-md-3"}
                    key={index}
                    item={item}
                  />
                );
              })}
          </div>
          {data.length > 1 && (
            <div class="text-center">
              <div class="d-inline-flex" defaultStyle="margin-bottom: -42px;">
                <Button
                  style="Compare"
                  title={buttonTitle}
                  onClick={() => {
                    dispatch(setCars(data));
                    navigate(paths.comparing, {state: {cars: data}});
                  }}
                />
                <a onClick={() => onClick()} class="close ms-2">
                  <i class="fa-solid fa-xmark"></i>
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

import React, { useRef } from "react";
import Search from "../Search";
import RentalSearch from "../RentalSearch";
import ListItem from "../../Tools/ListItem";
import { useState } from "react";
import { t } from "i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  getAllFiltersData,
  getSelectedFilters,
} from "../../../utils/constants";
import { useEffect } from "react";
import { getAllModels, getModels } from "../../../Reducer/api";
import JsonLDSchemaHandler from "../../Tools/JSONLDSchemaUtil";
import { ldSchemas } from "../../../utils/json-schemas";

export default function AdvancedSearch() {
  const location = useLocation();

  const [selectedOption, setSelectedOption] = useState(
    location?.state?.selectedOption ? location?.state?.selectedOption : "Cars"
  );
  const [params] = useSearchParams();
  const [data, setData] = useState(null);
  const defaultValues = {
    city: [],
    make: "",
    model: "",
    price: {
      min: "",
      max: "",
      finance: false,
    },
    rentType: "daily",
    year: {
      min: "",
      max: "",
    },
    km: {
      min: "",
      max: "",
    },
    regional: [],
    condition: [],
    paint: [],
    colour: [],
    gearbox: [],
    fuel: [],
    bodyType: [],
    size: {
      min: "",
      max: "",
    },
    power: {
      min: "",
      max: "",
    },
    cylinders: {
      min: "",
      max: "",
    },
    doors: {
      min: "",
      max: "",
    },
    seats: {
      min: "",
      max: "",
    },
    brandNew: params.get("brandNew") == 1 ? true : false,
    ...location?.state?.filters || {}
  };
  const [allFilters, setAllFilters] = useState(defaultValues);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const filtersRef = useRef(allFilters);

  useEffect(() => {
    const type = selectedOption === "Cars" ? "normal" : "rental";
    getAllFiltersData(
      type,
      "",
      allFilters,
      getSelectedFilters(
        selectedFilters,
        setSelectedFilters,
        allFilters,
        filtersRef
      )
    ).then((res) => {
      console.log(res);
      setData(res);
    });
    // setAllFilters(defaultValues);
    // resetModels();
  }, [selectedOption, allFilters]);

  const handleClick = (e) => {
    setAllFilters(defaultValues);
  };

  useEffect(() => {
    console.log(allFilters);
  }, [allFilters]);

  const [models, setModels] = useState([]);

  useEffect(() => {
    resetModels();
    // if (allFilters?.make) {
    getModels({ makeId: allFilters?.make, live: true }).then((res) => {
      res = res?.map((v) => ({ label: v, value: v }));
      setModels(res);
    });
    // }
    // else {
    //   getAllModels().then((res) => {
    //     res = res?.map((v) => ({ label: v, value: v }));
    //     setModels(res);
    //   });
    // }
  }, [allFilters?.make]);

  const resetModels = () => {
    setModels([]);
    setAllFilters({ ...allFilters, model: "" });
  };

  const skip = [t("Any"), "$0", t("Brand new")];
  const showFilteredValues = (type, fullData, value, minOrMax) => {
    if (!value) return fullData;
    if (skip.includes(value)) return fullData;

    if (minOrMax === "min") {
      return fullData.filter((v) => v.value < parseFloat(value));
    }
    if (minOrMax === "max") {
      return fullData.filter((v) => v.value > parseFloat(value));
    }
    return fullData;
  };

  return (
    <div>
      <div
        class="tab-content dview_alcars"
        style={{ marginTop: "70px" }}
        id="myTabContent"
      >
        <div
          class={`tab-pane fade ${
            selectedOption === "Cars" ? "show active" : ""
          }`}
          id="Cars-tab-pane"
          role="tabpanel"
          aria-labelledby="Cars-tab"
          tabindex="0"
        >
          <Search
            selectedOption={"Cars"}
            setSelectedOption={setSelectedOption}
            data={data}
            allFilters={allFilters}
            setAllFilters={setAllFilters}
            models={models}
            showFilteredValues={showFilteredValues}
            handleClick={handleClick}
            setSelectedFilters={setSelectedFilters}
          />
        </div>
        <div
          class={`tab-pane fade ${
            selectedOption === "Rentals" ? "show active" : ""
          }`}
          id="Rentals-tab-pane"
          role="tabpanel"
          aria-labelledby="Rentals-tab"
          tabindex="0"
        >
          <RentalSearch
            selectedOption={"Rentals"}
            setSelectedOption={setSelectedOption}
            data={data}
            allFilters={allFilters}
            setAllFilters={setAllFilters}
            models={models}
            showFilteredValues={showFilteredValues}
            handleClick={handleClick}
            setSelectedFilters={setSelectedFilters}
          />
        </div>
      </div>

      <JsonLDSchemaHandler data={ldSchemas?.advancedSearch} />
    </div>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import NewcarForm from "../NewCars/NewcarForm";
import { Newcars } from "../../../Reducer/formSlice";
import { toast } from "react-toastify";
import { getModels } from "../../../Reducer/api";
import {
  getAllFiltersData,
  getSelectedFilters,
} from "../../../utils/constants";
export default function NewcarBanner({ carTypes, page, filters }) {
  const { t, i18n } = useTranslation();
  const detail = useSelector((state) => state.fromsdata.newcarsdata);
  const loading = useSelector((state) => state.fromsdata.loading);
  const error = useSelector((state) => state.fromsdata.error);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(Newcars());
  }, [dispatch]);

  if (error) {
    toast.error(error);
  }

  const defaultValues = {
    city: [],
    make: "",
    model: "",
    bodyType: [],
    price: {
      min: "",
      max: "",
    },
  };

  const [models, setModels] = useState([]);
  const [filterData, setFilterData] = useState({...defaultValues, ...filters});
  const [selectedFilters, setSelectedFilters] = useState([]);
  const filtersRef = useRef(filterData);

  useEffect(() => {
    setModels([]);
    if (filterData.make) {
      getModels({
        makeId: filterData.make,
        live: true,
        ownerId: "",
        page: page,
        filters: filters
      }).then((res) => {
        res = res?.map((v) => ({ label: v, value: v }));
        setModels(res);
      });
    }
  }, [filterData.make]);

  useEffect(() => {
    console.log(filterData);
  }, [filterData]);

  const resetFilters = () => {
    setFilterData(defaultValues);
    setModels([]);
  };

  const [adFiltersList, setAdFiltersList] = useState([]);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const getFilters = async () => {
    setFiltersLoading(true);
    const apiCall = await getAllFiltersData(
      page,
      "",
      filterData,
      getSelectedFilters(
        selectedFilters,
        setSelectedFilters,
        filterData,
        filtersRef
      )
    );

    setAdFiltersList(apiCall);

    setFiltersLoading(false);
  };
  useEffect(() => {
    getFilters();
  }, [filterData]);

  return (
    <section
      class="banner hbanner "
      style={{
        backgroundImage: `url(${
          detail.electric_cars
            ? detail.electric_cars
            : "/assets/images/newcar.jpg"
        })`,
      }}
    >
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="setform electricbanner">
              <h6>{t("Electric cars")}</h6>
              <h5>{t("The future of driving")}</h5>
              <div class="tab-content" id="myTabContent">
                <NewcarForm
                  data={detail}
                  carTypes={carTypes}
                  models={models}
                  filterData={filterData}
                  setFilterData={setFilterData}
                  resetFilters={resetFilters}
                  adFiltersList={adFiltersList}
                  loading={filtersLoading}
                />
              </div>
            </div>
          </div>
          <div class="col-md-6"></div>
        </div>
      </div>
    </section>
  );
}

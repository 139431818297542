import { useEffect, useRef } from "react";

export const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

export const useDidMountEffectWithDebounce = (func, deps, delay) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      const timer = setTimeout(() => {
        func();
      }, delay);
      return () => clearTimeout(timer);
    } else didMount.current = true;
  }, deps);
};

export const callWithDebounce = (func, delay) => {
  const timer = setTimeout(() => {
    func();
  }, delay);
  return () => clearTimeout(timer);
};

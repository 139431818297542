import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Banner from "./Banner";
import ElectricCarsType from "./ElectricCarsType";
import { title } from "../../../data/content";
import BrandSection from "../../Tools/BrandSection";
import PopularModal from "../../Tools/PopularModal";
import ComingSoon from "../../ComingSoon";
import JsonLDSchemaHandler from "../../Tools/JSONLDSchemaUtil";
import { ldSchemas } from "../../../utils/json-schemas";
import staticFiltersData from "../../../data/withoutVin.json";
import { getEntityTypes } from "../../../Reducer/api";
import ElectricCarsContent from "./ElectricCarsContent";
export default function ElectricCars({ page }) {
  const { t, i18n } = useTranslation();
  let rtl = false;
  switch (i18n.language) {
    case "English":
      rtl = false;
      break;
    case "Arabic":
      rtl = true;
      break;
    case "Kurdish":
      rtl = true;
      break;
  }
  const [carTypes, setCarTypes] = useState([]);

  const getCarTypes = async () => {
    const res = await getEntityTypes();
    setCarTypes(res?.data);
    // setSelectedCarType(res?.data?.[0]);
  };
  useEffect(() => {
    getCarTypes();
  }, []);

  const filters = {
    fuel: staticFiltersData.data.fuel
      .filter((v) => v?.type === "electric")
      .map((v) => v?.name?.en),
  };

  return (
    <>
      {/* <ComingSoon /> */}
      <Banner page={page} carTypes={carTypes} filters={filters} />
      {/* <ElectricCarsType rtl={rtl} /> */}
      <PopularModal rtl={rtl} filters={filters} title={"Popular electric models in Iraq"} />
      <BrandSection
        brandTitle={t("Popular electric car brands")}
        filters={filters}
      />
      <ElectricCarsContent />
      <JsonLDSchemaHandler data={ldSchemas?.electricCars} />
    </>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";
import BannerSection from "./BannerSection";
import PlaceSection from "./PlaceSection";
import SearchedSection from "./SearchedSection";
import ArticlesSection from "../../Tools/ArticleBlog/ArticlesSection";
import Follow from "./Follow";
import BrandNewSection from "./BrandNewSection";
import { title } from "../../../data/content";
import BrandSection from "../../Tools/BrandSection";
import RecentlyCarView from "../../Tools/RecentCarView";
import { useEffect } from "react";
import { useState } from "react";
import { getEntityTypes, getMake } from "../../../Reducer/api";
import WelcomeModal from "../../Tools/WecomeModal";
import { useDispatch, useSelector } from "react-redux";
import { myprofilefetch } from "../../../Reducer/myprofileSlice";
import { changeLanguage } from "../../../Reducer/authSlice";

import JsonLDSchemaHandler from "./../../Tools/JSONLDSchemaUtil";
import { ldSchemas } from "../../../utils/json-schemas";
import { Helmet } from "react-helmet";
import FaqSection from "./FaqSection";

export default function Home({ page }) {
  const { t, i18n } = useTranslation();
  const profileData = useSelector((state) => state.myprofile.profilearr);
  const [carTypes, setCarTypes] = useState([]);
  const dispatch = useDispatch();
  const [selectedCarType, setSelectedCarType] = useState(null);
  let rtl = false;
  let lang = "ar";
  switch (i18n.language) {
    case "English":
      rtl = false;
      lang = "en";
      break;
    case "Arabic":
      rtl = true;
      lang = "ar";
      break;
    case "Kurdish":
      rtl = true;
      lang = "ku";
      break;
  }
  const getCarTypes = async () => {
    const res = await getEntityTypes();
    setCarTypes(res?.data);
    // setSelectedCarType(res?.data?.[0]);
  };
  useEffect(() => {
    getCarTypes();
    if (localStorage.getItem("token")) {
      dispatch(myprofilefetch());
    }
  }, []);
  useEffect(() => {
    if (profileData?.userType) {
      if (profileData?.userType === "dealer") {
        if (
          !profileData?.additionalInfo?.dealerWorkingHours ||
          // profileData?.email &&
          // profileData?.email !== "N/A" &&
          profileData?.emailVerified == false ||
          profileData?.phoneNumberVerified == false
        ) {
          openModal();
        }
      } else {
        if (
          !profileData?.isWelcomeMailSent ||
          // profileData?.email &&
          // profileData?.email !== "N/A" &&
          profileData?.emailVerified == false ||
          profileData?.phoneNumberVerified == false
        ) {
          openModal();
        }
      }
    }
  }, [profileData]);

  const [modal, setModal] = useState(false);
  const openModal = () => {
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
  };
  return (
    <>
      <BannerSection carTypes={carTypes} page={page} />
      <BrandNewSection rtl={rtl} page={page} />
      <BrandSection brandTitle={t("Browse by brand")} page={page} />
      <PlaceSection page={page} />
      <SearchedSection
        rtl={rtl}
        page={page}
        carTypes={carTypes}
        setCarTypes={setCarTypes}
        selectedCarType={selectedCarType}
        setSelectedCarType={setSelectedCarType}
      />
      <ArticlesSection rtl={rtl} page={page} />
      <FaqSection />
      <RecentlyCarView
        rtl={rtl}
        page={page}
        title={"Recently viewed car deals"}
      />
      <Follow />
      <WelcomeModal
        modal={modal}
        closeModal={closeModal}
        userType={profileData?.userType}
      />

      <JsonLDSchemaHandler data={ldSchemas?.home} />

      {/*website open graph */}
      <Helmet>
        <meta property="og:title" content="Buy Sell & Rent Your Cars in Iraq" />
        <meta property="og:site_name" content="Ako Sayara" />
        <meta property="og:url" content="https://www.akosayara.com" />
        <meta
          property="og:description"
          content="**Ako Sayara** is a leading online platform in Iraq for buying, selling, and renting cars. It offers a wide selection of vehicles, from economy to luxury models, catering to individual and business needs. With a user-friendly website and reliable customer service, Ako Sayara connects buyers and sellers, making car transactions seamless and trustworthy. Whether you're looking for a car to rent or purchase, Ako Sayara provides a convenient and efficient solution for all your automotive needs."
        />
        <meta property="og:type" content="" />
        <meta
          property="og:image"
          content="https://akosayara.com/assets/screens/English/Buy1.png"
        />
      </Helmet>
    </>
  );
}
